import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import BusinessProcessTypes from '../../../../helpers/types';
import { ModalWindow } from '../../../../../../../ui-kit';
import './style.sass';

const InfoEventModal = ({
  isOpen,
  onClose,
  event,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName="businessProcessEventInfo"
    title="About event"
    className="bpEventInfoModal modal-window--big"
  >
    <h2 className="bpEventInfoModal__title">{event.title}</h2>
    <div className="bpEventInfoModal__content">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut dicta harum tempora veritatis.
      Ab accusantium alias asperiores atque commodi consectetur deleniti
      distinctio dolor dolores eum fuga
      impedit inventore laboriosam laudantium maxime minus molestias mollitia nam
      neque nesciunt non omnis
      quibusdam quis quisquam rerum sapiente sint sit vel, veniam vero voluptatum.
    </div>
    <h4 className="bpEventInfoModal__variable-title">List of variables: </h4>
    <ul className="bpEventInfoModal__list">
      {
        // eslint-disable-next-line camelcase
        propOr([], 'fields', event).map(({ name, is_output }) => !!is_output && (
          <div className="bpEventInfoModal__item" key={name}>
            <div className="bpEventInfoModal__name">
              <b
                className="bpEventInfoModal__variable"
              >
                {name}
              </b>
            </div>
            <div className="bpEventInfoModal__description">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, necessitatibus!
            </div>
          </div>
        ))
      }
    </ul>
  </ModalWindow>
);

InfoEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  event: BusinessProcessTypes.Event.isRequired,
};

export default InfoEventModal;
