import {
  compose, withStateHandlers, branch, renderNothing, getContext, withHandlers,
  lifecycle, withProps, withState,
} from 'recompose';
import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  T,
  cond,
  isEmpty,
  equals,
  identity,
  prop,
  curry,
  ifElse,
  always,
  isNil,
  not,
  and,
  map,
  includes,
  pathOr, propOr,
} from 'ramda';
import { withTranslation } from 'react-i18next';
import { Either } from 'ramda-fantasy';
import { connect } from 'react-redux';

import moment from 'moment';
import { notEqual } from 'ramda-extension';
import { userSelectors } from '../../../../../../state/user';
import { usersSelectors } from '../../../../../../state/users';
import { messengerSelectors } from '../../../../../../state/messenger';
import { openModal } from '../../../../../../state/ui/actions';

import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { propIdOrNull } from '../../../../../../utils/helpers/commonHelpers';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import {
  withUserProfile,
  withRefs,
  withPrevMessage,
  withWindowWidth,
} from '../../../../../../utils/enchancers';

import MessageItem from './messageItem';
import { isFirstMessageOnDay } from '../../../../../../utils/helpers/messengerHelpers/messages';
import { convertToUNIX } from '../../../../../../utils/helpers/dateHelpers';
import { DIRECT_CHANNELS, GENERAL_CHANNEL } from '../../../../../../constants/messenger';
import { IMAGES_SIZE } from '../../../../../../constants/ui';
import { callNotification } from '../../../../../../utils/helpers/notifies';

const mapStateToProps = (state, { id, channelId, prevMessageId }) => {
  const message = messengerSelectors.getMessage(state)(id, channelId);
  return {
    message,
    parentMessageFromState: messengerSelectors.getParentMessage(state)(id, channelId),
    prevMessage: messengerSelectors.getMessage(state)(prevMessageId, channelId),
    authors: usersSelectors.getUsersEntities(state),
    bootData: userSelectors.getUserData(state),
    isPinned: !!messengerSelectors.getPinnedMessages(state)(channelId)[prop('id', message)],
    user: userSelectors.getUserData(state),
    channel: messengerSelectors.getChannelById(state)(channelId),
    getMessageById: idMessage => messengerSelectors.getMessage(state)(idMessage, channelId),
  };
};

const mapDispatchToProps = ({
  setOpenModal: openModal,
});

const { Left, Right } = Either;

const getContent = prop('content');
const getAuthorId = prop('created_by');

const checkIsUserOwner = curry((authorId, userId) => cond([
  [equals(authorId), () => true],
  [T, () => equals(authorId, userId)],
])(userId));

const isHideShowActions = cond([
  [equals(true), () => Right(false)],
  [T, Left],
]);

const hideShowActions = Either.either(identity);

const onEditMessageHandler = ({
  setMessageAsEditable,
  setIsShowActions,
}) => () => {
  setIsShowActions(false);
  setMessageAsEditable(true);
};
const onClickDeleteHandler = ({
  setSelectedMessage, message, id, setOpenModal, index,
}) => () => {
  setSelectedMessage({ index, ts: id, id: message.id });
  setOpenModal('deleteMessageModal');
};

const onPinMessageHandler = ({
  setSelectedMessage, message, id, setOpenModal, index,
}) => () => {
  setSelectedMessage({ index, ts: id, id: message.id });
  setOpenModal('pinMessageModal');
};

const onUnpinMessageHandler = ({
  setSelectedMessage, message, id, setOpenModal, index,
}) => () => {
  setSelectedMessage({ index, ts: id, id: message.id });
  setOpenModal('unpinMessageModal');
};


const onClickShowActionsHandler = ({ setIsShowActions, isShowActions }) => () => {
  setIsShowActions(!isShowActions);
};

const onMouseLeaveHandler = ({ setIsShowActions, isShowActions }) => () => hideShowActions(
  setIsShowActions,
  isHideShowActions(isShowActions),
);

const onChangeContentHandler = ({ setContent }) => ({ target }) => setContent(target.value);

const onClickSaveContentHandler = ({
  message, onUpdateMessage, id, setMessageAsEditable, channelId, setIsReplyEdited, replyMessage,
}) => (content) => {
  const isContent = content.replace(/<span class="selectable-text">|<\/span>|<br>/gi, '');
  if (isContent) {
    onUpdateMessage(id, message.id, channelId, content);
    setMessageAsEditable(false);
    if (equals(
      propIdOrNull(replyMessage),
      propIdOrNull(message),
    )) {
      setIsReplyEdited(true);
    }
  } else {
    callNotification.error('Message is empty');
  }
};

const onCloseEditMessageHandler = ({ setMessageAsEditable }) => () => setMessageAsEditable(false);

const setIsShowActionsStateHandler = () => isVisible => ({ isShowActions: isVisible });

const setContentStateHandler = () => content => ({ content });

const onShowUserProfileHandler = ({
  onSetUserProfile,
  message: { created_by },
}) => () => onSetUserProfile(created_by);

const onClickMentionHandler = ({ onSetUserProfile }) => (user) => {
  onSetUserProfile(user);
};


const getDateCreatedMessage = compose(Number, date => moment(date).format('D'), prop('created_at'));

const messagesHasDifferentDate = (message, prevMessage) => ifElse(isNil,
  T,
  () => (getDateCreatedMessage(prevMessage) !== getDateCreatedMessage(message)))(prevMessage);

const onSetReplyMessageHandler = ({ setReplyMessage, message, replyMessage }) => () => {
  if (!replyMessage || message.id !== replyMessage.id) {
    setReplyMessage(message);
  }
};

const onKeyDownEditHandler = () => () => {};

const onSetRefMessageHandler = ({ setRef }) => e => setRef('message', e);


const enhance = compose(
  withPrevMessage,
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withUserProfile,
  withWindowWidth(),
  withTranslation(['common', 'chat']),
  withState('lastUpdatedId', 'setLastUpdatedId', null),
  branch(
    ({ message }) => isNil(message),
    renderNothing,
  ),
  withProps(({
    message, bootData, authors, messageTimestamps, prevMessage, channel, user,
    parentMessageFromState, allRenderedMessages, isPendingMore, isLastMessage,
  }) => {
    const author = authors[message.created_by];
    const isMessageLoaded = allRenderedMessages ? includes(pathOr(null, ['parent', 'ts'], message), allRenderedMessages) : false;
    return {
      parentMessage: isMessageLoaded ? parentMessageFromState : message.parent,
      isEdited: !!propOr(false, 'updated_by_user_at', message),
      isOwner: checkIsUserOwner(getAuthorId(message), prop('id', bootData)),
      isOwnerChannel: channel.created_by === user.id || includes(channel.type,
        [...DIRECT_CHANNELS, GENERAL_CHANNEL]),
      userAvatar: ifElse(prop('avatar'),
        () => getImageUrl(author.avatar, IMAGES_SIZE.sm),
        always(''))(author),
      userName: getFullName(author),
      userOnlineStatus: propOr(0, 'is_online', author),
      isPending: isLastMessage && isPendingMore,
      isNewDay: ifElse(isNil, T, () => messagesHasDifferentDate(message, prevMessage))(prevMessage),
      showDateLabel: isFirstMessageOnDay(convertToUNIX(message.created_at))(messageTimestamps),
    };
  }),
  withStateHandlers(({ message }) => ({
    isShowActions: false,
    mouseHover: true,
    isEditable: false,
    content: getContent(message),
    isUpdatedMessageHeight: false,
  }), {
    setMessageAsEditable: () => val => ({ isEditable: val }),
    setIsShowActions: setIsShowActionsStateHandler,
    setContent: setContentStateHandler,
  }),
  memo,
  getContext(
    {
      setUserProfileId: PropTypes.func,
      onSelectEditableMessageId: PropTypes.func,
      setSelectedMessage: PropTypes.func,
      setLastUserOwnerMessageIndex: PropTypes.func,
      lastUserOwnerMessage: PropTypes.string,
      onRenderContent: PropTypes.func,
      setIsReplyEdited: PropTypes.func,
    },
  ),
  branch(
    ({ message }) => isEmpty(message),
    renderNothing,
  ),
  withHandlers({
    onEditMessage: onEditMessageHandler,
    onPinMessage: onPinMessageHandler,
    onUnpinMessage: onUnpinMessageHandler,
    onMouseLeave: onMouseLeaveHandler,
    onClickShowActions: onClickShowActionsHandler,
    onChangeContent: onChangeContentHandler,
    onClickSaveContent: onClickSaveContentHandler,
    onShowUserProfile: onShowUserProfileHandler,
    onClickMention: onClickMentionHandler,
    onClickDelete: onClickDeleteHandler,
    onCloseEditMessage: onCloseEditMessageHandler,
    onSetReplyMessage: onSetReplyMessageHandler,
    onSetRefMessage: onSetRefMessageHandler,
  }),
  withHandlers({
    onKeyDownEdit: onKeyDownEditHandler,
  }),
  memo,
  lifecycle({
    shouldComponentUpdate({
      isShowActions, isEditable, isPending, message, isPinned, editableMessage, isLastMessage,
      isUnread, userOnlineStatus,
    }) {
      return this.props.isShowActions !== isShowActions || isEditable !== this.props.isEditable
        || isPending !== this.props.isPending
        || !equals(message, this.props.message)
        || this.props.message.id === this.props.lastUpdatedId
        || isPinned !== this.props.isPinned
        || editableMessage !== this.props.editableMessage
        || isLastMessage !== this.props.isLastMessage
        || isUnread !== this.props.isUnread
        || userOnlineStatus !== this.props.userOnlineStatus;
    },
    componentDidMount() {
      const {
        index, id, lastUserOwnerMessage,
        setLastUserOwnerMessageIndex,
      } = this.props;
      if (equals(id, lastUserOwnerMessage)) {
        setLastUserOwnerMessageIndex(index);
      }
    },
    componentDidUpdate(prevProps) {
      const {
        message, setLastUserOwnerMessageIndex, index, setContent,
        editableMessage, onEditMessage, id, lastUserOwnerMessage,
        isReplyEdited, setIsReplyEdited, setReplyMessage, replyMessage,
        setLastUpdatedId,
      } = this.props;
      if (notEqual(isReplyEdited, prevProps.isReplyEdited) && isReplyEdited) {
        if (equals(
          propIdOrNull(replyMessage),
          propIdOrNull(message),
        )) {
          setReplyMessage(message);
          setIsReplyEdited(false);
        }
      }
      if (not(equals(...map(getContent)([message, prevProps.message])))) {
        setContent(getContent(message));
      }
      if (and(equals(editableMessage, id),
        not(equals(prevProps.editableMessage, editableMessage)))) {
        onEditMessage();
      }
      if (equals(id, lastUserOwnerMessage)) {
        setLastUserOwnerMessageIndex(index);
      }
      if (message.content.length !== prevProps.message.content.length) {
        setLastUpdatedId(message.id);
      }
    },
  }),
  memo,
);
export default enhance(MessageItem);
