import {
  compose, lifecycle, withState,
} from 'recompose';
import {
  has, map, prop, filter, pathEq, omit,
} from 'ramda';
import { notEqual as notEqualR } from 'ramda-extension';

import { memo } from 'react';
import Condition from './condition';


const enhance = compose(
  withState('isShown',
    'setIsShown',
    ({ cond }) => !cond),
  memo,
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        cond, values, setIsShown, fieldsConfig, resetForm, fieldGroup,
      } = this.props;

      if (cond) {
        const { field, value: { equal, notEqual } } = cond;

        const listOfConnectedFields = compose(
          map(prop('name')),
          filter(pathEq(['input_options', 'cond', 'field'], field)),
        )(fieldsConfig);
        if (prevProps.values && notEqualR(values[cond.field], prevProps.values[cond.field])) {
          resetForm({ ...values, [fieldGroup]: omit(listOfConnectedFields, values) });
        }

        if (has('equal', cond.value)) {
          if (values[field] === equal) {
            setIsShown(true);
          } else {
            setIsShown(false);
          }
        }
        if (has('notEqual', cond)) {
          if (values[field] !== notEqual) {
            setIsShown(true);
          } else {
            setIsShown(false);
          }
        }
      }
    },
  }),
);

export default enhance(Condition);
