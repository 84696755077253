import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Layouts, NoInternetConnection,
} from '../../ui-kit';
import { Header } from '../../containers';
import { LeftSideBar } from '../../newContainers';
import { ProfileContainer } from './profileContainer';
import './style.sass';
import { setAppTitle } from '../../state/ui/actions';
import { useSubscriptionPermission } from '../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../constants/pemissions';

export const Profile = () => {
  const { t } = useTranslation('common');

  useEffect(() => {
    setAppTitle('Profile settings - Avanga 2.0');
  }, []);
  useSubscriptionPermission(MODULES.PROFILE);
  return (
    <Layouts.Main>
      <LeftSideBar />
      <div className="main-container">
        <NoInternetConnection>
          <Header hideToggleRightSideBar>
            <h1 className="weight--medium title--xl title-page">
              {t('Profile settings')}
            </h1>
          </Header>
          <div className="main-container__content main-container__content--profile">
            <ProfileContainer />
          </div>
        </NoInternetConnection>
      </div>
    </Layouts.Main>
  );
};
