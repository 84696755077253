import {
  path, compose, prop, pathOr,
} from 'ramda';

import { IMAGES_SIZE } from '../../constants/ui';
import { SERVER_ERRORS } from '../../constants/config';

const LOCALHOST = 'localhost';
const WSS = 'wss://api.';
const API = 'api.';


const isNotLocalhost = hostname => hostname !== LOCALHOST;
const getRootApiUrl = () => process.env.REACT_APP_ROOT_API_URL;

const getApiUrl = (source) => {
  if (isNotLocalhost(source.hostname)) {
    return `${source.protocol}//${API}${source.hostname}`;
  }
  return process.env.REACT_APP_API_URL;
};

const getWsApiUrl = (source) => {
  if (isNotLocalhost(source.hostname)) {
    return `${WSS}${source.hostname}`;
  }
  return process.env.REACT_APP_WS_URL;
};


const getImageUrl = (id, size = IMAGES_SIZE.original) => {
  const url = getApiUrl(window.location);
  const userSalt = localStorage.getItem('userSalt');
  return (id ? `${url}/files/${id}/${size}${size ? '&' : '?'}salt=${userSalt}` : '');
};

const getImageParams = (id, size = IMAGES_SIZE.original) => {
  const userSalt = localStorage.getItem('userSalt');
  return (id ? `${id}/${size}${size ? '&' : '?'}salt=${userSalt}` : '');
};

const getFileUrl = (id) => {
  const url = getApiUrl(window.location);
  const userSalt = localStorage.getItem('userSalt');
  return (id ? `${url}/files/${id}/?salt=${userSalt}` : '');
};

const getActivityDownloadUrl = (pathname, params) => {
  const origin = getApiUrl(window.location);
  const token = localStorage.getItem('authToken');
  const downloadUrl = `${origin}${pathname}?startDate=${params.startDate}&endDate=${params.endDate}&user_id=${params.user_id}&token=${token}`;
  return downloadUrl;
}

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
};

const getNormalizeErrorObject = compose(code => prop(code, SERVER_ERRORS), pathOr(0, ['error', 'response', 'status']));

const getError = errors => path(['response', 'data', 'result', 'error'], errors);

const getErrors = errors => path(['response', 'data', 'result', 'errors'], errors);

const getErrorMessage = error => path(['error', 'response', 'data', 'result', 'message'], error);

export {
  getApiUrl,
  getRootApiUrl,
  getWsApiUrl,
  getErrors,
  getError,
  getImageUrl,
  getQueryVariable,
  getFileUrl,
  getNormalizeErrorObject,
  getImageParams,
  getErrorMessage,
  getActivityDownloadUrl,
};
