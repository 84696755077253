import { schema } from 'normalizr';

import { CATEGORIES } from './types';
import { camelCase } from '../../utils/helpers/commonHelpers';
import {
  category, author, assigneeUser, watchers,
} from '../tasks/schema';

const tasksSchema = new schema.Entity(
  'tasks',
  {
    category,
    author,
    assigneeUser,
    watchers: [watchers],
  },
  {
    processStrategy: (value, parent) => ({ ...value, sprint: parent.id }),
  },
);


const members = [new schema.Entity('members')];
const projectDataSchema = new schema.Entity(
  'data', {
    members,
  },
);

const sprintTasksSchema = new schema.Entity('sprints', {
  tasks: [tasksSchema],
});

const backlogSchema = new schema.Entity('backlog', {
  category,
  assigneeUser,
  author,
  watchers: [watchers],
});

const agendaBoardSchema = new schema.Entity('agenda', {
  tasks: [tasksSchema],
});

const categoriesTaskSchema = [new schema.Entity(camelCase(CATEGORIES))];

const sprintsListSchema = { sprints: [new schema.Entity('sprints')] };

const tasksListSchema = [new schema.Entity('tasks')];

const notesListSchema = [new schema.Entity('documents')];

const usersWithTasksSchema = [new schema.Entity('usersWithTasks')];

const pinnedTasksListSchema = [new schema.Entity('pinnedTasks')];

const statusesListSchema = [new schema.Entity('statuses')];

export default projectDataSchema;

export {
  usersWithTasksSchema,
  sprintTasksSchema,
  sprintsListSchema,
  categoriesTaskSchema,
  agendaBoardSchema,
  backlogSchema,
  tasksSchema,
  tasksListSchema,
  notesListSchema,
  pinnedTasksListSchema,
  statusesListSchema,
};
