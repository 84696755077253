import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import BusinessProcesses from './businessProccesses';
import { uiActions } from '../../../../../state/ui';
import { withWindowWidth } from '../../../../../utils/enchancers';

const mapDispatchToProps = {
  openModal: uiActions.openModal,
};

const onCreateProcessModalHandler = ({
  openModal,
}) => () => {
  openModal('createProcessModal');
};

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withHandlers({
    onCreateProcessModal: onCreateProcessModalHandler,
  }),
);

export default enhancer(BusinessProcesses);
