import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../../../../ui-kit';
import BusinessProcessTypes from '../../../../helpers/types';
import './style.sass';

const ProcessNode = ({
  processNode: { id }, onAddAction, onEditAction, onDeleteAction, processTitle,
  isAllowDeleting,
  onOpenInfo,
}) => (
  <>
    <div
      className="pieceOfProcess"
    >
      <h4 className="pieceOfProcess__title">
        {processTitle}
      </h4>
      <div className="pieceOfProcess__add-among">
        <ButtonsCollection.ButtonPlus
          title="Add action"
          onClick={() => onAddAction(id)}
        />
        <div className="pieceOfProcess__add-among-arrow" />
      </div>
      <div className="pieceOfProcess__controls-group">
        <ButtonsCollection.ButtonIcons
          title="Edit node"
          type="button"
          onClick={() => onEditAction(id)}
        >
          <span className="icon-edit-icon" />
        </ButtonsCollection.ButtonIcons>
        {
          isAllowDeleting && (
            <ButtonsCollection.ButtonIcons
              title="Delete node"
              type="button"
              onClick={() => onDeleteAction(id)}
            >
              <span className="icon-delete-icon" />
            </ButtonsCollection.ButtonIcons>
          )
        }
        <ButtonsCollection.ButtonIcons
          title="Info about node"
          type="button"
          onClick={onOpenInfo}
        >
          <span className="icon-notify-info" />
        </ButtonsCollection.ButtonIcons>
      </div>
    </div>
    <hr />
  </>
);

ProcessNode.propTypes = {
  processNode: BusinessProcessTypes.ProcessNode.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onEditAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  processTitle: PropTypes.string.isRequired,
  isAllowDeleting: PropTypes.bool.isRequired,
  onOpenInfo: PropTypes.func.isRequired,
};

export default ProcessNode;
