import React from 'react';
import {
  Tabs, TabPanel, TabList, Tab,
} from 'react-tabs';
import PropTypes from 'prop-types';

import {
  TaskAbout,
  AttachedFiles,
  TimeEntry,
  Comments,
  History,
} from './components';
import { PreLoaderWhileLoading, ScrollWrapper } from '../../ui-kit';
import './style.sass';
import { useSubscriptionPermission } from '../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../constants/pemissions';

const PRELOADER_DIMENSION = 100;

const Task = ({
  task, t, onScrollSetRef, scrollElement, isLoading,
}) => {
  useSubscriptionPermission(MODULES.TASKS);
  return (
    <PreLoaderWhileLoading
      dimension={PRELOADER_DIMENSION}
      alignContainerCenter
      isLoading={isLoading || !task.title}
    >
      <ScrollWrapper refCustom={onScrollSetRef}>
        <div className="task">
          <TaskAbout task={task} />
          <Tabs selectedTabClassName="task-navigation__link--active">
            <div className="task-navigation">
              <TabList className="task-navigation__list">
                <Tab className="task-navigation__link">{t('Comments')}</Tab>
                <Tab className="task-navigation__link">{t('Time Entry')}</Tab>
                <Tab className="task-navigation__link">{t('Attached Files')}</Tab>
                <Tab className="task-navigation__link">{t('History')}</Tab>
              </TabList>
            </div>
            <TabPanel>
              <Comments scrollElement={scrollElement} />
            </TabPanel>
            <TabPanel>
              <TimeEntry />
            </TabPanel>
            <TabPanel>
              <div className="task-files">
                <AttachedFiles />
              </div>
            </TabPanel>
            <TabPanel>
              <History task={task} />
            </TabPanel>
          </Tabs>
        </div>
      </ScrollWrapper>
    </PreLoaderWhileLoading>
  );
};

Task.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  onScrollSetRef: PropTypes.func.isRequired,
  scrollElement: PropTypes.instanceOf(Object),
  t: PropTypes.func.isRequired,
};

Task.defaultProps = {
  scrollElement: {},
};

export default Task;
