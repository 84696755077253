import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import {
  Layouts,
  NoInternetConnection,
  ButtonsCollection,
  DropDown,
  PermissionElement,
} from '../../ui-kit';
import { CoWorkers } from '../../newContainers/leftSideBar/components';
import { AttachedFilesSlider } from '../../components';
import {
  ProjectsNavigation, Categories, RightSideBarContainer, StatusesCRUDModal,
} from './components';
import {
  EditSprintModal,
  NewSprintModal,
  DeleteSprintModal,
  CompleteSprintModal,
  StartSprintModal,
} from './components/sprintCRUD';
import { EditProjectModal } from '../../containers/allProjects/components/modals';
import { CreateCategoryModal, EditCategoryModal, DeleteCategoryModal } from './components/categoriesCRUD';

import {
  Header, ProjectsNotes,
  Tasks, AgendaBoard, Task,
} from '../../containers';
import { LeftSideBar } from '../../newContainers';
import { WINDOW_WIDTH } from '../../constants/ui';
import './style.sass';
import PROJECT_TABS from '../../constants/tabNavigation';
import { BUTTON_LABELS, MODALS_NAME } from './constants';
import {
  CreateTaskModal, DeleteTaskModal, EditTaskModal, PinTaskModal, UnpinTaskModal,
} from '../../containers/taskCRUD';
import { ACTIONS, MODULES } from '../../constants/pemissions';
import { useSubscriptionPermission } from '../../utils/hooks/useSubscriptionPermission';

const MODULES_BY_ACTIVE_TAB = {
  tasks: 'tasks',
  notes: 'project_documents',
};

const getModuleByModalName = activeTab => MODULES_BY_ACTIVE_TAB[activeTab];

const Project = ({
  match,
  setOpenModal,
  activeTab,
  project,
  windowWidth,
  id,
  selectedTask,
  selectedMeta,
  isTaskHasSprint,
  setSelectedCategoryId,
  selectedCategoryId,
  t,
}) => {
  useSubscriptionPermission(MODULES.PROJECTS);
  return (
    <Layouts.Main className="page-project">
      <LeftSideBar>
        <Categories
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
        />
        <CoWorkers />
      </LeftSideBar>
      <div className="main-container page-project__main-container">
        <NoInternetConnection>
          <Header>
            <ProjectsNavigation title={project.title} match={match} />
          <>
            <PermissionElement
              module={getModuleByModalName(activeTab)}
              action={ACTIONS.CREATE}
            >
              {
                windowWidth > WINDOW_WIDTH.MEDIUM ? (
                  <ButtonsCollection.ButtonBrill
                    onClick={() => setOpenModal(MODALS_NAME[activeTab])}
                    className="button--sm header__control-button--project"
                  >
                    { BUTTON_LABELS[activeTab] }
                  </ButtonsCollection.ButtonBrill>
                ) : (
                  <ButtonsCollection.ButtonPlus
                    onClick={() => setOpenModal(MODALS_NAME[activeTab])}
                    className="header__control-button--project"
                  />
                )
              }
            </PermissionElement>
            {
            (!isEmpty(project)) && (
              <PermissionElement
                module={MODULES.PROJECTS}
                action={ACTIONS.CREATE}
              >
                <DropDown
                  className="project-header-control"
                  name="project-header"
                  label={(
                    <ButtonsCollection.ButtonIcons
                      title={t('Control buttons for project')}
                      className="header-col__project-burger"
                    >
                      <span className="icon-burger-dots-gorizont" />
                    </ButtonsCollection.ButtonIcons>
                  )}
                  list={
                    [
                      {
                        id: 0,
                        label: (
                          <>
                            <span className="project-header-control__icon icon-category-set" />
                            <span className="project-header-control__text">New category</span>
                          </>
                        ),
                        onClick: () => setOpenModal('createCategory'),
                      },
                      {
                        id: 1,
                        label: (
                          <>
                            <span className="project-header-control__icon icon-category-set" />
                            <span className="project-header-control__text">Statuses</span>
                          </>
                        ),
                        onClick: () => setOpenModal('statusesOfTasks'),
                      },
                      {
                        id: 3,
                        label: (
                          <>
                            <span className="project-header-control__icon icon-edit-icon" />
                            <span className="project-header-control__text">{t('Edit Project')}</span>
                          </>
                        ),
                        onClick: () => setOpenModal('editProjectModal'),
                      },
                    ]
                  }
                />
              </PermissionElement>
            )}
          </>
          </Header>
          <div className={`main-container__content ${activeTab === PROJECT_TABS.TASK ? 'task-component' : ''}`}>
            <Switch>
              <Route exact path="/projects/:id/notes/" component={ProjectsNotes} />
              <Route exact path="/projects/:projectId/agenda-board/" component={AgendaBoard} />
              <Route exact path="/projects/:id/task/:taskId/" component={Task} />
              <Route exact path="/attached-modal/" component={AttachedFilesSlider} />
              <Route exact path="/attached-modal/" component={AttachedFilesSlider} />
              <Route exact component={Tasks} />
            </Switch>
            <RightSideBarContainer projectId={id} windowWidth={windowWidth} />
          </div>
        </NoInternetConnection>
      </div>
      <NewSprintModal projectId={id} />
      <EditProjectModal selectedProjectId={id} />
      <DeleteSprintModal projectId={id} />
      <EditSprintModal projectId={id} />
      <StartSprintModal projectId={id} />
      <CompleteSprintModal projectId={id} />
      <CreateTaskModal />
      <EditTaskModal selectedMeta={selectedMeta} selectedTaskId={selectedTask} />
      <DeleteTaskModal selectedTask={selectedTask} isTaskHasSprint={isTaskHasSprint} />
      <PinTaskModal selectedTask={selectedTask} isTaskHasSprint={isTaskHasSprint} />
      <UnpinTaskModal selectedTask={selectedTask} isTaskHasSprint={isTaskHasSprint} />
      <EditCategoryModal projectId={id} />
      <CreateCategoryModal projectId={id} />
      <DeleteCategoryModal projectId={id} />
      <StatusesCRUDModal projectId={id} />
    </Layouts.Main>
  );
};

Project.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  setOpenModal: PropTypes.func.isRequired,
  selectedTask: PropTypes.instanceOf(Object),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  activeTab: PropTypes.string.isRequired,
  selectedMeta: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  isTaskHasSprint: PropTypes.bool,
  selectedCategoryId: PropTypes.number,
  setSelectedCategoryId: PropTypes.func.isRequired,
};

Project.defaultProps = {
  selectedTask: null,
  isTaskHasSprint: false,
  selectedCategoryId: null,
};

export default Project;
