export default {
  CREATE_SUBSCRIPTION_REQUEST: 'CREATE_SUBSCRIPTION_REQUEST',
  GET_PLANS_REQUEST: 'GET_PLANS_REQUEST',
  GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
  SET_CARD_REQUEST: 'SET_CARD_REQUEST',
  UPDATE_SUBSCRIPTION_REQUEST: 'UPDATE_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
};
