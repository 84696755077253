import { compose } from 'recompose';
import { map, path, values } from 'ramda';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';
import { bpAutocompleteActions } from '../store/bpAutoComplete';

export const BUSINESS_PROCESS_STATUSES = {
  ACTIVE: 1,
  NOT_ACTIVE: 0,
};

export const AUTOCOMPLETE_MODELS = {
  PROJECT: 0,
  CHANNELS: 1,
  USERS: 2,
  ROLES: 3,
  CLIENTS: 4,
};

export const AUTOCOMPLETE_MODELS_CONFIG = {
  [AUTOCOMPLETE_MODELS.PROJECT]: {
    name: 'getAutoComplete',
    action: bpAutocompleteActions.getBpProjectsRequest,
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data']),
    ),
    searchField: 'title',
  },
  [AUTOCOMPLETE_MODELS.USERS]: {
    name: 'getAutoComplete',
    action: bpAutocompleteActions.getBpUsersRequest,
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['users']),
    ),
    searchField: 'q',
  },
  [AUTOCOMPLETE_MODELS.CLIENTS]: {
    name: 'getAutoComplete',
    action: bpAutocompleteActions.getBpClientsRequest,
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['clients']),
    ),
    searchField: 'name',
  },
  [AUTOCOMPLETE_MODELS.CHANNELS]: {
    name: 'getAutoComplete',
    action: bpAutocompleteActions.getBpChannelsRequest,
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data']),
    ),
    searchField: 'name',
  },
  [AUTOCOMPLETE_MODELS.ROLES]: {
    name: 'getAutoComplete',
    action: bpAutocompleteActions.getBpRolesRequest,
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data']),
    ),
    searchField: 'name',
  },
};
