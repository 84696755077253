/* eslint-disable no-unused-vars,react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { propOr, equals, not } from 'ramda';

import {
  ButtonsCollection, StatusBar, LabelPriorityTask, BrillMask, Avatar, Grid, DropDown,
} from '../../../../ui-kit';
import './style.sass';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const isHasTime = value => not(equals(value, '0m'));

const TaskItem = ({
  assigneeAvatar,
  categories,
  id,
  onOpenProfileAssignee,
  isItemDrag,
  onEdit,
  onDelete,
  classNameStatus,
  assigneeName,
  estimatedTime,
  spentTime,
  progress,
  author,
  authorName,
  data,
  onOpenProfileAuthor,
  t,
  onPin,
  onUnpin,
  isTaskPin,
  P,
  agendaColumn,
}) => (
    <Grid.Row className={`project-task ${classNameStatus} ${isItemDrag ? 'project-task--drag' : ''} project-task__cursor`} >
    <Grid.ColData className="project-task__col--id">
      <div>
        {data.id}
      </div>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--subject">
      <Link to={`task/${id}/`} className="project-task__title-name-task text--cut">
        {data.title}
      </Link>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--priority">
      <LabelPriorityTask className="project-task__priority-label" status={data.priority} />
    </Grid.ColData>
    <Grid.ColData className="project-task__col--category">
      <span className="text--cut">
        {propOr(t('None'), 'title', categories[data.category_id])}
      </span>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--status">
      <span className="text--cut">
        {agendaColumn.title}
      </span>
    </Grid.ColData>
    <Grid.ColData className="project-task__col--author">
      { author && (
      <BrillMask asLink onClick={onOpenProfileAuthor}>
        <Avatar src={getImageUrl(author.avatar, IMAGES_SIZE.xs)} alt={authorName} />
      </BrillMask>
      )}
    </Grid.ColData>
    <Grid.ColData className="project-task__col--assignee">
      <BrillMask asLink onClick={onOpenProfileAssignee}>
        <Avatar src={assigneeAvatar} alt={assigneeName} />
      </BrillMask>
      {/* <BrillMask className="project-task--unassigned"> */}
      {/*  <Avatar> */}
      {/*    <div className="project-task--unassigned_icon-wrapper" title="Without Assignee"> */}
      {/*      <span className="icon-author-user project-task--unassigned_icon" /> */}
      {/*    </div> */}
      {/*  </Avatar> */}
      {/* </BrillMask> */}
    </Grid.ColData>
    <Grid.ColData className="project-task__col--estimation">
      {
        isHasTime(estimatedTime) && (
          <b className="project-task__estimation">
            {estimatedTime}
          </b>
        )
      }
    </Grid.ColData>
    <Grid.ColData className="project-task__col--spent">
      {
        isHasTime(spentTime) && (
          <b className="project-task__spent">
            {spentTime}
          </b>
        )
      }
    </Grid.ColData>
    <Grid.ColData className="project-task__col--progress">
      <StatusBar
        percents={progress}
      />
    </Grid.ColData>
    <Grid.ColData className="project-task__col--controls">
      <DropDown
        className="controls-group-task"
        name="controls-group-task"
        label={(
          <ButtonsCollection.ButtonIcons
            className="project-task__controls-button-task"
            title="Controls for sprint"
          >
            <span className="project-task__control-icon icon-settings-vertical">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>
          </ButtonsCollection.ButtonIcons>
          )}
        list={
            [
              {
                id: 0,
                onClick: onEdit,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pencil-2" />
                    <span className="controls-group-task__text">{t('Edit')}</span>
                  </>
                ),
              },
              {
                id: 1,
                onClick: isTaskPin ? onUnpin : onPin,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pin" />
                    <span className="controls-group-task__text">{isTaskPin ? 'Unpin' : t('Pin')}</span>
                  </>
                ),
              },
              ...P(MODULES.TASKS, ACTIONS.DELETE, [{
                id: 2,
                onClick: onDelete,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-delete-icon" />
                    <span className="controls-group-task__text">{t('Delete')}</span>
                  </>
                ),
              }]),
            ]
          }
      />
    </Grid.ColData>
  </Grid.Row>
);



TaskItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isItemDrag: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  P: PropTypes.func.isRequired,
  agendaColumn: PropTypes.instanceOf(Object).isRequired,
};

export default TaskItem;
