import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { settingRolesSchema } from './schema';

export const {
  getRolesRequest,
  getRoleRequest,
  createRoleRequest,
  updateRoleRequest,
  getModulesRequest,
  deleteRoleRequest,
  setRoles,
  setRole,
  setModules,
} = createActions(
  {
    [types.GET_ROLES_REQUEST]: [
      identity,
      (params, meta = {}) => ({
        async: true,
        route: '/roles',
        selectorName: 'getRolesRequest',
        method: 'GET',
        params,
        schema: {
          rules: settingRolesSchema,
          pathData: ['data'],
        },
        ...(meta.isAutocomplete ? {} : { actions: { success: setRoles } }),
        ...meta,
      }),
    ],
    [types.GET_ROLE_REQUEST]: [
      identity,
      ({ roleId, ...params }, meta) => ({
        async: true,
        route: `/roles/${roleId}`,
        selectorName: 'getRolRequest',
        method: 'GET',
        params,
        actions: {
          success: action => setRole({ ...action, roleId }),
        },
        ...meta,
      }),
    ],
    [types.CREATE_ROLE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/roles',
        selectorName: 'createRoleRequest',
        method: 'POST',
        params,
        ...meta,
      }),
    ],
    [types.UPDATE_ROLE_REQUEST]: [
      identity,
      ({ roleId, ...params }, meta) => ({
        async: true,
        route: `/roles/${roleId}`,
        selectorName: 'updateRoleRequest',
        method: 'PUT',
        params,
        actions: {
          success: action => setRole({ ...action, roleId }),
        },
        ...meta,
      }),
    ],
    [types.GET_MODULES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/modules',
        selectorName: 'getModulesRequest',
        method: 'GET',
        params,
        actions: {
          success: setModules,
        },
        ...meta,
      }),
    ],
    [types.DELETE_ROLE_REQUEST]: [
      identity,
      ({ roleId, ...params }, meta) => ({
        async: true,
        route: `/roles/${roleId}`,
        selectorName: 'deleteRoleRequest',
        method: 'DELETE',
        params,
        actions: {
          success: getRolesRequest,
        },
        ...meta,
      }),
    ],
  },
  types.SET_ROLES,
  types.SET_ROLE,
  types.SET_MODULES,
);
