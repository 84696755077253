import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { Link } from 'react-router-dom';

import { ButtonsCollection } from '../../../../../../../ui-kit';
import BusinessProcessTypes from '../../../../helpers/types';
import './style.sass';

const ProcessCard = ({ process, push, onDeleteProcess }) => (
  <Link
    to={`/business-processes/${prop('id', process)}`}
    className="processCard"
  >
    <h4 className="processCard__title">
      {prop('title', process)}
    </h4>
    <div className="processCard__controls-group">
      <ButtonsCollection.ButtonIcons
        onClick={() => push(`/business-processes/${prop('id', process)}`)}
        title="Edit process"
      >
        <span className="icon-edit-icon" />
      </ButtonsCollection.ButtonIcons>
      <ButtonsCollection.ButtonIcons
        title="Delete process"
        onClick={(e) => {
          e.preventDefault();
          onDeleteProcess(prop('id', process));
        }}
      >
        <span className="icon-delete-icon" />
      </ButtonsCollection.ButtonIcons>
    </div>
  </Link>
);

ProcessCard.propTypes = {
  process: BusinessProcessTypes.BusinessProcess.isRequired,
  push: PropTypes.func.isRequired,
  onDeleteProcess: PropTypes.func.isRequired,
};


export default ProcessCard;
