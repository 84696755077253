import React from 'react';
import {
  CLIENT_LOGS, CLIENT_LOGS_ONLY_ACTION_FIELDS, CLIENT_LOGS_TYPE,
  CONTACT_LOGS_ONLY_ACTION_FIELDS, CLIENT_TYPES, HISTORY_EVENTS,
} from '../../../../constants/crm';
import { STATUS } from '../../../../constants/tasks';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const getLogAction = action => ({
  isCreated: action === CLIENT_LOGS.CREATED,
  isChanged: action === CLIENT_LOGS.CHANGED,
  isDeleted: action === CLIENT_LOGS.DELETED,
});

const renderLeadLog = ({
  action, field, prevValue, newValue, originalField,
}, t, { type }) => {
  const { isCreated, isChanged } = getLogAction(action);
  const showOnlyAction = CLIENT_LOGS_ONLY_ACTION_FIELDS.includes(originalField);
  return (
    <>
      {
        isCreated && (
          <span className="history__event">{t(`created the ${type === CLIENT_TYPES.CLIENT ? 'client' : 'lead'}`)}</span>
        )
      }
      {
        isChanged && (
          <span className="history__event">{
            `${t(action)} ${t(field)} ${showOnlyAction ? '' : (
              `${t('from')} ${t(prevValue)} ${t('to')} ${t(newValue)}`
            )}`
          }
          </span>
        )
      }
    </>
  );
};

const renderTaskLog = ({
  action, newValue, title,
}, t) => {
  const newStatus = {
    [STATUS.DONE.id]: 'Done',
    [STATUS.TO_DO.id]: 'To do',
    [STATUS.IN_PROGRESS.id]: 'In progress',
  };
  const { isCreated, isChanged, isDeleted } = getLogAction(action);

  return (
    <>
      {
        isCreated && (
          <span className="history__event">{`${t(HISTORY_EVENTS.TASKS.ADDED)} ${title}`}</span>
        )
      }

      {
        isDeleted && (
          <span className="history__event">{`${t(HISTORY_EVENTS.TASKS.DELETED)} ${title}`}</span>
        )
      }
      {
        isChanged && (
          <span className="history__event">{
            `${t(HISTORY_EVENTS.TASKS.MARKED)} ${title} as ${newStatus[newValue]}`
          }
          </span>
        )
      }
    </>
  );
};

const renderDocumentLog = ({
  action, newValue, prevValue, title, field,
}, t) => {
  const { isCreated, isChanged, isDeleted } = getLogAction(action);

  return (
    <>
      {
        isCreated && (
          <span className="history__event">{`${t(HISTORY_EVENTS.NOTE.ADDED)} ${title}`}</span>
        )
      }
      {
        isDeleted && (
          <span className="history__event">{`${t(HISTORY_EVENTS.NOTE.DELETED)} ${title}`}</span>
        )
      }
      {
        isChanged && (
          <span className="history__event">{
            field === 'title' ? (
              `${t(HISTORY_EVENTS.NOTE.UPDATED_TITLE)} ${t('from')} ${prevValue} ${t('to')} ${newValue}`
            ) : (
              `${t(HISTORY_EVENTS.NOTE.UPDATED)} ${field} ${'of'} ${title} ${t('note')}`
            )
          }
          </span>
        )
      }
    </>
  );
};

const renderContactLog = ({
  action, newValue, prevValue, title, field, originalField,
}, t) => {
  const { isCreated, isChanged, isDeleted } = getLogAction(action);

  const showOnlyAction = CONTACT_LOGS_ONLY_ACTION_FIELDS.includes(originalField);
  return (
    <>
      {
        isCreated && (
          <span className="history__event">{`${t(HISTORY_EVENTS.CONTACT.ADDED)} ${title}`}</span>
        )
      }
      {
        isDeleted && (
          <span className="history__event">{`${t(HISTORY_EVENTS.CONTACT.DELETED)} ${title}`}</span>
        )
      }
      {
        isChanged && (
          <span className="history__event">{
            `${t(HISTORY_EVENTS.CONTACT.CHANGED)} ${t(field)} of ${title} ${!showOnlyAction ? `
            ${t('from')} ${prevValue} ${t('to')} ${newValue}` : ''}`
          }
          </span>
        )
      }
    </>
  );
};

const renderProductLog = ({
  action, newValue, prevValue, title,
}, t, client) => {
  const { isChanged } = getLogAction(action);
  return (
    <>
      {
        isChanged && (
          <span className="history__event">
            {
              prevValue === client.id && `disconnected project - ${t(title)} from ${getFullName(client.contact)}`
            }
            {
              newValue === client.id && (`connected project - ${t(title)} to ${getFullName(client.contact)}`)
          }
          </span>
        )
      }
    </>
  );
};

export const renderLogType = {
  [CLIENT_LOGS_TYPE.LEAD]: renderLeadLog,
  [CLIENT_LOGS_TYPE.TASK]: renderTaskLog,
  [CLIENT_LOGS_TYPE.DOCUMENT]: renderDocumentLog,
  [CLIENT_LOGS_TYPE.CONTACT]: renderContactLog,
  [CLIENT_LOGS_TYPE.PRODUCT]: renderProductLog,
};
