import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ButtonsCollection, Layouts, DropDown } from '../../../../ui-kit';
import {
  Header,
  LeadsContainer,
} from '../../../../containers';
import { LeftSideBar } from '../../../../newContainers';
import './style.sass';
import CRMModules from '../CRMModules/index';
import { CRM_ROUTES } from '../../../../constants/crm';
import { RightSideBarLeadsContainer } from './components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { useSubscriptionPermission } from '../../../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../../../constants/pemissions';

const Leads = ({
  t,
  onCreateLeadModal,
  windowWidth,
  selectedLeadId,
  selectedPinnedLeadId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onCreateCustomFieldModal = useCallback(() => setIsOpen(true), []);
  useSubscriptionPermission(MODULES.LEADS);
  return (
    <Layouts.Main rightSideBarStatus={false}>
      <LeftSideBar>
        <CRMModules />
      </LeftSideBar>
      <div className="main-container page-contacts__main-container">
        <Header>
          <h1 className="weight--medium title--xl title-page">
            {t('Leads')}
          </h1>
          <>
            {
              windowWidth > WINDOW_WIDTH.MEDIUM ? (
                <ButtonsCollection.ButtonBrill
                  onClick={onCreateLeadModal}
                  className="button--sm header__control-button--project"
                >

                  New lead
                </ButtonsCollection.ButtonBrill>
              ) : (
                <ButtonsCollection.ButtonPlus
                  onClick={onCreateLeadModal}
                  className="header__control-button--project"
                />
              )
            }
            <DropDown
              className="crm-module-header-control"
              name="crm-module-header"
              label={(
                <ButtonsCollection.ButtonIcons
                  title={t('Control buttons for CRM')}
                  className="header-col__separate-page header-col__crm-module-burger"
                >
                  <span className="icon-burger-dots-gorizont icon" />
                </ButtonsCollection.ButtonIcons>
              )}
              list={
                [
                  {
                    id: 0,
                    label: (
                      <NavLink to={CRM_ROUTES.PIPELINES} className="crm-module-header-control__link">
                        <span className="crm-module-header-control__icon icon-category-set" />
                        <span className="crm-module-header-control__text">{t('Pipelines')}</span>
                      </NavLink>
                    ),
                  },
                  {
                    id: 1,
                    label: (
                      <div className="crm-module-header-control__link">
                        <span className="crm-module-header-control__icon icon-category-set" />
                        <span className="crm-module-header-control__text">{t('Custom fields')}</span>
                      </div>
                    ),
                    onClick: () => onCreateCustomFieldModal(),
                  },
                ]
              }
            />
            </>
        </Header>
        <div className="main-container__content page-contacts__content">
          <LeadsContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedLeadId={selectedLeadId}
            selectedPinnedLeadId={selectedPinnedLeadId}
          />
          <RightSideBarLeadsContainer windowWidth={windowWidth} />
        </div>
      </div>
    </Layouts.Main>
  );
};
Leads.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  onCreateLeadModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectedLeadId: PropTypes.number,
  selectedPinnedLeadId: PropTypes.number,
};
Leads.defaultProps = {
  selectedLeadId: null,
  selectedPinnedLeadId: null,
};
export default Leads;
