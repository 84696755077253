import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './style.sass';
import { useHistory, useParams } from 'react-router';

const joinUrl = (url, goTo) => (url.slice(-1) === '/' ? `${url}${goTo}/` : `${url}/${goTo}/`);


const DisabledNavLink = ({ isDisabled, children, ...props }) => (
  <>
    {
    isDisabled ? (
      <span
        title="There are no active sprints"
        className="project-nav__link project-nav__link--disabled"
      >
        {children}
      </span>
    ) : (<NavLink {...props}>{children}</NavLink>)
  }
  </>
);

DisabledNavLink.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

const ProjectNavigation = ({
  match, t, title, onSetIsActiveTaskLink, isHasActiveSprints, isProjectIdWithoutTask,
}) => {
  const { id } = useParams();
  const { location: { pathname } } = useHistory();
  const projectPath = `/projects/${id}/`;
  return (
    <>
      <NavLink className="project-nav__go-to-projects" to={pathname === projectPath ? '/projects/' : projectPath}>
        <span className="icon-expand-arrow-left" title="Back to project" />
      </NavLink>
      <h1 className="weight--medium title--xl project-nav__title">
        {title}
      </h1>
      <nav className="header__project-nav project-nav">
        <DisabledNavLink exact isDisabled={isProjectIdWithoutTask} isActive={onSetIsActiveTaskLink} to={`${match.url}`} activeClassName="project-nav__link--active" className="project-nav__link">{t('Tasks')}</DisabledNavLink>
        <DisabledNavLink exact isDisabled={!isHasActiveSprints || isProjectIdWithoutTask} to={joinUrl(match.url, 'agenda-board')} activeClassName="project-nav__link--active" className="project-nav__link">{t('Agenda board')}</DisabledNavLink>
        <DisabledNavLink exact isDisabled={isProjectIdWithoutTask} to={joinUrl(match.url, 'notes')} activeClassName="project-nav__link--active" className="project-nav__link">{t('Notes')}</DisabledNavLink>
      </nav>
    </>
  );
};

ProjectNavigation.propTypes = {
  title: PropTypes.string,
  onSetIsActiveTaskLink: PropTypes.func.isRequired,
  isHasActiveSprints: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  isProjectIdWithoutTask: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ProjectNavigation.defaultProps = {
  title: 'Without project',
};

export default ProjectNavigation;
