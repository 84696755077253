import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MobileFiltersContainer, DropDown, FormsCollection } from '../../../../../../../ui-kit';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../../../../constants/ui';

const FiltersRow = ({
  onChangeSorting, onSearch, areMobileFiltersOpen,
  toggleMobileFiltersHandler, processesFilter, onChangeSearch, searchValue,
}) => {
  const { t } = useTranslation('common');
  return (
    <div className="processes-filter">
      <div className="processes-filter__sort-group">
        <FormsCollection.Search
          className="search-field--circle"
          placeholder="Search by title"
          name="search"
          value={searchValue}
          onChange={onChangeSearch}
          onSearch={onSearch}
          withoutControl
        />
        <MobileFiltersContainer
          title="Filter"
          onWidth={WINDOW_WIDTH.SMALL}
          isOpen={areMobileFiltersOpen}
          onToggle={toggleMobileFiltersHandler}
        >
          <div className="processes-filter__sort-group">
            <DropDown
              label={`${t('Sort')} : `}
              placeholder="Custom"
              onChange={onChangeSorting}
              currentItem={processesFilter.sortId}
              list={
                [
                  {
                    id: 0,
                    order: 'desc',
                    sortBy: 'created_at',
                    label: t('Recent'),
                  },
                  {
                    id: 1,
                    order: 'asc',
                    sortBy: 'created_at',
                    label: t('Old'),
                  },
                ]
              }
            />
          </div>
        </MobileFiltersContainer>
      </div>
    </div>
  );
};

FiltersRow.propTypes = {
  processesFilter: PropTypes.instanceOf(Object).isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
};

export default FiltersRow;
