import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsCollection } from '..';
import './style.sass';

/**
 *
 * @param className {string}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */


export const Pin = ({ className, title }) => {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation('common');
  const onClickPin = () => {
    setIsActive(!isActive);
  };

  return (
    <ButtonsCollection.ButtonIcons
      className={classNames('pin-button ', className, isActive && 'pin-star--active')}
      onClick={onClickPin}
      title={`${isActive ? t(`Unpin ${title}`) : t(`Pin ${title}`)}`}
    >
      <span className={classNames({ 'icon-star-empty-project--active': isActive, 'icon-pin': !isActive })} />

    </ButtonsCollection.ButtonIcons>
  );
};
