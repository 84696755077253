import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';

export const {
  getBpRolesRequest,
  getBpProjectsRequest,
  getBpChannelsRequest,
  getBpUsersRequest,
  getBpClientsRequest,
  setBpRoles,
  setBpProjects,
  setBpChannels,
  setBpUsers,
  setBpClients,
} = createActions({
  [types.GET_BP_ROLES_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/roles',
      selectorName: 'getBpRolesRequest',
      method: 'GET',
      params,
      ...meta,
      actions: {
        success: setBpRoles,
      },
    }),
  ],
  [types.GET_BP_PROJECTS_REQUEST]: [
    identity,
    ({ processId, ...params }, meta) => ({
      async: true,
      route: '/projects',
      selectorName: 'getBpProjectsRequest',
      method: 'GET',
      params,
      ...meta,
      actions: {
        success: setBpProjects,
      },
    }),
  ],
  [types.GET_BP_CHANNELS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/channels',
      selectorName: 'getBpChannelsRequest',
      method: 'GET',
      params,
      ...meta,
      actions: {
        success: setBpChannels,
      },
    }),
  ],
  [types.GET_BP_USERS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/users/autocomplete',
      selectorName: 'getBpUsersRequest',
      method: 'GET',
      params,
      ...meta,
      actions: {
        success: setBpUsers,
      },
    }),
  ],
  [types.GET_BP_CLIENTS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/clients/autocomplete',
      selectorName: 'getBpClientsRequest',
      method: 'GET',
      params,
      ...meta,
      actions: {
        success: setBpClients,
      },
    }),
  ],
},
types.SET_BP_PROJECTS,
types.SET_BP_CHANNELS,
types.SET_BP_ROLES,
types.SET_BP_USERS,
types.SET_BP_CLIENTS);
