import React from 'react';

import FormView from '../formView';
import '../style.sass';

const EditAction = props => (
  <FormView
    {...props}
  />
);

export default EditAction;
