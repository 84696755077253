import {
  compose, lifecycle, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { prop } from 'ramda';

import ProcessContainer from './processContainer';
import { bpActions, bpSelectors } from '../../store/bpModule';
import { RIGHT_SIDEBAR_VIEWS } from '../../constants/common';
import { uiActions } from '../../../../../state/ui';
import preloaderWhileLoading from '../../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../../constants/ui';

const mapStateToProps = (store, { crudNodesConfig }) => ({
  result: bpSelectors.getProcessNodeResult(store),
  editingEvent: bpSelectors.getEventByNodeId(store)(prop('selectedNode', crudNodesConfig)),
  isPending: bpSelectors.getPendingDeleteBusinessProcessNode(store)
    || bpSelectors.getBusinessProcessPending(store),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  getBusinessProcess: bpActions.getBusinessProcessRequest,
  getBusinessProcessEvents: bpActions.getBusinessProcessEventsRequest,
  setRightSideBar: uiActions.changeRightSidebarStatus,
};

const onAddActionHandler = ({ setCrudNodesConfig }) => parentId => setCrudNodesConfig({
  view: RIGHT_SIDEBAR_VIEWS.CREATE_ACTION,
  parentId,
});

const onEditActionHandler = ({ setCrudNodesConfig }) => selectedNode => setCrudNodesConfig({
  view: RIGHT_SIDEBAR_VIEWS.EDIT_ACTION,
  selectedNode,
});

const onDeleteActionHandler = ({ setCrudNodesConfig, setOpenModal }) => (selectedNode) => {
  setCrudNodesConfig({
    selectedNode,
  });
  setOpenModal('blockedNodeModal');
  setOpenModal('deleteNodeModal');
};

const onToggleRightSideBarHandler = ({ setCrudNodesConfig, crudNodesConfig }) => (status) => {
  if (crudNodesConfig.view !== RIGHT_SIDEBAR_VIEWS.NOTHING && status) {
    setCrudNodesConfig({ view: RIGHT_SIDEBAR_VIEWS.NOTHING });
    return false;
  }
  return true;
};

const enhance = compose(
  withState('crudNodesConfig', 'setCrudNodesConfig', {
    view: RIGHT_SIDEBAR_VIEWS.NOTHING,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ result }) => ({
    hasNotNodes: !result.length,
  })),
  withHandlers({
    onAddAction: onAddActionHandler,
    onEditAction: onEditActionHandler,
    onDeleteAction: onDeleteActionHandler,
    onToggleRightSideBar: onToggleRightSideBarHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        match: { params: { processId } }, getBusinessProcess, getBusinessProcessEvents,
      } = this.props;
      getBusinessProcess({ processId });
      getBusinessProcessEvents();
    },
    componentDidUpdate(prevProps) {
      const { crudNodesConfig, setRightSideBar } = this.props;
      if (crudNodesConfig.view !== RIGHT_SIDEBAR_VIEWS.NOTHING
        && crudNodesConfig.view !== prevProps.crudNodesConfig.view) {
        setRightSideBar(true);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 800,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default enhance(ProcessContainer);
