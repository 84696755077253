import {
  branch,
  compose, renderNothing, withHandlers, withProps,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { forEach, keys, values } from 'ramda';
import DeleteNodeModal from './deleteNodeModal';
import { uiActions, uiSelectors } from '../../../../../../../state/ui';
import { bpActions, bpSelectors } from '../../../../store/bpModule';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  deleteProcessNodeRequest: bpActions.deleteBusinessProcessNodeRequest,
};

const mapStateToProps = (state, { crudNodesConfig: { selectedNode } }) => ({
  isOpen: uiSelectors.getModal(state)('deleteNodeModal'),
  isPending: bpSelectors.getPendingDeleteBusinessProcessNode(state),
  node: bpSelectors.getProcessNodeById(state)(selectedNode),
  events: bpSelectors.getEventsEntities(state),
  nodes: bpSelectors.getProcessNodeEntities(state),
});


const onDeleteHandler = ({
  deleteProcessNodeRequest, onClose, node, match: { params: { processId } },
}) => () => deleteProcessNodeRequest({
  processId,
  nodeId: node.id,
}, {
  callbacks: {
    success: onClose,
  },
});

const onCloseHandler = ({ onCloseModal }) => () => {
  onCloseModal('blockedNodeModal');
  onCloseModal('deleteNodeModal');
};

const getListOfUse = (nodeId, events, nodes) => {
  const accum = [];
  forEach((node) => {
    const params = JSON.parse(node.params);
    forEach((key) => {
      if (new RegExp(`{${nodeId}\\|\\d*}`, 'g').test(params[key])) accum.push(events[node.event_id].title);
    }, keys(params));
  }, values(nodes));
  return accum;
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ events, node = {}, nodes }) => ({
    event: events[node.event_id],
    listOfUse: getListOfUse(node.id, events, nodes),
  })),
  branch(({ event }) => !event, renderNothing),
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);


export default enhance(DeleteNodeModal);
