import { handleActions } from 'redux-actions';
import {
  reverse, uniq, filter, ifElse, equals, always, prop, propEq,
} from 'ramda';
import { notEqual } from 'ramda-extension';

import types from './types';
import {
  mergeDeep, mergeDeepByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const initialState = {
  entities: {
    comments: {},
    time_entries: {},
    data: {},
    files: {},
  },
  result: {
    comments: [],
    time_entries: [],
    files: [],
  },
};

const reducer = handleActions({
  [types.CLEAR_DATA_TASK]: mergeIn(() => initialState),
  [types.DELETE_TASK_FILE]: mergeDeep(({
    payload,
  }, state) => ({
    result: {
      ...state.result,
      files: filter(notEqual(payload.id), state.result.files),
    },
  })),
  [types.SET_TASK_FILES]: mergeDeep(({
    payload: { data: { result, entities }, meta: { isNotUpdate = false }, ...other },
  }, state) => ({
    entities: {
      ...state.entities,
      files: {
        ...ifElse(equals(false), always(state.entities.files), always({}))(isNotUpdate),
        ...entities.files,
        ...other,
      },
    },
    result: {
      ...state.result,
      files: uniq([...ifElse(equals(false), always(state.result.files), always([]))(isNotUpdate),
        ...result]),
    },
  })),
  [types.SET_ACTIVE_TASK]: mergeDeep(({
    payload: {
      data: { entities: { task } }, task: { id },
    },
  }) => ({
    entities: { data: task[id] },
  })),
  [types.UPDATE_ACTIVE_TASK]: mergeDeep(({
    payload: { data: { entities: { task } }, task: { id } },
  }) => ({
    entities: { data: task[id] },
  })),
  [types.SET_TASK_COMMENT]: mergeIn(({ payload: { comment } }, { result, entities }) => ({
    entities: {
      ...entities,
      comments: {
        ...entities.comments,
        [comment.id]: {
          ...comment,
          author: comment.author.id,
        },
      },
    },
    result: {
      ...result,
      comments: [...result.comments, comment.id],
    },
  })),
  [types.EDIT_TASK_COMMENT]: mergeIn(({ payload: { comment } }, { entities }) => ({
    entities: {
      ...entities,
      comments: {
        ...entities.comments,
        [comment.id]: {
          ...comment,
          author: comment.author.id,
        },
      },
    },
  })),
  [types.SET_TASK_COMMENTS]: mergeIn(({ payload: { data, hasMore } }, { result, entities }) => ({
    entities: {
      ...entities,
      comments: {
        ...entities.comments,
        ...data.entities.comments,
        hasMore,
      },
    },
    result: {
      ...result,
      comments: uniq([...reverse(data.result), ...result.comments]),
    },
  })),
  [types.SET_TIME_ENTRIES]: mergeIn(({ payload: { data: { entities, result } } }, state) => ({
    entities: {
      ...state.entities,
      time_entries: entities.time_entries,
    },
    result: {
      ...state.result,
      time_entries: result,
    },
  })),
  [types.SET_TIME_ENTRY_ENTITY]: mergeIn(({ payload: { timeEntry } }, { entities }) => ({
    entities: {
      ...entities,
      time_entries: {
        ...entities.time_entries,
        [timeEntry.id]: timeEntry,
      },
    },
  })),
  [types.REORDER_TIME_ENTRIES]: mergeDeep(({ payload }) => ({
    result: {
      time_entries: payload,
    },
  })),
  [types.DELETE_TIME_ENTRY_ENTITY]: mergeDeep(({ payload }) => ({
    entities: {
      time_entries: payload,
    },
  })),
  [types.DELETE_TASK_COMMENT]: mergeIn(({ payload: { comment } }, { result }) => ({
    result: {
      comments: filter(commentId => !propEq('id', commentId, comment), prop('comments', result)),
    },
  })),
  [types.REORDER_SPENT_TIME_TASK]: mergeDeepByPath(
    ['entities', 'data'],
    ({
      payload: {
        totalSpentTime,
        remainingEstimate,
      },
    }) => ({ spent_time: totalSpentTime, remaining_estimate: remainingEstimate }),
  ),
  [types.SET_TASK_LOGS]: mergeIn(({ payload: { data, meta: { taskId } } }) => ({
    taskLogs: {
      [taskId]: data,
    },
  })),
}, initialState);

export default reducer;
