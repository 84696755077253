import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { isNil, prop } from 'ramda';
import { push as connectRouterPush } from 'connected-react-router';

import VariablesModal from './variablesModal';
import { bpSelectors } from '../../../../../store/bpModule';

import { uiActions, uiSelectors } from '../../../../../../../../state/ui';
import {
  createTexNode, insertNodeAfterElement,
  insertNodeInCurrentFocus, setFocusAfterNode,
} from '../../../../../../../../utils/helpers/uiComponentHelpers/caretHelpers';
import { getVariable, getVariableLabel } from '../../../../../helpers/variables';

const mapStateToProps = (store, { processId, crudNodesConfig }) => ({
  isOpen: uiSelectors.getModal(store)('businessProcessVariablesModal'),
  process: bpSelectors.getProcessById(store)(processId),
  variablesOfNodes: bpSelectors.getOutputVariablesToEvent(store)(prop('parentId', crudNodesConfig)),
  events: bpSelectors.getEventsEntities(store),
  nodes: bpSelectors.getProcessNodeEntities(store),
});

const mapDispatchToProps = ({
  push: connectRouterPush,
  onCloseModal: () => uiActions.closeModal('businessProcessVariablesModal'),
});

const getVariableLabelByTextHandler = ({ events, nodes }) => ({ nodeId, variable }) => {
  const node = nodes[nodeId];
  const event = events[node.event_id];

  return getVariableLabel(node, event, variable);
};

const onSetVariableHandler = ({
  onCloseModal, setFieldValue, getVariableLabelByText, values,
}) => ({ nodeId, id, variable }) => {
  const variableElement = getVariable({
    label: getVariableLabelByText({ nodeId, variable }),
    nodeId,
    variableId: variable.id,
    id,
  });
  const emptyElement = createTexNode();
  requestAnimationFrame(() => {
    const { focusNode } = window.getSelection();
    const field = focusNode.parentNode.hasAttribute('contenteditable') ? focusNode.parentNode : focusNode;
    const [group, name, isSimple] = field.getAttribute('id').split(',');

    if (Number(isSimple)) field.innerHTML = '';
    insertNodeInCurrentFocus(variableElement);
    insertNodeAfterElement(variableElement, emptyElement);
    setFocusAfterNode(variableElement.parentElement, emptyElement);
    requestAnimationFrame(() => {
      variableElement.scrollIntoView();
    });
    setFieldValue(
      group,
      {
        ...values[group],
        [name]: variableElement.parentNode.innerHTML,
      },
    );
  });
  onCloseModal();
};

const enhance = compose(
  branch(({ selectedAction }) => isNil(selectedAction), renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers(({
    getVariableLabelByText: getVariableLabelByTextHandler,
  })),
  withHandlers({
    onSetVariable: onSetVariableHandler,
  }),
);

export default enhance(VariablesModal);
