import React, {
  useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  ButtonsCollection, AsyncAutoComplete, FormsCollection, PermissionElement,
} from '../../../../../../../ui-kit';
import './style.sass';
import { ACTIONS, MODULES } from '../../../../../../../constants/pemissions';
import { VIEW_TYPE } from '../../../../../../../constants/activity';
import { getUsersListAutocompleteRequest } from '../../../../../../../state/users/actions';
import { useActivityContext } from '../../../../context/useActivityContext';
import { useUrlParams } from '../../../../../../../utils/hooks';


const DEFAULT_USER_VALUE = { value: null, label: 'All users' };

/**
 *
 * @param activeView{string}
 * @param getProjectAutocomplete{function}
 * @param onClose{function}
 * @returns {JSX.Element}
 * @constructor
 */

const FilterFields = ({
  activeView,
  getProjectAutocomplete,
  onClose,
}) => {
  const {
    startDate,
    endDate,
    projectValue,
    selectedUser,
    setStartDate,
    setEndDate,
    setProjectValue,
    setSelectedUser,
    selectedUsers,
    setSelectedUsers,
  } = useActivityContext();

  const dispatch = useDispatch();

  const { setUrlParams, getUrlParam } = useUrlParams({});

  const setUrlParam = (optionValue, param) => {
    if (!optionValue) {
      setUrlParams({ [param]: null } ?? '');
    } else {
      setUrlParams({ [param]: optionValue } ?? '');
    }
  };


  const getUsersAutocompleteAction = useCallback((object, callback) => {
    dispatch(getUsersListAutocompleteRequest(object, callback));
  }, []);

  const getAutocompleteHandler = (fieldValue, callback) => {
    getUsersAutocompleteAction({
      q: fieldValue,
      limit: 10,
    }, {
      callbacks: {
        success: ({ users }) => {
          const usersResult = [DEFAULT_USER_VALUE, ...users];
          callback(usersResult);
        },
      },
    });
  };

  // AutocompleteHandler for project AsyncAutoComplete. Needs fixes
  //
  // const projectsTaskCrudRequest = useCallback((object, callback) => {
  //   dispatch(getProjectsTaskCrudRequest(object, callback));
  // }, []);
  //
  // const getAutocompleteProjectHandler = (fieldValue, callback) => {
  //   projectsTaskCrudRequest({
  //     q: fieldValue,
  //     limit: 10,
  //   }, {
  //     callbacks: {
  //       success: ({ data: { entities: { projects } } }) => {
  //         const value = Object.values(projects);
  //         const renamedValue = value.map((el) => {
  //           const updatedData = { ...el, label: el.title, value: el.title };
  //           return updatedData;
  //         });
  //         const usersResult = [DEFAULT_PROJECT_VALUE, ...renamedValue];
  //         callback(usersResult);
  //       },
  //     },
  //   });
  // };

  const setUsersProjectHandler = useCallback((users) => {
    let selectedUserVal = [...users];
    const lastUser = selectedUserVal[selectedUserVal.length - 1];
    if (lastUser?.value === null) {
      selectedUserVal = [DEFAULT_USER_VALUE];
    }
    // eslint-disable-next-line max-len
    if (selectedUserVal.length > 1) selectedUserVal = selectedUserVal.filter(({ value }) => value !== null);
    setSelectedUsers(selectedUserVal);
    setUrlParams({ user_id: lastUser ? [lastUser.value] : null } ?? '');
  }, []);

  const onChangeDateHandler = ({ target: { value } }, setDate, dateMoment) => {
    setDate(moment(value).format('YYYY-MM-DD'));
    setUrlParam(moment(value).format('YYYY-MM-DD'), dateMoment);
  };

  const onChangeUsersHandler = (user) => {
    setSelectedUser(user);
    setUrlParam(user.value, 'user_id');
  };
  const onChangeProjectHandler = (project) => {
    setProjectValue(project);
    setUrlParam(project.value, 'project_id');
  };

  const urlStartDate = getUrlParam(['startDate']);
  const urlEndDate = getUrlParam(['endDate']);

  useEffect(() => {
    if (urlStartDate) {
      setStartDate(urlStartDate);
    }
    if (urlEndDate) {
      setEndDate(urlEndDate);
    }
  }, [activeView, urlStartDate, urlEndDate]);

  return (
    <div className="activity-filter-fields">
      <div className="activity-filter-fields__row">
        <FormsCollection.FormWrapper
          handleSubmit={() => {}}
        >
          <div className="activity-filter-fields__form-fields flex-row">
            <PermissionElement module={MODULES.STATS} action={ACTIONS.READ}>
              <>
                <>
                  {
                    activeView !== VIEW_TYPE.MY_STATISTICS && (
                      <>
                        {activeView === VIEW_TYPE.PROJECT && (
                          <AsyncAutoComplete
                            isMulti
                            name="users"
                            placeholder="Users"
                            closeMenuOnSelect
                            value={selectedUsers}
                            loadOptions={getAutocompleteHandler}
                            onChange={setUsersProjectHandler}
                          />
                        )}
                        {activeView === VIEW_TYPE.USERS && (
                          <AsyncAutoComplete
                            name="users"
                            placeholder="Users"
                            closeMenuOnSelect
                            value={selectedUser}
                            loadOptions={getAutocompleteHandler}
                            onChange={onChangeUsersHandler}
                          />
                        )}
                      </>
                    )
                  }
                </>
                {
                  (activeView === VIEW_TYPE.PROJECT) && (
                    <>
                      <AsyncAutoComplete
                        name="projects"
                        placeholder="Project"
                        closeMenuOnSelect
                        value={projectValue}
                        loadOptions={getProjectAutocomplete}
                        onChange={onChangeProjectHandler}
                      />
                    </>
                  )
                }
                {
                  (activeView === VIEW_TYPE.MY_STATISTICS) && (
                    <>
                      <AsyncAutoComplete
                        name="projects"
                        placeholder="Project"
                        closeMenuOnSelect
                        value={projectValue}
                        loadOptions={getProjectAutocomplete}
                        onChange={onChangeProjectHandler}
                      />
                    </>
                  )
                }
              </>
            </PermissionElement>
            <div className="activity-filter-fields__date-picker">
              <div className="activity-filter-fields__date-picker-label">
                From
              </div>
              <FormsCollection.DatePicker
                isNotUseTimeZone
                onChange={e => onChangeDateHandler(e, setStartDate, 'startDate')}
                value={startDate}
                maxDate={moment(endDate).toDate()}
              />
            </div>
            <div className="activity-filter-fields__date-picker">
              <div className="activity-filter-fields__date-picker-label">
                To
              </div>
              <FormsCollection.DatePicker
                isNotUseTimeZone
                onChange={e => onChangeDateHandler(e, setEndDate, 'endDate')}
                value={endDate}
                minDate={moment(startDate).toDate()}
              />
            </div>
            <ButtonsCollection.ButtonBrill
              onClick={onClose}
              className="activity-filter-fields__button-submit button-brill--fill"
              type="button"
            >
              Apply
            </ButtonsCollection.ButtonBrill>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </div>
  );
};

export default FilterFields;
//
// //
// // import React, { useCallback, useEffect } from 'react';
// // import { useDispatch } from 'react-redux';
// // import moment from 'moment';
// // import {
// //   ButtonsCollection, AsyncAutoComplete, FormsCollection, PermissionElement,
// // } from '../../../../../../../ui-kit';
// // import './style.sass';
// // import { ACTIONS, MODULES } from '../../../../../../../constants/pemissions';
// // import { VIEW_TYPE } from '../../../../../../../constants/activity';
// // import { getUsersListAutocompleteRequest } from '../../../../../../../state/users/actions';
// // import { useActivityContext } from '../../../../context/useActivityContext';
// //
// //
// // const DEFAULT_USER_VALUE = { value: null, label: 'All users' };
// //
// // /**
// //  *
// //  * @param activeView{string}
// //  * @param getProjectAutocomplete{function}
// //  * @param onClose{function}
// //  * @returns {JSX.Element}
// //  * @constructor
// //  */
// //
// // const FilterFields = ({
// //                         activeView,
// //                         getProjectAutocomplete,
// //                         onClose,
// //                       }) => {
// //   const {
// //     startDate,
// //     endDate,
// //     projectValue,
// //     selectedUser,
// //     setStartDate,
// //     setEndDate,
// //     setProjectValue,
// //     setSelectedUser,
// //     selectedUsers,
// //     setSelectedUsers,
// //   } = useActivityContext();
// //
// //   const dispatch = useDispatch();
// //
// //   const getUsersAutocompleteAction = useCallback((object, callback) => {
// //     dispatch(getUsersListAutocompleteRequest(object, callback));
// //   }, []);
// //
// //   const getAutocompleteHandler = (fieldValue, callback) => {
// //     getUsersAutocompleteAction({
// //       q: fieldValue,
// //       limit: 10,
// //     }, {
// //       callbacks: {
// //         success: ({ users }) => {
// //           const usersResult = [DEFAULT_USER_VALUE, ...users];
// //           callback(usersResult);
// //         },
// //       },
// //     });
// //   };
// //
// //   // AutocompleteHandler for project AsyncAutoComplete. Needs fixes
// //   //
// //   // const projectsTaskCrudRequest = useCallback((object, callback) => {
// //   //   dispatch(getProjectsTaskCrudRequest(object, callback));
// //   // }, []);
// //   //
// //   // const getAutocompleteProjectHandler = (fieldValue, callback) => {
// //   //   projectsTaskCrudRequest({
// //   //     q: fieldValue,
// //   //     limit: 10,
// //   //   }, {
// //   //     callbacks: {
// //   //       success: ({ data: { entities: { projects } } }) => {
// //   //         const value = Object.values(projects);
// //   //         const renamedValue = value.map((el) => {
// //   //           const updatedData = { ...el, label: el.title, value: el.title };
// //   //           return updatedData;
// //   //         });
// //   //         const usersResult = [DEFAULT_PROJECT_VALUE, ...renamedValue];
// //   //         callback(usersResult);
// //   //       },
// //   //     },
// //   //   });
// //   // };
// //
// //   const setUsersProjectHandler = useCallback((users) => {
// //     let selectedUserVal = [...users];
// //     const lastUser = selectedUserVal[selectedUserVal.length - 1];
// //     if (lastUser?.value === null) {
// //       selectedUserVal = [DEFAULT_USER_VALUE];
// //     }
// //     // eslint-disable-next-line max-len
// //     if (selectedUserVal.length > 1) selectedUserVal = selectedUserVal.filter(({ value }) => value !== null);
// //     setSelectedUsers(selectedUserVal);
// //   }, []);
// //
// //   const onChangeDateHandler = ({ target: { value } }, setDate) => {
// //     setDate(moment(value).format('YYYY-MM-DD'));
// //   };
// //
// //   const onChangeUsersHandler = (user) => {
// //     setSelectedUser(user);
// //   };
// //   const onChangeProjectHandler = (project) => {
// //     setProjectValue(project);
// //   };
// //
// //   return (
// //     <div className="activity-filter-fields">
// //       <div className="activity-filter-fields__row">
// //         <FormsCollection.FormWrapper
// //           handleSubmit={() => {}}
// //         >
// //           <div className="activity-filter-fields__form-fields flex-row">
// //             <PermissionElement module={MODULES.STATS} action={ACTIONS.READ}>
// //               <>
// //                 <>
// //                   {
// //                     activeView !== VIEW_TYPE.MY_STATISTICS && (
// //                       <>
// //                         {activeView === VIEW_TYPE.PROJECT && (
// //                           <AsyncAutoComplete
// //                             isMulti
// //                             name="users"
// //                             placeholder="Users"
// //                             closeMenuOnSelect
// //                             value={selectedUsers}
// //                             loadOptions={getAutocompleteHandler}
// //                             onChange={setUsersProjectHandler}
// //                           />
// //                         )}
// //                         {activeView === VIEW_TYPE.USERS && (
// //                           <AsyncAutoComplete
// //                             name="users"
// //                             placeholder="Users"
// //                             closeMenuOnSelect
// //                             value={selectedUser}
// //                             loadOptions={getAutocompleteHandler}
// //                             onChange={onChangeUsersHandler}
// //                           />
// //                         )}
// //                       </>
// //                     )
// //                   }
// //                 </>
// //                 {
// //                   (activeView === VIEW_TYPE.PROJECT) && (
// //                     <>
// //                       <AsyncAutoComplete
// //                         name="projects"
// //                         placeholder="Project"
// //                         closeMenuOnSelect
// //                         value={projectValue}
// //                         loadOptions={getProjectAutocomplete}
// //                         onChange={onChangeProjectHandler}
// //                       />
// //                     </>
// //                   )
// //                 }
// //                 {
// //                   (activeView === VIEW_TYPE.MY_STATISTICS) && (
// //                     <>
// //                       <AsyncAutoComplete
// //                         name="projects"
// //                         placeholder="Project"
// //                         closeMenuOnSelect
// //                         value={projectValue}
// //                         loadOptions={getProjectAutocomplete}
// //                         onChange={onChangeProjectHandler}
// //                       />
// //                     </>
// //                   )
// //                 }
// //               </>
// //             </PermissionElement>
// //             <div className="activity-filter-fields__date-picker">
// //               <div className="activity-filter-fields__date-picker-label">
// //                 From
// //               </div>
// //               <FormsCollection.DatePicker
// //                 isNotUseTimeZone
// //                 onChange={e => onChangeDateHandler(e, setStartDate)}
// //                 value={startDate}
// //                 maxDate={moment(endDate).toDate()}
// //               />
// //             </div>
// //             <div className="activity-filter-fields__date-picker">
// //               <div className="activity-filter-fields__date-picker-label">
// //                 To
// //               </div>
// //               <FormsCollection.DatePicker
// //                 isNotUseTimeZone
// //                 onChange={e => onChangeDateHandler(e, setEndDate)}
// //                 value={endDate}
// //                 minDate={moment(startDate).toDate()}
// //               />
// //             </div>
// //             <ButtonsCollection.ButtonBrill
// //               onClick={onClose}
// //               className="activity-filter-fields__button-submit button-brill--fill"
// //               type="button"
// //             >
// //               Apply
// //             </ButtonsCollection.ButtonBrill>
// //           </div>
// //         </FormsCollection.FormWrapper>
// //       </div>
// //     </div>
// //   );
// // };
// //
// // export default FilterFields;


// import React, { useCallback, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import moment from 'moment';
// import {
//   ButtonsCollection, AsyncAutoComplete, FormsCollection, PermissionElement,
// } from '../../../../../../../ui-kit';
// import './style.sass';
// import { ACTIONS, MODULES } from '../../../../../../../constants/pemissions';
// import { VIEW_TYPE } from '../../../../../../../constants/activity';
// import { getUsersListAutocompleteRequest } from '../../../../../../../state/users/actions';
// import { useActivityContext } from '../../../../context/useActivityContext';
//
//
// const DEFAULT_USER_VALUE = { value: null, label: 'All users' };
//
// /**
//  *
//  * @param activeView{string}
//  * @param getProjectAutocomplete{function}
//  * @param onClose{function}
//  * @param setUserId{function}
//  * @param setFilters{function}
//  * @param mergeFilters{function}
//  * @param onResetUrlParam{function}
//  * @param onSetUrlParam{function}
//  * @returns {JSX.Element}
//  * @constructor
//  */
//
// const FilterFields = ({
//   activeView,
//   getProjectAutocomplete,
//   onClose,
//   setUserId,
//   setFilters,
//   mergeFilters,
//   onResetUrlParam,
//   onSetUrlParam,
// }) => {
//   const {
//     startDate,
//     endDate,
//     projectValue,
//     selectedUser,
//     setStartDate,
//     setEndDate,
//     setProjectValue,
//     setSelectedUser,
//     selectedUsers,
//     setSelectedUsers,
//   } = useActivityContext();
//
//   const dispatch = useDispatch();
//
//   const setUrlParam = (optionValue, param) => {
//     if (!optionValue) {
//       setFilters(mergeFilters({ [param]: null }));
//       onResetUrlParam({ [param]: null });
//     } else {
//       setFilters(mergeFilters({ [param]: optionValue }));
//       onSetUrlParam({ [param]: optionValue });
//     }
//   };
//
//
//   const getUsersAutocompleteAction = useCallback((object, callback) => {
//     dispatch(getUsersListAutocompleteRequest(object, callback));
//   }, []);
//
//   const getAutocompleteHandler = (fieldValue, callback) => {
//     getUsersAutocompleteAction({
//       q: fieldValue,
//       limit: 10,
//     }, {
//       callbacks: {
//         success: ({ users }) => {
//           const usersResult = [DEFAULT_USER_VALUE, ...users];
//           callback(usersResult);
//         },
//       },
//     });
//   };
//
//   // AutocompleteHandler for project AsyncAutoComplete. Needs fixes
//   //
//   // const projectsTaskCrudRequest = useCallback((object, callback) => {
//   //   dispatch(getProjectsTaskCrudRequest(object, callback));
//   // }, []);
//   //
//   // const getAutocompleteProjectHandler = (fieldValue, callback) => {
//   //   projectsTaskCrudRequest({
//   //     q: fieldValue,
//   //     limit: 10,
//   //   }, {
//   //     callbacks: {
//   //       success: ({ data: { entities: { projects } } }) => {
//   //         const value = Object.values(projects);
//   //         const renamedValue = value.map((el) => {
//   //           const updatedData = { ...el, label: el.title, value: el.title };
//   //           return updatedData;
//   //         });
//   //         const usersResult = [DEFAULT_PROJECT_VALUE, ...renamedValue];
//   //         callback(usersResult);
//   //       },
//   //     },
//   //   });
//   // };
//
//   const setUsersProjectHandler = useCallback((users) => {
//     let selectedUserVal = [...users];
//     const lastUser = selectedUserVal[selectedUserVal.length - 1];
//     if (lastUser?.value === null) {
//       selectedUserVal = [DEFAULT_USER_VALUE];
//     }
//     // eslint-disable-next-line max-len
//     if (selectedUserVal.length > 1) selectedUserVal = selectedUserVal.filter(({ value }) => value !== null);
//     setSelectedUsers(selectedUserVal);
//     setUserId(lastUser || DEFAULT_USER_VALUE);
//     onSetUrlParam({ user_id: lastUser ? [lastUser.value] : null });
//   }, []);
//
//   const onChangeDateHandler = ({ target: { value } }, setDate, dateMoment) => {
//     setDate(moment(value).format('YYYY-MM-DD'));
//     setUrlParam(moment(value).format('YYYY-MM-DD'), dateMoment);
//   };
//
//   const onChangeUsersHandler = (user) => {
//     setSelectedUser(user);
//     setUserId(user);
//     setUrlParam(user.value, 'user_id');
//   };
//   const onChangeProjectHandler = (project) => {
//     setProjectValue(project);
//     setUrlParam(project.value, 'project_id');
//   };
//
//   useEffect(() => {
//     if (activeView === VIEW_TYPE.MY_STATISTICS) {
//       setUrlParam(projectValue.value, 'project_id');
//     }
//   }, [activeView]);
//
//   return (
//     <div className="activity-filter-fields">
//       <div className="activity-filter-fields__row">
//         <FormsCollection.FormWrapper
//           handleSubmit={() => {}}
//         >
//           <div className="activity-filter-fields__form-fields flex-row">
//             <PermissionElement module={MODULES.STATS} action={ACTIONS.READ}>
//               <>
//                 <>
//                   {
//                     activeView !== VIEW_TYPE.MY_STATISTICS && (
//                       <>
//                         {activeView === VIEW_TYPE.PROJECT && (
//                           <AsyncAutoComplete
//                             isMulti
//                             name="users"
//                             placeholder="Users"
//                             closeMenuOnSelect
//                             value={selectedUsers}
//                             loadOptions={getAutocompleteHandler}
//                             onChange={setUsersProjectHandler}
//                           />
//                         )}
//                         {activeView === VIEW_TYPE.USERS && (
//                           <AsyncAutoComplete
//                             name="users"
//                             placeholder="Users"
//                             closeMenuOnSelect
//                             value={selectedUser}
//                             loadOptions={getAutocompleteHandler}
//                             onChange={onChangeUsersHandler}
//                           />
//                         )}
//                       </>
//                     )
//                   }
//                 </>
//                 {
//                   (activeView === VIEW_TYPE.PROJECT) && (
//                     <>
//                       <AsyncAutoComplete
//                         name="projects"
//                         placeholder="Project"
//                         closeMenuOnSelect
//                         value={projectValue}
//                         loadOptions={getProjectAutocomplete}
//                         onChange={onChangeProjectHandler}
//                       />
//                     </>
//                   )
//                 }
//                 {
//                   (activeView === VIEW_TYPE.MY_STATISTICS) && (
//                     <>
//                       <AsyncAutoComplete
//                         name="projects"
//                         placeholder="Project"
//                         closeMenuOnSelect
//                         value={projectValue}
//                         loadOptions={getProjectAutocomplete}
//                         onChange={onChangeProjectHandler}
//                       />
//                     </>
//                   )
//                 }
//               </>
//             </PermissionElement>
//             <div className="activity-filter-fields__date-picker">
//               <div className="activity-filter-fields__date-picker-label">
//                 From
//               </div>
//               <FormsCollection.DatePicker
//                 isNotUseTimeZone
//                 onChange={e => onChangeDateHandler(e, setStartDate, 'startDate')}
//                 value={startDate}
//                 maxDate={moment(endDate).toDate()}
//               />
//             </div>
//             <div className="activity-filter-fields__date-picker">
//               <div className="activity-filter-fields__date-picker-label">
//                 To
//               </div>
//               <FormsCollection.DatePicker
//                 isNotUseTimeZone
//                 onChange={e => onChangeDateHandler(e, setEndDate, 'endDate')}
//                 value={endDate}
//                 minDate={moment(startDate).toDate()}
//               />
//             </div>
//             <ButtonsCollection.ButtonBrill
//               onClick={onClose}
//               className="activity-filter-fields__button-submit button-brill--fill"
//               type="button"
//             >
//               Apply
//             </ButtonsCollection.ButtonBrill>
//           </div>
//         </FormsCollection.FormWrapper>
//       </div>
//     </div>
//   );
// };
//
// export default FilterFields;
