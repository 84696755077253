import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ContentEditable from 'react-contenteditable';
import classNames from 'classnames';

import './style.sass';
import {
  always, ifElse, lt, objOf, replace,
} from 'ramda';
import { compose } from 'recompose';
import { ScrollWrapper } from '../../..';
import { regexRules } from '../../../../utils/helpers/uiComponentHelpers/common';
import {
  createTexNode,
  insertNodeInCurrentFocus,
  setFocusAfterNode,
} from '../../../../utils/helpers/uiComponentHelpers/caretHelpers';
import { debounceFunc } from '../../../../utils/helpers/commonHelpers';

const DEBOUNCE_TIMEOUT = 500;

/**
 *
 * @param name {string}
 * @param onChange {function}
 * @param error {string}
 * @param touched {boolean}
 * @param onBlur {function}
 * @param onKeyPress {function}
 * @param onFocus {function}
 * @param placeholderJump {string}
 * @param onKeyDown {function}
 * @param className {string}
 * @param autoHeight {boolean}
 * @param tabIndex {number}
 * @param children {JSX.Element|string|array}
 * @param value {string}
 * @param placeholder {string}
 * @param stylesScroll {object}
 * @param onSetRefField {function}
 * @param onUpdateScroll {function}
 * @param onKeyUp {function}
 * @param asInput {boolean}
 * @param maxHeight {number}
 * @param getRef {function}
 * @param setRef {function}
 * @param isNotValidationPasteText {boolean}
 * @param id {string}
 * @returns {JSX.Element}
 * @constructor
 */

const TextArea = memo(({
  name = '',
  onChange = () => {},
  value = '',
  error = '',
  tabIndex = 0,
  touched = false,
  onBlur = () => {},
  placeholderJump = '',
  autoHeight = false,
  className = '',
  onKeyPress = null,
  children = '',
  onFocus = () => {},
  onUpdateScroll = () => {},
  placeholder = '',
  onKeyDown = null,
  onKeyUp = () => {},
  maxHeight,
  stylesScroll,
  onSetRefField,
  asInput = false,
  id = '',
  getRef,
  isNotValidationPasteText,
  setRef,
}) => {
  const [isFocus, setInFocus] = useState(false);
  const [isFill, setIsFill] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(null);
  const [scrollTop, setScrollTop] = useState(null);
  const { t } = useTranslation('common');

  const setFocusStatus = (val) => {
    setInFocus(val);
  };

  const removeBreakers = () => {
    if (value?.length) value.replace(regexRules.breakHtmlElement, '');
  };

  const onBlurField = () => {
    if (!value) {
      setInFocus(false);
    } else {
      setInFocus(true);
    }
    removeBreakers();
  };

  const onToggleFill = () => {
    if (value?.length) {
      setIsFill(true);
    } else {
      setIsFill(false);
    }
  };

  const setScrollHeightStateHandler = () => compose(
    objOf('scrollHeight'),
    ifElse(lt(maxHeight),
      always(maxHeight)),
  );

  const setHeightToElement = (element, height) => {
    if (typeof height === 'number') {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      return () => element.style.height = `${height}px`;
    } if (typeof height === 'string') {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      return () => element.style.height = height;
    }
    return 'Type of `height` is not correct';
  };

  const onGrowTextArea = (element) => {
    setScrollHeightStateHandler();
    setHeightToElement(element, scrollHeight);
  };

  const updateScrollPosition = () => {
    const scrollElement = getRef('scroll');
    scrollElement.scrollToBottom();
  };

  const setScrollHandler = () => debounceFunc(() => {
    const scrollElement = getRef('scroll');
    setScrollHeight(scrollElement?.getScrollHeight());
    setScrollTop(scrollElement?.getScrollTop());
  }, DEBOUNCE_TIMEOUT, false);

  const onSetRefScroll = setRef('scroll');
  const scrollElement = getRef('scroll');

  const scrollTopConst = scrollElement?.getScrollTop();

  const onParseDirtyHtml = (e) => {
    const fieldElement = getRef('input');
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');
    const newFieldValue = replace(regexRules.regAllHtml, '', pastedData);
    const element = document.createElement('template');
    const emptyElement = createTexNode();

    element.innerHTML = newFieldValue;
    element.content.appendChild(emptyElement);
    element.className = 'js-element-paste-text';
    document.execCommand('insertHtml', null, '');
    insertNodeInCurrentFocus(element.content);
    onChange({ target: { value: fieldElement.innerHTML, name } });
    element.dispatchEvent(new Event('keyup'));
    requestAnimationFrame(() => {
      updateScrollPosition();
      setFocusAfterNode(fieldElement, emptyElement, true);
    });
    e.preventDefault();
  };

  const privateRef = useRef({
    isFill, value, scrollHeight, scrollTop, scrollTopConst,
  });

  useEffect(() => {
    privateRef.current = {
      isFill, value, scrollHeight, scrollTop, scrollTopConst,
    };
  }, [isFill, value, scrollHeight, scrollTop, scrollTopConst]);

  const prevProps = privateRef.current;

  useEffect(() => {
    setScrollHandler();
    const fieldElement = getRef('input');
    if (scrollTop + 200 === scrollHeight && prevProps.scrollTop === scrollTopConst) {
      updateScrollPosition();
    }
    onGrowTextArea(fieldElement);

    if (!value) {
      setIsFill(false);
    } else {
      setIsFill(true);
    }
    fieldElement.addEventListener('blur', onBlurField);
    fieldElement.addEventListener('keyup', onToggleFill, true);
    if (!isNotValidationPasteText) {
      fieldElement.addEventListener('paste', onParseDirtyHtml);
    }
    return (
      fieldElement.removeEventListener('keyup', onToggleFill, true),
      fieldElement.removeEventListener('setEmoji', onGrowTextArea),
      fieldElement.removeEventListener('keydown', onGrowTextArea, true),
      fieldElement.removeEventListener('blur', onBlurField),
      fieldElement.removeEventListener('paste', onParseDirtyHtml)
    );
  }, [prevProps, value]);

  return (
    <div
      className={classNames('textArea-group',
        error && touched && 'textArea-group--has-error',
        asInput && 'textArea-group--input',
        isFill && 'textArea-group--has-value',
        className,
        isFocus && 'textArea-group--pseudo-focus',
        autoHeight && 'textArea-group--auto-height')}
      id="textarea"
    >
      <div className="textArea-group__wrap">
        {
          asInput ? (
            <>
              {
                (placeholder && !isFill) && <div className="textArea-group__placeholder">{placeholder}</div>
              }
              <ContentEditable
                id={id}
                tabIndex={tabIndex}
                disabled={false}
                onChange={e => onChange({ ...e, target: { ...e.target, name } })}
                innerRef={onSetRefField}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onBlur={(e) => { setFocusStatus(false); onBlur(e); }}
                onFocus={(e) => { setFocusStatus(true); onFocus(e); }}
                html={value}
                onKeyPress={onKeyPress}
                className="textArea-group__place-write"
              />
            </>
          ) : (

            <ScrollWrapper
              refCustom={onSetRefScroll}
              className="textArea-group__scroll-wrapper"
              style={stylesScroll}
              autoHeight
              autoHide
              onUpdate={onUpdateScroll}
            >
              {
                (placeholder && !isFill) && <div className="textArea-group__placeholder">{placeholder}</div>
              }
              <ContentEditable
                id={id}
                tabIndex={tabIndex}
                disabled={false}
                onChange={e => onChange({ ...e, target: { ...e.target, name } })}
                innerRef={onSetRefField}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onBlur={(e) => { setFocusStatus(false); onBlur(e); }}
                onFocus={(e) => { setFocusStatus(true); onFocus(e); }}
                html={value}
                onKeyPress={onKeyPress}
                className="textArea-group__place-write"
              />
            </ScrollWrapper>
          )
        }
        {children}
      </div>
      {placeholderJump && (<label htmlFor={name} className="textArea-group__placeholder-jump">{placeholderJump}</label>)}
      {error && touched && (
        <span className="textArea-group__error field__error-text">
          {t(error, { key: placeholderJump })}
        </span>
      )}
    </div>
  );
});

export default TextArea;
