const STATUS = {
  TO_DO: 0,
  IN_PROGRESS: 1,
};

const SPRINT_STATUS = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
  COMPLETE: 2,
};

const OPTIONS_OF_STATUSES_SPRINTS = [
  {
    id: 1,
    label: 'No Active',
  },
  {
    id: 2,
    label: 'Active',
  },
  {
    id: 3,
    label: 'Complete',
  },
];

export {
  SPRINT_STATUS,
  OPTIONS_OF_STATUSES_SPRINTS,
};

export default STATUS;
