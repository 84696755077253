import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../../../../ui-kit';
import './style.sass';

const CreateProjectModal = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  name,
  handleBlur,
  touched,
  values,
  errors,
}) => {
  const { t } = useTranslation('common');
  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={() => onCloseModal(name)}
      className="create-process__modal"
      modalName="create-process"
      title="Create business process"
    >
      <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="create-process">
        <FormsCollection.Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.title}
          error={propOr('', 'message', errors.title)}
          touched={touched.title}
          name="title"
          id="create-process-modal__title"
          placeholderJump="Process title"
          className="create-process__title-field"
        />
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            className="button--md create-process__button-save button-brill--fill"
            type="submit"
          >
            {t('Save')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};

CreateProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CreateProjectModal;
