import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';

import ProcessesListContainer from './processesListContainer';
import { bpActions, bpSelectors } from '../../store/bpModule';
import { uiActions } from '../../../../../state/ui';
import { preloaderWhileLoading } from '../../../../../utils/enchancers';
import withBrillMaker from '../../../../../utils/enchancers/withBrillsBuilder';
import { PRELOADER_DIMENSION } from '../../../../../constants/ui';

const mapStateToProps = store => ({
  result: bpSelectors.getBusinessProcessResult(store),
  isPending: bpSelectors.getBusinessProcessesPending(store),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  getBusinessProcesses: bpActions.getBusinessProcessesRequest,
};

const onDeleteProcessHandler = ({ setCrudProcessConfig, openModal }) => (selectedProcess) => {
  setCrudProcessConfig({ selectedProcess });
  openModal('deleteProcessModal');
};

const enhance = compose(
  withState('processesFilter', 'setProcessesFilter', {}),
  withState('crudProcessConfig', 'setCrudProcessConfig', {}),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDeleteProcess: onDeleteProcessHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getBusinessProcesses } = this.props;
      getBusinessProcesses({});
    },
    componentDidUpdate(prevProps) {
      const { processesFilter, getBusinessProcesses } = this.props;
      if (processesFilter !== prevProps.processesFilter) {
        getBusinessProcesses(processesFilter);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 800,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
  withBrillMaker('refToBrillBuilder'),
);

export default enhance(ProcessesListContainer);
