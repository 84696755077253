import { handleActions } from 'redux-actions';
import types from './types';
import { mergeIn } from '../../utils/helpers/stateHelpers';

const initialState = ({
  customer: {},
  subscription: {},
});

const reducer = handleActions({
  [types.SET_CUSTOMER]: mergeIn(({ payload: { result: { customer, subscription } } }) => ({
    customer, subscription,
  })),
  [types.SET_SUBSCRIPTION]: mergeIn(({ payload: { subscription } }) => ({
    subscription,
  })),
}, initialState);

export default reducer;
