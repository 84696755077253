import React from 'react';
import PropTypes from 'prop-types';

import { AgendaBoardHeader, FiltersRow, StatusesBoard } from './components';
import { ScrollWrapper } from '../../ui-kit';
import './style.sass';

const AgendaBoard = ({
  agendaStatusesList,
  sprints,
  onSelectSprint,
  getFilter,
  tasks,
  setRef,
  onSelectUser,
  onResetSprint,
}) => {
  const getAssigneesIdsFromTasks = () => {
    const tasksArray = tasks && Object.values(tasks);
    const matches = [];
    tasksArray.forEach((item) => {
      if ((item).assignee !== null) {
        matches.push((item).assignee);
      }
    });
    return [...new Set(matches)];
  };
  const assigneesIds = getAssigneesIdsFromTasks();

  return (
    <div
      className="agenda-board"
      ref={e => setRef('agendaRow', e)}
    >
      <FiltersRow
        assigneesIds={assigneesIds}
        sprints={sprints}
        onSelectSprint={onSelectSprint}
        onSelectUser={onSelectUser}
        onResetSprint={onResetSprint}
        currentItem={getFilter(null, 'sprint')}
        selectedUsers={getFilter([], 'assignee')}
        spentTime={68.889}
      />
      <div className="agenda-board__column-header">
        <AgendaBoardHeader sprintId={getFilter(null, 'sprint')} />
      </div>
      <ScrollWrapper autoHide isEasyScroll className="agenda-board__scroll-wrapper">
        <div
          className="agenda-board__column-wrapper"
        >
          <StatusesBoard statuses={agendaStatusesList} />
        </div>
      </ScrollWrapper>
    </div>
  );
};

AgendaBoard.propTypes = {
  agendaStatusesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  sprints: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectSprint: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  onResetSprint: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
};

AgendaBoard.defaultProps = {
};

export default AgendaBoard;
