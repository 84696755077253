import React, { useCallback } from 'react';
import { pathOr, path, compose } from 'ramda';
import { push as routerPush } from 'connected-react-router';

import { useDispatch, useSelector } from 'react-redux';
import {
  Pagination, Grid, Avatar, BrillMask, ButtonsCollection,
} from '../../../../../../../ui-kit';
import { getFullName } from '../../../../../../../utils/helpers/userHelpers';
import { USER_STATUSES } from '../../../../../../../constants/users';
import { getImageUrl } from '../../../../../../../utils/helpers/requestHelpers';
import { WINDOW_WIDTH } from '../../../../../../../constants/ui';
import { permissionsSelectors } from '../../../../../../../state/permissions';
import { useWindowWidth } from '../../../../../../../utils/hooks';
import './style.sass';
import { useSubscriptionPermission } from '../../../../../../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../../../../../../constants/pemissions';

/**
 * @param usersEntities {object}
 * @param usersList {array}
 * @param usersCount {number}
 * @param gridName {string}
 * @param pagination {object}
 * @param order {string}
 * @param sort {string}
 * @param onSortBy {function}
 * @param isLoading {boolean}
 * @param onDeleteUser {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const UsersList = ({
  usersEntities,
  usersList,
  usersCount,
  gridName,
  pagination,
  order = '',
  sort = '',
  onSortBy,
  isLoading = true,
  onDeleteUser,
}) => {
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const roles = useSelector(permissionsSelectors.getRolesEntities);
  const push = useCallback((value) => {
    dispatch(routerPush(value));
  });
  useSubscriptionPermission(MODULES.USERS);

  return (
    <Grid.Wrapper
      isLoading={!isLoading}
      isKeepOptions
      name={gridName}
      className="table-users"
      pagination={(
        <Pagination
          count={usersCount}
          component={gridName}
          pagination={pagination}
          className="pagination"
        />
      )}
    >
      <Grid.Row className="table-users__row table-users__row-controls">
        <Grid.Col
          sort={sort}
          order={order}
          filter="id"
          onClick={() => onSortBy({ sort: 'id' })}
          name="#"
          className="table-users__col"
        />
        <div className="table-users__col--avatar" />
        {windowWidth > WINDOW_WIDTH.SMALL && (
        <Grid.Col
          sort={sort}
          order={order}
          filter="name"
          onClick={() => onSortBy({ sort: 'name' })}
          name="Name"
          className="table-users__col"
        />
        )}
        <Grid.Col
          order={order}
          sort={sort}
          filter="username"
          name="Username"
          onClick={() => onSortBy({ sort: 'username' })}
          className="table-users__col"
        />
        <Grid.Col
          order={order}
          sort={sort}
          onClick={() => onSortBy({ sort: 'email' })}
          filter="email"
          name="Email"
          className="table-users__col"
        />
        <Grid.Col
          order={order}
          sort={sort}
          onClick={() => onSortBy({ sort: 'role_id' })}
          filter="role_id"
          name="Role"
          className="table-users__col"
        />
        <Grid.Col
          order={order}
          sort={sort}
          onClick={() => onSortBy({ sort: 'status' })}
          filter="done"
          name="Status"
          className="table-users__col"
        />
        {windowWidth > WINDOW_WIDTH.SMALL && (
        <Grid.Col className="table-users__col" />
        )}
      </Grid.Row>
      {
          usersList.map(userId => (
            <Grid.Row
              key={`project-grid - ${userId}`}
              className="table-users__row project-row"
              onClick={() => push(`/preferences/users/${userId}`)}
            >
              <Grid.ColData className="table-users__col">{userId}</Grid.ColData>
              <Grid.ColData className="table-users__col--avatar">
                <BrillMask>
                  <Avatar
                    src={getImageUrl(path([userId, 'avatar'], usersEntities))}
                    alt={getFullName(usersEntities[userId])}
                  />
                </BrillMask>
              </Grid.ColData>
              <Grid.ColData className="table-users__col table-users__col--name">
                <div className="text--cut">{getFullName(usersEntities[userId])}</div>
              </Grid.ColData>
              <Grid.ColData className="table-users__col table-users__col--username">
                <div className="text--cut">{pathOr('', [userId, 'username'], usersEntities)}</div>
              </Grid.ColData>
              <Grid.ColData className="table-users__col table-users__col--email">
                <div className="text--cut p-xs text--breaker">
                  {pathOr('', [userId, 'email'], usersEntities)}
                </div>
              </Grid.ColData>
              <Grid.ColData className="table-users__col table-users__col--role">
                <div className="text--cut">
                  {compose(roleId => pathOr('-', [roleId, 'name'], roles),
                    pathOr('', [userId, 'role_id']))(usersEntities)}
                </div>
              </Grid.ColData>
              <Grid.ColData className="table-users__col">
                <span
                  className="user-status text--cut"
                  style={{
                    color: pathOr('-',
                      [pathOr(-1, [userId, 'status'], usersEntities), 'color'],
                      USER_STATUSES),
                  }}
                >
                  {pathOr('-', [pathOr(-1, [userId, 'status'], usersEntities), 'title'],
                    USER_STATUSES)}
                </span>
              </Grid.ColData>
              <Grid.ColData className="table-users__col table-users__col--controls">
                <ButtonsCollection.ButtonIcons
                  onClick={() => push(`/preferences/users/${userId}/`)}
                  title="Edit user"
                >
                  <span className="icon-edit-icon" />
                </ButtonsCollection.ButtonIcons>
              </Grid.ColData>
              <Grid.ColData className="table-users__col table-users__col--controls">
                <ButtonsCollection.ButtonIcons
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDeleteUser(usersEntities[userId]);
                  }}
                  title="Delete user"
                >
                  <span className="icon-trash" />
                </ButtonsCollection.ButtonIcons>
              </Grid.ColData>
            </Grid.Row>
          ))}
    </Grid.Wrapper>
  );
};
