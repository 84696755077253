import React from 'react';
import PropTypes from 'prop-types';
import {
  join, prop, reduce,
} from 'ramda';
import { isNotEmpty } from 'ramda-extension';

import {
  ConfirmModal,
} from '../../../../../../../ui-kit';
import BusinessProcessTypes from '../../../../helpers/types';
import BlockedNodeModal from '../blockedNodeModal';

const DeleteNodeModal = ({
  isOpen,
  isPending,
  onClose,
  event,
  onDelete,
  listOfUse,
  crudNodesConfig,
}) => (
  <BlockedNodeModal modalName="blockedNodeModal" crudNodesConfig={crudNodesConfig}>
    <ConfirmModal
      isOpen={isOpen}
      isPending={isPending}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onClose}
      title="Delete node"
      disabledAction={!!listOfUse.length}
    >

      Are you sure you want to delete
      <b>{prop('title', event)}</b>

      node?
      {
        isNotEmpty(listOfUse) && (
          <small>
            <hr />
            These nodes are using variables of the node:
            <b> {join(' , ', reduce((accum, item) => ([...accum, item]), [], listOfUse))}.</b>.
            You should clean up it, and try again.
          </small>
        )
      }
    </ConfirmModal>
  </BlockedNodeModal>
);

DeleteNodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  listOfUse: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  event: BusinessProcessTypes.Event.isRequired,
  crudNodesConfig: PropTypes.instanceOf(Object).isRequired,
};

export default DeleteNodeModal;
