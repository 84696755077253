import rootApi from '../services/root-api.service';
import { ROOT_API_ROUTES } from '../constants/rootApiRoutes';
import api from '../services/api.service';
import { API_ROUTES } from '../constants/apiRoutes';

const schema = {
  createSubscriptionCheckout: async (params = {}) => {
    try {
      const response = await rootApi.post(ROOT_API_ROUTES.CREATE_SUBSCRIPTION, params);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getPlansRequest: async (params) => {
    try {
      const response = await rootApi.get(`${ROOT_API_ROUTES.PLANS}`, { interval: params });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getCustomer: async (params = {}) => {
    try {
      const response = await api.get(`${API_ROUTES.SUBSCRIBER_INFO}`, params);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  setCard: async (params = {}) => {
    try {
      const response = await rootApi.post(`${ROOT_API_ROUTES.CARD}`, params);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  updateSubscription: async (params = {}, id) => {
    try {
      const response = await rootApi.put(`${ROOT_API_ROUTES.CREATE_SUBSCRIPTION}/${id}`, params);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  cancelSubscription: async (params = {}) => {
    try {
      const response = await rootApi.post(`${ROOT_API_ROUTES.CANCEL_SUBSCRIPTION}`, params);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getCard: async (paymentMethodId) => {
    try {
      const response = await rootApi.get(`${ROOT_API_ROUTES.CARD}/${paymentMethodId}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default schema;
