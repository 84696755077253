import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection, Header } from '../../../../ui-kit';
import SettingRolesContainer from './settingRolesContainer';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { useWindowWidth } from '../../../../utils/hooks';
import './style.sass';
import { useSubscriptionPermission } from '../../../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../../../constants/pemissions';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const RolesWrapper = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  useSubscriptionPermission(MODULES.ROLES);
  return (
    <>
      <Header hideToggleRightSideBar className="role-header">
        <>
          <h1 className="weight--medium title--xl title-page user-nav__title">
            {t('Settings - Roles')}
          </h1>
          <nav className="header-nav header-nav--user">
            <NavLink
              activeClassName="header-nav__link--active"
              className="header-nav__link"
              to="/preferences/users/"
            >
              {t('Users')}
            </NavLink>
            <NavLink
              activeClassName="header-nav__link--active"
              className="header-nav__link"
              to="/preferences/roles"
            >
              {t('Roles')}
            </NavLink>
          </nav>
        </>
        {
          windowWidth > WINDOW_WIDTH.MEDIUM ? (
            <ButtonsCollection.ButtonBrill
              asLink
              href="/preferences/roles/create"
              className="button--sm header__control-button--project"
            >
              {t('New role')}
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              asLink
              renderMobileButton
              href="/preferences/roles/create"
              className="button--sm header__control-button--project"
            />
          )
        }
      </Header>
      <div className="main-container__content main-container__content--roles">
        <SettingRolesContainer />
      </div>
    </>
  );
};

export default RolesWrapper;
