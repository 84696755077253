import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../../ui-kit';
import { SubscriptionContainer } from './subscriptionContainer';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const SubscriptionWrapper = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header hideToggleRightSideBar>
        <h1 className="weight--medium title--xl title-page">
          {t('Settings - Subscription')}
        </h1>
      </Header>
      <div className="main-container__content">
        <SubscriptionContainer />
      </div>
    </>
  );
};
