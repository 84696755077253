import {
  identity, ifElse, memoizeWith, pathOr, prop, propOr,
} from 'ramda';
import { createSelector } from 'reselect/lib/index';

/*
* It's a bad example because this function always return other result
*
* */
// const projectEntitiesSelector = key => createSelector(
//   state => pathOr({}, ['project', 'entities', key], state),
//   identity,
// );

const projectEntitiesSelector = memoizeWith(identity, key => createSelector(
  pathOr({}, ['project', 'entities', key]),
  identity,
));

const projectResultSelector = key => createSelector(
  state => pathOr([], ['project', 'result', key], state),
  identity,
);

const summaryPath = pathOr({}, ['project', 'summary']);

const getProjectSprintTasks = projectEntitiesSelector('tasks');
const getProjectBacklogTasks = projectEntitiesSelector('backlog');
const getProjectSprintsTasks = projectEntitiesSelector('sprints');
const getProjectCategoriesTasks = projectEntitiesSelector('categories');
const getProjectTasksAuthors = projectEntitiesSelector('author');
const getProjectTasksAssignees = projectEntitiesSelector('assigneeUser');
const getAgendaStatuses = projectEntitiesSelector('agenda');

const getSprintsList = projectResultSelector('sprints');
const getProjectCategoriesTasksList = projectResultSelector('categories');
const getAgendaStatusesList = projectResultSelector('statuses');
const getUsersWithTasksList = projectResultSelector('usersWithTasks');

const getProjectSprint = createSelector(
  projectEntitiesSelector('sprints'),
  sprints => memoizeWith(identity, sprintId => pathOr({}, [sprintId], sprints)),
);

const getAgenda = createSelector(
  getAgendaStatuses,
  statuses => memoizeWith(identity, status => propOr({}, status, statuses)),
);

const getProjectSprintTasksList = createSelector(
  getProjectSprintsTasks,
  sprints => memoizeWith(identity, sprintId => pathOr([], [sprintId, 'tasks'], sprints)),
);

const getAgendaTasksList = createSelector(
  getAgendaStatuses,
  statuses => memoizeWith(identity, status => pathOr([], [status, 'tasks'], statuses)),
);

const getProjectSprintTask = createSelector(
  getProjectSprintTasks,
  tasks => memoizeWith(identity, task => pathOr({}, [task], tasks)),
);

const getCategory = createSelector(
  projectEntitiesSelector('categories'),
  categories => memoizeWith(identity, categoryId => pathOr({}, [categoryId], categories)),
);

const getAssignee = createSelector(
  projectEntitiesSelector('assignee'),
  assignee => memoizeWith(identity, assigneeId => pathOr({}, [assigneeId], assignee)),
);

const getAuthor = createSelector(
  projectEntitiesSelector('author'),
  author => memoizeWith(identity, authorId => pathOr({}, [authorId], author)),
);


const getBacklogTasksList = createSelector(
  pathOr([], ['project', 'result', 'backlog']), identity,
);

const getProjectTasksPendingRequest = createSelector(pathOr(false, ['pending', 'getProjectTasksRequest']), identity);
const getProjectRequestPending = createSelector(pathOr(false, ['pending', 'getProject']), identity);
const getPendingAddSprintRequest = createSelector(pathOr(false, ['pending', 'addSprintRequest']), identity);
const getDeleteTaskPendingRequest = createSelector(pathOr(false, ['pending', 'deleteProjectTaskRequest']), identity);
const getAgendaTasksRequestPending = createSelector(pathOr(false, ['pending', 'getAgendaTasksRequest']), identity);
const getAgendaTasksBySprintRequestPending = createSelector(pathOr(false, ['pending', 'getAgendaTasksBySprintRequest']), identity);
const getCategoryAddRequestPending = createSelector(pathOr(false, ['pending', 'addCategoryTaskRequest']), identity);
const getCategoryDeleteRequestPending = createSelector(pathOr(false, ['pending', 'deleteCategoryTaskRequest']), identity);
const getCategoryUpdateRequestPending = createSelector(pathOr(false, ['pending', 'updateCategoryTaskRequest']), identity);
const getImportantTasksRequestPending = createSelector(pathOr(false, ['pending', 'getImportantTasksRequest']), identity);
const getNotesRequestPending = createSelector(pathOr(false, ['pending', 'getNotesRequest']), identity);
const getUpdateNoteRequestPending = createSelector(pathOr(false, ['pending', 'updateNoteRequest']), identity);
const getDeleteNoteRequestPending = createSelector(pathOr(false, ['pending', 'deleteNoteRequest']), identity);
const getAddNoteRequestPending = createSelector(pathOr(false, ['pending', 'addNoteRequest']), identity);
const getDeleteSprintRequestPending = createSelector(pathOr(false, ['pending', 'deleteSprintRequest']), identity);
const getUpdateSprintRequestPending = createSelector(pathOr(false, ['pending', 'updateSprintRequest']), identity);

const addMembersToProjectPending = createSelector(pathOr(false, ['pending', 'addProjectMembersRequest']), identity);
const getDeleteProjectMemberPending = createSelector(pathOr(false, ['pending', 'deleteProjectMemberRequest']), identity);

const getProjectData = createSelector(
  projectEntitiesSelector('data'),
  data => memoizeWith(identity, projectId => pathOr({}, [projectId], data)),
);

const getTask = createSelector(
  state => memoizeWith(identity, taskId => pathOr({}, ['project', 'entities', 'tasks', taskId], state)), identity,
);

const getBacklogTask = createSelector(
  state => memoizeWith(identity, taskId => pathOr({}, ['project', 'entities', 'backlog', taskId], state)), identity,
);

const getCategoryTasks = createSelector(
  state => memoizeWith(identity, categoryId => pathOr({}, ['project', 'entities', 'categories', categoryId], state)), identity,
);

const getImportantTasksList = createSelector(pathOr([], ['project', 'result', 'importantTasks']), identity);
const getImportantTasksEntities = createSelector(pathOr({}, ['project', 'entities', 'importantTasks']), identity);

const getPinnedTasksList = createSelector(pathOr([], ['project', 'pinnedTasks', 'result']), identity);
const getPinnedTasksEntities = createSelector(pathOr({}, ['project', 'pinnedTasks', 'entities']), identity);
const getPinnedTasksCount = createSelector(pathOr(0, ['project', 'pinnedTasks', 'count']), identity);
const getPinnedTasksHasMore = createSelector(pathOr(false, ['project', 'pinnedTasks', 'hasMore']), identity);
const getPinnedTasksPending = createSelector(pathOr(false, ['pending', 'getPinnedTasksRequest']), identity);

const getTaskAssignee = createSelector(
  state => memoizeWith(identity, assigneeId => pathOr({}, ['project', 'entities', 'assignee', assigneeId], state)), identity,
);

const getProjectSummary = createSelector(summaryPath, identity);

const getNotesList = createSelector(pathOr([], ['project', 'result', 'documents']), identity);

const getNotesEntities = createSelector(pathOr({}, ['project', 'entities', 'documents']), identity);

const getNote = createSelector(state => memoizeWith(identity, documentId => pathOr({}, ['project', 'entities', 'documents', documentId], state)), identity);


const getProjectMembers = createSelector(pathOr({}, ['project', 'entities', 'members']), identity);

const getProjectMembersList = createSelector(pathOr([], ['project', 'result', 'members']), identity);

const getTaskFromAnyStore = (state, taskId) => ifElse(prop('id'), identity, () => getBacklogTask(state)(taskId))(getTask(state)(taskId));

export default getProjectData;

const getStatusesOfTasksEntities = createSelector(pathOr({}, ['project', 'entities', 'agenda']), identity);

const getStatusesOfTasksList = createSelector(pathOr([], ['project', 'result', 'statuses']), identity);

const getStatusOfTasks = createSelector(
  state => memoizeWith(identity, statusId => pathOr({}, ['project', 'entities', 'agenda', statusId], state)), identity,
);

const getDeleteStatusOfTasksPending = createSelector(pathOr(false, ['pending', 'deleteStatusOfTasks']), identity);
const getStatusesOfTasksPending = createSelector(pathOr(false, ['pending', 'getStatusesOfTasksRequest']), identity);


export {
  getProjectSprintTask,
  getProjectSprintTasks,
  getProjectSprint,
  getProjectBacklogTasks,
  getCategory,
  getProjectSprintsTasks,
  getAssignee,
  getAuthor,
  getProjectTasksAuthors,
  getBacklogTasksList,
  getProjectTasksAssignees,
  getPendingAddSprintRequest,
  getProjectSprintTasksList,
  getSprintsList,
  getProjectRequestPending,
  getProjectTasksPendingRequest,
  getDeleteTaskPendingRequest,
  getTask,
  getAgendaStatuses,
  getProjectCategoriesTasksList,
  getAgenda,
  getProjectCategoriesTasks,
  getCategoryTasks,
  getBacklogTask,
  getAgendaTasksList,
  getTaskAssignee,
  getUsersWithTasksList,
  getAgendaStatusesList,
  getImportantTasksList,
  getImportantTasksEntities,
  getAgendaTasksBySprintRequestPending,
  getAgendaTasksRequestPending,
  getCategoryAddRequestPending,
  getCategoryUpdateRequestPending,
  getCategoryDeleteRequestPending,
  getImportantTasksRequestPending,
  getDeleteSprintRequestPending,
  getUpdateSprintRequestPending,
  getProjectSummary,
  getNotesList,
  getNotesEntities,
  getNote,
  getDeleteNoteRequestPending,
  getNotesRequestPending,
  getUpdateNoteRequestPending,
  getAddNoteRequestPending,
  getProjectMembers,
  addMembersToProjectPending,
  getProjectMembersList,
  getDeleteProjectMemberPending,
  getPinnedTasksList,
  getPinnedTasksEntities,
  getPinnedTasksCount,
  getPinnedTasksHasMore,
  getPinnedTasksPending,
  getTaskFromAnyStore,
  getStatusesOfTasksEntities,
  getStatusesOfTasksList,
  getStatusOfTasks,
  getDeleteStatusOfTasksPending,
  getStatusesOfTasksPending,
};
