import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonsCollection, Layouts, NoInternetConnection, PermissionElement,
} from '../../ui-kit';
import {
  CoWorkers,
  // NavGroup,
  ProjectsNav,
  // TaskList,
} from '../../newContainers/leftSideBar/components';
import {
  Header, ProjectsList,
} from '../../containers';
import { LeftSideBar } from '../../newContainers';
import { WINDOW_WIDTH } from '../../constants/ui';
import './style.sass';
import { RightSideBarContainer } from './components';
import { ACTIONS, MODULES } from '../../constants/pemissions';
import { usePreviousPath } from '../../utils/hooks';
import { useSubscriptionPermission } from '../../utils/hooks/useSubscriptionPermission';

const Projects = ({
  openModal, t, windowWidth,
}) => {
  const { path, pathHandler } = usePreviousPath();
  useSubscriptionPermission(MODULES.PROJECTS);
  return (
    <Layouts.Main>
      <LeftSideBar>
        <ProjectsNav />
        <CoWorkers />
      </LeftSideBar>
      <div className="main-container page-projects__main-container">
        <NoInternetConnection>
          <Header>
            <h1 className="weight--medium title--xl title-page">{t('All projects')}</h1>
              <>
                <PermissionElement
                  module={MODULES.PROJECTS}
                  action={ACTIONS.CREATE}
                >
                  {
                    windowWidth > WINDOW_WIDTH.MEDIUM ? (
                      <ButtonsCollection.ButtonBrill
                        onClick={() => openModal('createProjectModal')}
                        className="button--sm header__control-button--project"
                      >
                        {t('Start a new project')}
                      </ButtonsCollection.ButtonBrill>
                    ) : (
                      <ButtonsCollection.ButtonPlus
                        onClick={() => openModal('createProjectModal')}
                        className="header__control-button--project"
                      />
                    )
                  }
                </PermissionElement>
              </>
          </Header>
          <div className="main-container__content main-container__content--projects">
            <ProjectsList path={path} onPath={pathHandler} windowWidth={windowWidth} />
            <RightSideBarContainer windowWidth={windowWidth} />
          </div>
        </NoInternetConnection>
      </div>
    </Layouts.Main>
  );
};


Projects.propTypes = {
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default Projects;
