import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { push as routePush } from 'connected-react-router';

import { withTranslation } from 'react-i18next';
import CreateProcessModal from './createProcessModal';
import rules from './rules';
import { uiActions, uiSelectors } from '../../../../../../../state/ui';
import { bpActions } from '../../../../store/bpModule';
import { BUSINESS_PROCESS_STATUSES } from '../../../../constants/api';

const mapStateToProps = store => ({
  isOpen: uiSelectors.getModal(store)('createProcessModal'),
});

const mapDispatchToProps = {
  addBusinessProcess: bpActions.addBusinessProcessRequest,
  onCloseModal: () => uiActions.closeModal('createProcessModal'),
  push: routePush,
};

const onSubmit = (formValues, { props: { addBusinessProcess, push, onCloseModal }, resetForm }) => {
  addBusinessProcess({ ...formValues, status: BUSINESS_PROCESS_STATUSES.NOT_ACTIVE }, {
    callbacks: {
      success: ({ bp }) => {
        onCloseModal();
        resetForm({});
        push(`/business-processes/${bp.id}`);
      },
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withFormik({
    mapPropsToValues: ({ initialValues = {} }) => initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: onSubmit,
  }),
);

export default enhance(CreateProcessModal);
