import React from 'react';

import { ButtonsCollection, ScrollWrapper, Header } from '../../../../../ui-kit';
import { ProcessesListContainer } from '../../containers';
import { WINDOW_WIDTH } from '../../../../../constants/ui';

const BusinessProcesses = ({ windowWidth, onCreateProcessModal }) => (
  <>
    <Header className="role-header">
      <>
        <h1 className="weight--medium title--xl title-page">
          Business Processes
        </h1>
      </>
      {
        windowWidth > WINDOW_WIDTH.MEDIUM ? (
          <ButtonsCollection.ButtonBrill
            onClick={onCreateProcessModal}
            className="button--sm header__control-button--project"
          >

            New Process
          </ButtonsCollection.ButtonBrill>
        ) : (
          <ButtonsCollection.ButtonPlus
            onClick={onCreateProcessModal}
            className="header__control-button--project"
          />
        )
      }
    </Header>
    <ScrollWrapper>
      <div className="main-container__content main-container__content--roles">
        <ProcessesListContainer />
      </div>
    </ScrollWrapper>
  </>
);

export default BusinessProcesses;
