import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';

export const {
  createSubscriptionRequest,
  getPlansRequest,
  getCustomerRequest,
  setCardRequest,
  updateSubscriptionRequest,
  cancelSubscriptionRequest,
  setCustomer,
  setSubscription,
} = createActions(
  {
    [types.CREATE_SUBSCRIPTION_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscription',
        selectorName: 'createSubscriptionRequest',
        method: 'POST',
        params,
        ...meta,
      }),
    ],
  },
  types.SET_CUSTOMER,
  types.SET_SUBSCRIPTION,
);
