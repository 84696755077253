import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { changeRightSidebarStatus } from '../../state/ui/actions';
import { getRightSidebar } from '../../state/ui/selectors';
import { ButtonsCollection } from '..';

import './style.sass';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const ToggleRightSideBarButton = () => {
  const { t } = useTranslation('common');
  const status = useSelector(getRightSidebar);
  const dispatch = useDispatch();

  const toggleRightSideBarHandler = useCallback(() => {
    dispatch(changeRightSidebarStatus(!status));
  }, [status]); // eslint-disable-line

  return (
    <ButtonsCollection.ButtonIcons
      title={t('Toggle right side bar')}
      className={classNames('toggle-right-aside', { 'toggle-right-aside--active': status })}
      onClick={toggleRightSideBarHandler}
    >
      <span className="icon-toggle-right-sidebar" />
    </ButtonsCollection.ButtonIcons>
  );
};

export default memo(ToggleRightSideBarButton);
