import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection, Header } from '../../../../ui-kit';
import SettingUsersContainer from './settingUsersContainer';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { useWindowWidth } from '../../../../utils/hooks';
import './style.sass';
import { useSubscriptionPermission } from '../../../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../../../constants/pemissions';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const UsersWrapper = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  useSubscriptionPermission(MODULES.SETTINGS);
  return (
    <>
      <Header hideToggleRightSideBar className="user-header">
        <>
          <h1 className="weight--medium title--xl title-page user-nav__title">
            {t('Settings - Users')}
          </h1>
          <nav className="header-nav header-nav--user">
            <NavLink
              activeClassName="header-nav__link--active"
              className="header-nav__link"
              to="/preferences/users"
            >
              {t('Users')}
            </NavLink>
            <NavLink
              activeClassName="header-nav__link--active"
              className="header-nav__link"
              to="/preferences/roles"
            >
              {t('Roles')}
            </NavLink>
          </nav>
        </>
        {
          windowWidth > WINDOW_WIDTH.MEDIUM ? (
            <ButtonsCollection.ButtonBrill
              asLink
              href="/preferences/users/invite-user"
              type="button"
              className="user-header__button-invite"
            >
              {t('Create user')}
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              asLink
              href="/preferences/users/invite-user"
              type="button"
              renderMobileButton
              className="user-header__button-invite"
            />
          )
        }
      </Header>
      <div className="main-container__content">
        <SettingUsersContainer />
      </div>
    </>
  );
};
