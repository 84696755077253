import { branch, compose, renderNothing } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { T, identity, is } from 'ramda';

const mapDispatchToProps = ({ conf }) => (dispatch, ownProps) => {
  const {
    name, action, dataPath, params, searchField, meta = null, appendOption,
    appendOptionWhen = T, filterFunc,
  } = is(Function, conf) && conf(ownProps) ? conf(ownProps) : conf;
  return ({
    [name]: (inputValue, callback) => dispatch(action({
      [searchField]: inputValue,
      limit: 10,
      ...(params && params(ownProps)),
    }, {
      ...meta,
      callbacks: {
        success: (data) => {
          const appendOptions = (is(Function, appendOption) && appendOptionWhen(ownProps))
            ? [appendOption(ownProps)] : [];
          const filterFuncResult = filterFunc ? filterFunc(ownProps) : identity;
          callback(filterFuncResult([...appendOptions, ...dataPath(data)]));
        },
      },
    })),
  });
};

const WithAutocomplete = conf => (BaseComponent) => {
  const WrapperComponent = props => <BaseComponent {...props} />;
  const enhancer = compose(
    branch(() => !conf, renderNothing),
    connect(null, mapDispatchToProps({
      conf,
    })),
  );
  return enhancer(WrapperComponent);
};

export default WithAutocomplete;
