import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, DefaultAutoComplete, FormsCollection } from '../../../../../../../ui-kit';
import BusinessProcessTypes from '../../../../helpers/types';
import FieldByOption from '../fieldByOption/index';
import './style.sass';
import VariablesModal from './variablesModal';

const FormView = ({
  handleChange, optionsOfActions, onChangeAction, selectedAction, fields, values, errors,
  handleSubmit, setCrudNodesConfig, crudNodesConfig, setFieldValue, options, setFieldError,
  resetForm,
}) => (
  <FormsCollection.FormWrapper className="processContainer__form-main editActionForm" handleSubmit={handleSubmit}>
    <div className="processContainer__field-group">
      <span className="processContainer__label">Action</span>
      <DefaultAutoComplete
        options={optionsOfActions}
        onChange={onChangeAction}
        value={selectedAction}
        name="action"
      />
    </div>
    {
      !!fields.length && (
        <div className="editActionForm__form">
          <h4 className="editActionForm__title title--m">Action settings</h4>
          {
            fields.map(fieldConfig => (
              <FieldByOption
                key={fieldConfig.id}
                setCrudNodesConfig={setCrudNodesConfig}
                handleChange={handleChange}
                config={fieldConfig}
                values={values}
                fieldGroup="fields"
                fieldsConfig={fields}
                isUseVariable
                setFieldValue={setFieldValue}
                selectedAction={selectedAction}
                errors={errors}
                setFieldError={setFieldError}
                resetForm={resetForm}
              />
            ))
          }
        </div>
      )
    }
    {
      !!options.length && (
        <div className="editActionForm__form">
          <h4 className="editActionForm__title title--m">Action rules</h4>
          {
            options.map(fieldConfig => (
              <FieldByOption
                key={fieldConfig.name}
                setCrudNodesConfig={setCrudNodesConfig}
                handleChange={handleChange}
                config={fieldConfig}
                values={values}
                setFieldValue={setFieldValue}
                fieldGroup="options"
                fieldsConfig={options}
                selectedAction={selectedAction}
                errors={errors}
                setFieldError={setFieldError}
                resetForm={resetForm}
              />
            ))
          }
        </div>
      )
    }
    <ButtonsCollection.ButtonBrill
      type="submit"
      className="button--md button-brill--fill editActionForm__button-save"
    >
      Save
    </ButtonsCollection.ButtonBrill>
    <VariablesModal
      selectedAction={selectedAction}
      crudNodesConfig={crudNodesConfig}
      setFieldValue={setFieldValue}
      values={values}
    />
  </FormsCollection.FormWrapper>
);

FormView.propTypes = {
  handleChange: PropTypes.func.isRequired,
  optionsOfActions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onChangeAction: PropTypes.func.isRequired,
  selectedAction: PropTypes.instanceOf(Object).isRequired,
  fields: PropTypes.arrayOf(BusinessProcessTypes.EventField).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setCrudNodesConfig: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  crudNodesConfig: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default FormView;
