import { bpActions } from '../store/bpModule';

export const NODES_ACTIONS_CONST = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const NODES_REDUX_ACTIONS = {
  [NODES_ACTIONS_CONST.CREATE]: bpActions.addBusinessProcessNodeRequest,
  [NODES_ACTIONS_CONST.UPDATE]: bpActions.editBusinessProcessNodeRequest,
};
