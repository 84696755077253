import { toCamelCase } from 'ramda-extension';
import {
  createTexNode,
  insertNodeAfterElement,
} from '../../../../utils/helpers/uiComponentHelpers/caretHelpers';

export const getVariable = ({
  label, description = label, nodeId, variableId,
}) => {
  const emptyElement = createTexNode();
  const element = document.createElement('variable');
  element.innerHTML = `
   <div class="label label--fill bp-variable time-picker__label-current label--xs" title="${description}">
    <span class="label__wrapper"><span class="label__text">${label}
    <button
      type="button"
      title="Delete this variable"
      class="bp-variable-delete"
    >
      <span class="icon-close" />
    </button>
    </span></span>
    <span class="label__pseudo-label">
      <svg
        version="1.1"
        stroke="red"
        fill="none"
        stroke-width="2"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 19.4 48"
        style="enable-background: 'new 0 0 19.4 48'"
        class="label__left-part"
      >

        <path
          class="st0"
          d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5
c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
        />
      </svg>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        stroke="red"
        stroke-width="2"
        fill="none"
        y="0px"
        viewBox="0 0 227.3 48"
        style="enable-background: 'new 0 0 227.3 48'"
        class="label__middle-part"
        preserve-aspect-ratio="none"
      >
        <g>
          <path class="st0" d="M-9.1,47h243" />
          <path class="st0" d="M236.7,1H-9.5" />
        </g>
      </svg>
      <svg
        version="1.1"
        stroke="red"
        fill="none"
        stroke-width="2"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 19.4 48"
        style="enable-background: 'new 0 0 19.4 48'"
        class="label__right-part"
      >

        <path
          class="st0"
          d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
        />
      </svg>
    </span>
  </div>`;

  element.setAttribute('data-value', `{${nodeId}|${variableId}}`);
  element.setAttribute('contenteditable', false);

  insertNodeAfterElement(element, emptyElement);

  return element;
};

export const getVariableLabel = (node, event, variable) => `[${node.id}]${toCamelCase(event.key)}->${toCamelCase(variable.name)}`;
