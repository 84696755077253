import React from 'react';
import PropTypes from 'prop-types';

import { EditNote, DeleteNote, CreateNote } from './components/notesCRUD';
import { FilterRow, NotesList } from './components';
import './style.sass';
import { ButtonsCollection, PermissionElement } from '../../../../ui-kit';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import {useSubscriptionPermission} from "../../../../utils/hooks/useSubscriptionPermission";


const LeadNotes = ({
  onChangeSorting, onCreateNote, sortBy, leadId, notes, setOffset, offset,
}) => {
  const { val: { order, sortBy: sortByFilter } } = sortBy;
  const childProps = {
    leadId, order, sortBy: sortByFilter, setOffset, offset,
  };
  useSubscriptionPermission(MODULES.LEAD_DOCUMENTS);
  return (
    <>
      <PermissionElement
        module={MODULES.LEAD_DOCUMENTS}
        action={[ACTIONS.CREATE, ACTIONS.UPDATE, ACTIONS.DELETE]}
      >
        <ButtonsCollection.ButtonBrill
          onClick={onCreateNote}
          className="button--sm header__control-button--project add-notes-button"
        >
        New note
        </ButtonsCollection.ButtonBrill>
        {notes.length > 0 && (<FilterRow onChangeSorting={onChangeSorting} sortBy={sortBy} />)}
        <NotesList {...childProps} />
        <EditNote {...childProps} />
        <DeleteNote {...childProps} />
        <CreateNote {...childProps} />
      </PermissionElement>
    </>
  );
};

LeadNotes.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  onCreateNote: PropTypes.func.isRequired,
  leadId: PropTypes.oneOfType([
    PropTypes.number,
    () => null,
  ]).isRequired,
  sortBy: PropTypes.instanceOf(Object).isRequired,
};

export default LeadNotes;
