export default {
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  SET_ROLES: 'SET_ROLES',

  GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  SET_ROLE: 'SET_ROLE',

  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',

  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',

  GET_MODULES_REQUEST: 'GET_MODULES_REQUEST',
  SET_MODULES: 'SET_MODULES',

  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
};
