import React from 'react';
import PropTypes from 'prop-types';
import {
  join, prop, reduce,
} from 'ramda';
import { isNotEmpty } from 'ramda-extension';

import {
  ConfirmModal,
} from '../../../../../../../ui-kit';
import BusinessProcessTypes from '../../../../helpers/types';

const BlockedNodeModal = ({
  isOpen,
  onClose,
  event,
  listOfUse,
  children,
}) => (
  <>
    {
      !listOfUse.length ? (
        <>{children}</>
      ) : (
        <ConfirmModal
          isOpen={isOpen}
          onClose={onClose}
          title="Delete node"
          disabledAction={!!listOfUse.length}
        >

          Node
          <b>{prop('title', event)}</b>
          is blocked, because variables of the node is using?
          {
            isNotEmpty(listOfUse) && (
              <small>
                <hr />
                These nodes are using variables of the node:
                <b> {join(' , ', reduce((accum, item) => ([...accum, item]), [], listOfUse))}.</b>.
                You should clean up it, and try again.
              </small>
            )
          }
        </ConfirmModal>)
    }
  </>
);

BlockedNodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  listOfUse: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  event: BusinessProcessTypes.Event.isRequired,
};

export default BlockedNodeModal;
