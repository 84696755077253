import QueryString from 'qs';
import {
  compose, lifecycle, withContext, withHandlers, withProps, withStateHandlers, withState,
} from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  curry, isEmpty, prop, and, not, equals,
} from 'ramda';


import { STYLE_VIEW, MY_PROJECTS_FILTER } from '../../constants/projects';
import AllProjects from './allProjects';
import {
  withPagination,
  withSorting,
  withRefs,
  withOffset,
  withIsLoadingMore, withUrlParams, withFilters,
} from '../../utils/enchancers';
import { projectsActions, projectsSelectors } from '../../state/projects';
import { clientsActions } from '../../state/clients';
import { getOppositeValue, renameKeys } from '../../utils/helpers/commonHelpers';
import { setFilterForSingleParam } from '../../utils/helpers/uiComponentHelpers/filters';
import withLocationSearchChanged from '../../utils/enchancers/withLocationChanged';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';

const LIMIT_PROJECTS_FOR_LIST = 10;
const LIMIT_PROJECTS_FOR_CELLS = 60;
const DEFAULT_PARAMS = { offset: 0, page: 1 };

const renameParams = renameKeys({ sort: 'sortBy' });

const defaultSorting = {
  order: 'desc',
  sort: 'created_at',
};


const getLimitProjects = styleView => (styleView === STYLE_VIEW.CELL ? LIMIT_PROJECTS_FOR_CELLS
  : LIMIT_PROJECTS_FOR_LIST);

const makeSortingParams = curry((params, sort, order) => {
  const sortingParams = params;
  if (sort) {
    sortingParams.sort = sort;
  }
  if (order) {
    sortingParams.order = order;
  }
  return sortingParams;
});

const getSortingParamsForRequest = curry((viewStyle, sort, order) => {
  if (viewStyle === STYLE_VIEW.CELL) {
    return makeSortingParams({}, sort, order);
  } if (viewStyle === STYLE_VIEW.LIST) {
    return makeSortingParams({}, sort, order);
  }
  return defaultSorting;
});

const getLimitOffsetParamsForRequest = curry((viewStyle, limit, offset) => (
  viewStyle === STYLE_VIEW.CELL ? { limit: LIMIT_PROJECTS_FOR_CELLS, offset: 0 } : { limit, offset }
));

const getCallbacksForRequest = curry((viewStyle, success) => (
  viewStyle === STYLE_VIEW.CELL ? { callbacks: ({ success }) } : {}
));

const changeCurrentViewStyleStateHandler = () => val => ({ viewStyle: val });

const mapStateToProps = state => ({
  projectsListPending: projectsSelectors.getProjectsListPending(state),
  data: projectsSelectors.getProjectsList(state),
  hasMoreProjects: projectsSelectors.getProjectsListHasMore(state),
  count: projectsSelectors.getProjectsListCount(state),
  viewStyle: projectsSelectors.getProjectsViewStyle(state),
});

const mapDispatchToProps = ({
  getProjectsRequest: projectsActions.getProjectsListRequest,
  deleteFavoriteProject: projectsActions.removeFromFavoriteProjectsFetch,
  setFavoriteProject: projectsActions.addToFavoriteProjectsFetch,
  getClientsRequest: clientsActions.getClientsRequest,
  getSummaryProjects: projectsActions.getProjectsSummaryRequest,
});

const onScrollHandler = ({
  getProjectsRequest, searchName, order, setOffset, sort, offset, isLoadingMore,
  hasMoreProjects, setIsLoadingMore, viewStyle,
}) => (scroll) => {
  if (viewStyle === STYLE_VIEW.LIST) return scroll;
  const { scrollTop, scrollHeight, clientHeight } = scroll;
  const maxScrollTop = scrollHeight - clientHeight;
  const bottomTop = maxScrollTop - scrollTop;
  const isScroll = scrollHeight !== clientHeight;
  if (bottomTop < 30 && isScroll && !isLoadingMore && hasMoreProjects) {
    setIsLoadingMore(true);
    const paramsForViewStyle = getSortingParamsForRequest(viewStyle);
    getProjectsRequest({
      ...paramsForViewStyle(sort, order),
      limit: LIMIT_PROJECTS_FOR_CELLS,
      order,
      offset,
      title: searchName,
    }, {
      metaAction: { viewStyle: STYLE_VIEW.CELL },
      more: true,
      callbacks: {
        success: ({ data }) => {
          setOffset(data.result);
          setIsLoadingMore(false);
        },
      },
    });
  }
  return scroll;
};

const getParamsFomFilter = projectsFilter => (
  // eslint-disable-next-line no-nested-ternary
  equals(projectsFilter, MY_PROJECTS_FILTER.ALL)
    ? { all: 1 }
    // eslint-disable-next-line no-nested-ternary
    : equals(projectsFilter, MY_PROJECTS_FILTER.CREATED_BY_ME)
      ? { createdByMe: 1 }
    // eslint-disable-next-line no-nested-ternary
      : equals(projectsFilter, MY_PROJECTS_FILTER.FAVORITES)
        ? { favorites: 1 }
        : equals(projectsFilter, MY_PROJECTS_FILTER.ACTIVE)
          ? { status: 1 }
          : { status: 0 }
);

const getProjects = ({
  getProjectsRequest,
  viewStyle,
  pagination,
  sort,
  order,
  getUrlParam,
  setOffset,
  projectsFilter,
  getSummaryProjects,
}) => {
  const limitOffsetViewStyle = getLimitOffsetParamsForRequest(viewStyle);
  const callbacksViewStyle = getCallbacksForRequest(viewStyle);
  const paramsForViewStyle = renameParams(getSortingParamsForRequest(viewStyle)(sort, order));
  const paramsForFilter = getParamsFomFilter(projectsFilter);
  getProjectsRequest({
    ...paramsForFilter,
    ...paramsForViewStyle,
    ...limitOffsetViewStyle(pagination.limit, pagination.offset),
    title: getUrlParam(['title']),
  }, {
    ...callbacksViewStyle(({ data }) => {
      setOffset(data.result);
    }),
    metaAction: { viewStyle },
  });
  getSummaryProjects();
};

const onSearchHandler = props => () => {
  const {
    onSetUrlParam, onResetUrlParam, searchName, mergeFilters, setFilters,
  } = props;
  const setSearchNameFilterAndUrl = setFilterForSingleParam(mergeFilters, setFilters);
  if (isEmpty(searchName)) {
    return setSearchNameFilterAndUrl(onResetUrlParam, 'title', null);
  }
  return setSearchNameFilterAndUrl(onSetUrlParam, 'title', searchName);
};

const setSearchProjectsName = ({ setFilters }) => ({ target: { value } }) => setFilters({
  title: value,
});

const onToggleFavoriteStatusHandler = ({
  deleteFavoriteProject,
  setFavoriteProject,
}) => (e, status, projectId) => {
  e.stopPropagation();
  e.preventDefault();
  if (status) {
    return deleteFavoriteProject({ id: projectId });
  }
  return setFavoriteProject({ id: projectId });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withUrlParams({}),
  withFilters({ initial: ({ getUrlParam }) => ({ title: getUrlParam(['title']) }) }),
  withProps(({ getFilter }) => ({
    gridName: 'projects-list',
    searchName: getFilter('', 'title'),
  })),
  withPagination({
    limit: ({ styleView }) => getLimitProjects(styleView),
    offset: ({ getUrlParam, styleView }) => calculateOffset(getUrlParam(['page'], 1), getLimitProjects(styleView)),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
    selector: ({ gridName }) => gridName,
  }),
  withStateHandlers(
    () => ({ viewStyle: STYLE_VIEW.LIST }), {
      changeCurrentViewStyle: changeCurrentViewStyleStateHandler,
    },
  ),
  withStateHandlers(
    () => ({ statusVisible: 'brill', areMobileFiltersOpen: false }), {
      toggleMobileFiltersHandler:
        ({ areMobileFiltersOpen }) => () => ({ areMobileFiltersOpen: !areMobileFiltersOpen }),
    },
  ),
  withState('isNeedRefreshProjects', 'setIsNeedRefreshProjects', false),
  withState('projectsFilter', 'setProjectsFilter', ({ getUrlParam }) => getUrlParam(['role'], MY_PROJECTS_FILTER.ALL)),
  withOffset,
  withSorting({
    selector: props => props.getUrlParams({}),
    values: ({
      order: ({ getUrlParam }) => getUrlParam(['order'], 'desc'),
      sort: ({ getUrlParam }) => getUrlParam(['sort'], 'created_at'),
    }),
    onSortBy: ({onSetUrlParam }) => (dataSorting) => {
      const sort = prop('sort', dataSorting);
      const newOrder = prop('order', dataSorting);
      onSetUrlParam({ sort, order: newOrder });
    },
  }),
  withIsLoadingMore,
  withHandlers({
    onScroll: onScrollHandler,
    setSearchProjectsName,
    onSearch: onSearchHandler,
    onToggleFavoriteStatus: onToggleFavoriteStatusHandler,
  }),
  withContext({
    filterValue: PropTypes.any,
    setIsNeedRefreshProjects: PropTypes.func,
    onToggleFavoriteStatus: PropTypes.func,
  }, props => ({
    filterValue: props.filterValue,
    setIsNeedRefreshProjects: props.setIsNeedRefreshProjects,
    onToggleFavoriteStatus: props.onToggleFavoriteStatus,
  })),
  withLocationSearchChanged({
    onChange: props => getProjects(props),
  }),
  lifecycle({
    componentDidMount() {
      getProjects(this.props);
      this.props.getClientsRequest();
    },
    componentDidUpdate(prevProps) {
      const {
        viewStyle, isNeedRefreshProjects, setIsNeedRefreshProjects, searchName, onSetUrlParam, path,
      } = this.props;
      if (prevProps.searchName !== searchName) {
        onSetUrlParam({
          ...DEFAULT_PARAMS, title: searchName,
        });
      }
      if (isEmpty(searchName) && prevProps.searchName !== searchName) {
        const prevUrlParams = QueryString.parse(path, { ignoreQueryPrefix: true });
        onSetUrlParam({ ...prevUrlParams, title: '' });
      }
      if (prevProps.viewStyle !== viewStyle) {
        getProjects(this.props);
      }
      if (and(
        isNeedRefreshProjects,
        not(equals(prevProps.isNeedRefreshProjects, isNeedRefreshProjects)),
      )) {
        getProjects(this.props);

        setIsNeedRefreshProjects(false);
      }
    },
  }),
);

export default enhance(AllProjects);
