import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { path } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';

import { googleIcon } from '../../../constants/assets';
import { Layouts, ButtonsCollection, FormsCollection } from '../../../ui-kit';
import { ErrorNotification } from '../errorNotification';
import { requestHelpers } from '../../../utils/helpers';
import { getApiUrl } from '../../../utils/helpers/requestHelpers';
import { uiActions, uiSelectors } from '../../../state/ui';
import { userActions } from '../../../state/user';
import { rules } from './rules';
import { WINDOW_TITLE } from '../../../constants/window-titles';
import './style.sass';
import api from '../../../api/auth.service';
import subscriptionApi from '../../../api/subscription.service';
import { ROUTE_TYPES } from '../../../constants/ui';
import { APP_ROUTES } from '../../../constants/appRoutes';
import { setCustomer } from '../../../state/subscription/actions';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const SignIn = () => {
  const { t } = useTranslation('validation');
  const dispatch = useDispatch();
  const authNotification = useSelector(uiSelectors.getAuthNotification);
  const {
    control, setValue, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const successLoginFlow = async (data) => {
    if (data && data.token) {
      localStorage.setItem('authToken', data.token.token);
      const customerData = await subscriptionApi.getCustomer();
      dispatch(setCustomer(customerData));
      dispatch(push(APP_ROUTES.PROJECTS));
      dispatch(userActions.setAuthenticated(true));
      dispatch(uiActions.setRoutesType(ROUTE_TYPES.AUTHENTICATED));
    } else {
      dispatch(uiActions.displayNotification({ data }));
    }
  };

  const loginRequest = async (params) => {
    const data = await api.loginRequest(params);
    await successLoginFlow(data);
  };

  const googleLoginRequest = async (params) => {
    const data = await api.loginGoogleRequest(params);
    await successLoginFlow(data);
  };

  useEffect(() => {
    const code = requestHelpers.getQueryVariable('code');
    if (code) {
      googleLoginRequest({ code });
    }
    dispatch(uiActions.setAppTitle(WINDOW_TITLE.SIGN_IN));
    dispatch(uiActions.displayNotification(null));
    return () => {
      dispatch(uiActions.displayNotification(null));
    };
  }, []);

  const onChange = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = useCallback(values => loginRequest(values), []);

  const onError = (errors) => {
    dispatch(uiActions.displayNotification(null));
    if (errors) {
      const performedErrors = Object.values(errors).reverse()[0];
      dispatch(uiActions.displayNotification({
        [performedErrors.message.field]: {
          message: performedErrors.message.message,
          params: performedErrors.message.params,
        },
      }));
    }
  };

  return (
    <Layouts.Auth>
      <ErrorNotification />
      <div className="auth-wrapper__wrap">
        <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit, onError)}>
          <div className="auth-wrapper__form-contain">
            <h2 className="title weight--bold title--md text-align--center">
              {t('Sign in to Avanga')}
            </h2>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({
                onBlur, value, touched, field,
              }) => (
                <FormsCollection.Input
                  onChange={e => onChange('username', e.target.value)}
                  onBlur={onBlur}
                  value={value}
                  touched={touched}
                  error={!!path(['username'], authNotification)}
                  type="email"
                  name="username"
                  id="sign-in__username"
                  placeholderJump={t('Email address')}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({
                onBlur, value, touched, field,
              }) => (
                <FormsCollection.Input
                  onChange={e => onChange('password', e.target.value)}
                  onBlur={onBlur}
                  value={value}
                  touched={touched}
                  error={!!path(['password'], authNotification)}
                  type="password"
                  name="password"
                  id="sign-in__password"
                  placeholderJump={t('Password')}
                  {...field}
                />
              )}
            />
            <div className="button-group">
              <ButtonsCollection.ButtonBrill
                className="auth-wrapper__button--with-border button--fill"
                borderColor="#26b5ed"
                type="submit"
                borderWidth="2"
              >
                {t('Sign In')}
              </ButtonsCollection.ButtonBrill>
            </div>
            <div className="auth-wrapper__nav-group">
              <nav className="auth-wrapper__nav">
                <ul className="auth-wrapper__list">
                  <li className="auth-wrapper__item">
                    <Link to={APP_ROUTES.FORGOT_PASSWORD} className="auth-wrapper__link">
                      {t('Forgot password')}
                      ?
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="auth-wrapper__or">
              <span>
                {t('or').toUpperCase()}
              </span>
            </div>
            <div className="button-group">
              <ButtonsCollection.ButtonBrill
                type="button"
                onClick={() => window.location.assign(`${getApiUrl(window.location)}/google`)}
                className="auth-wrapper__button--with-shadow button--fill button-brill--shadow"
                backgroundColor="#fff"
              >
                <img src={googleIcon} alt="" />
                {t('Sign in with Google')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </Layouts.Auth>
  );
};
