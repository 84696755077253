import { getSecondsFromTimeString, getTimeStringBySeconds } from '../../../../../../../utils/helpers/dateHelpers';

export const FIELD_TYPES = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  ESTIMATE: 'ESTIMATE',
  RADIO: 'RADIO',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  MULTI_AUTOCOMPLETE: 'MULTI_AUTOCOMPLETE',
  DATE_PICKER: 'DATE_PICKER',
  TIME_PICKER: 'TIME_PICKER',
  SELECT: 'SELECT',
};

export const FIELD_TYPES_KEYS = {
  [FIELD_TYPES.TEXT]: 1,
  [FIELD_TYPES.SELECT]: 2,
  [FIELD_TYPES.TEXTAREA]: 6,
  [FIELD_TYPES.RADIO]: 5,
  [FIELD_TYPES.AUTOCOMPLETE]: 7,
  [FIELD_TYPES.MULTI_AUTOCOMPLETE]: 8,
  [FIELD_TYPES.DATE_PICKER]: 9,
  [FIELD_TYPES.TIME_PICKER]: 10,
  [FIELD_TYPES.ESTIMATE]: 11,
};


export const NORMALIZER_FIELDS = {
  [FIELD_TYPES_KEYS.ESTIMATE]: {
    TO_API: getSecondsFromTimeString,
    TO_SPA: getTimeStringBySeconds,
  },
};
