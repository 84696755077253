import { handleActions } from 'redux-actions';

import {
  filter, omit, reduce, uniq, map, prop, sort,
} from 'ramda';
import { notEqual } from 'ramda-extension';
import types from './types';
import {
  mergeByProp, mergeDeepByProp, mergeIn,
} from '../../../../../utils/helpers/ramdaStateHelpers';

const initialStore = {
  nodes: {
    entities: {},
    result: [],
  },
  categories: {
    entities: {
      1: {
        id: 1,
        name: 'System',
      },
      2: {
        id: 2,
        name: 'Project',
      },
      3: {
        id: 3,
        name: 'Roles',
      },
      4: {
        id: 4,
        name: 'User',
      },
      5: {
        id: 5,
        name: 'Messenger',
      },
    },
    result: [1, 2, 3, 4, 5],
  },
  businessProcess: {
    entities: {
      1: {
        id: 1,
        title: 'User was invited',
        category: 4,
      },
      2: {
        id: 2,
        title: 'User was deleted',
        category: 4,
      },
      3: {
        id: 3,
        title: 'Member was added ( Project )',
        category: 2,
      },
      4: {
        id: 4,
        title: 'Member was deleted ( Project )',
        category: 2,
      },
    },
    result: [1, 2, 3, 4],
  },
  events: {
    entities: {},
    result: [],
  },
};

const normalizeNode = (nodes) => {
  const nodesSorted = sort((a, b) => a.parent_id - b.parent_id, nodes);
  return {
    entities: reduce((accum, item) => ({ ...accum, [item.id]: item }), {}, nodesSorted),
    result: map(prop('id'), nodesSorted),
  };
};

const reducer = handleActions({
  [types.SET_BUSINESS_PROCESSES]: mergeByProp('businessProcess',
    ({
      payload: {
        data: {
          entities: { businessProcess },
          result,
        },
      },
    }) => ({ entities: businessProcess, result })),
  [types.SET_BUSINESS_PROCESS]: mergeIn(
    ({ payload: { bp } }) => ({
      businessProcess: {
        entities: { [bp.id]: bp },
        result: [bp.id],
      },
      ...(bp.nodes ? { nodes: normalizeNode(bp.nodes) } : {}),
    }),
  ),
  [types.DELETE_BUSINESS_PROCESS]: mergeByProp('businessProcess',
    ({ payload: processId }, { businessProcess: { entities, result } }) => ({
      entities: omit([processId], entities),
      result: filter(notEqual(processId), result),
    })),
  [types.SET_BUSINESS_PROCESS_NODE]: mergeDeepByProp('nodes',
    ({ payload: { node } }, { nodes: { result } }) => ({
      entities: { [node.id]: node },
      result: uniq(reduce((accum, nodeId) => (node.parent_id === nodeId
        ? [...accum, nodeId, node.id]
        : [...accum, nodeId]),
      [],
      [...result, node.id])),
    })),
  [types.DELETE_BUSINESS_PROCESS_NODE]: mergeByProp('nodes',
    ({ payload: nodeId }, { nodes: { entities, result } }) => ({
      entities: omit([nodeId], entities),
      result: filter(notEqual(nodeId), result),
    })),
  [types.SET_BUSINESS_PROCESS_EVENTS]: mergeByProp('events',
    ({
      payload: {
        data: {
          entities: { events },
          result,
        },
      },
    }) => ({ entities: events, result })),
}, initialStore);

export default reducer;
