export default {
  // Business processes
  GET_BUSINESS_PROCESSES_REQUEST: 'GET_BUSINESS_PROCESSES_REQUEST',
  GET_BUSINESS_PROCESS_REQUEST: 'GET_BUSINESS_PROCESS_REQUEST',
  ADD_BUSINESS_PROCESS_REQUEST: 'ADD_BUSINESS_PROCESS_REQUEST',
  EDIT_BUSINESS_PROCESS_REQUEST: 'EDIT_BUSINESS_PROCESS_REQUEST',
  DELETE_BUSINESS_PROCESS_REQUEST: 'DELETE_BUSINESS_PROCESS_REQUEST',

  SET_BUSINESS_PROCESSES: 'SET_BUSINESS_PROCESSES',
  SET_BUSINESS_PROCESS: 'SET_BUSINESS_PROCESS',
  DELETE_BUSINESS_PROCESS: 'DELETE_BUSINESS_PROCESS',

  // Nodes of Business processes
  ADD_BUSINESS_PROCESS_NODE_REQUEST: 'ADD_BUSINESS_PROCESS_NODE_REQUEST',
  EDIT_BUSINESS_PROCESS_NODE_REQUEST: 'EDIT_BUSINESS_PROCESS_NODE_REQUEST',
  DELETE_BUSINESS_PROCESS_NODE_REQUEST: 'DELETE_BUSINESS_PROCESS_NODE_REQUEST',

  SET_BUSINESS_PROCESS_NODE: 'SET_BUSINESS_PROCESS_NODE',
  DELETE_BUSINESS_PROCESS_NODE: 'DELETE_BUSINESS_PROCESS_NODE',
  SET_BUSINESS_PROCESS_NODES: 'SET_BUSINESS_PROCESS_NODES',

  // Events
  GET_BUSINESS_PROCESS_EVENTS_REQUEST: 'GET_BUSINESS_PROCESS_EVENTS_REQUEST',
  SET_BUSINESS_PROCESS_EVENTS: 'SET_BUSINESS_PROCESS_EVENTS',
};
