import React from 'react';
import PropTypes from 'prop-types';
import {
  prop, identity, pathOr, path,
} from 'ramda';
import { AsyncAutoComplete, FormsCollection } from '../../../../../../../ui-kit';
import './style.sass';
import { Condition, RadioButtons } from './components';
import { FIELD_TYPES, FIELD_TYPES_KEYS } from './constants';

const FieldByOption = ({
  config, values, handleChange, onOpenModalAddVariable, handleBlur, setFieldRef, fieldGroup,
  isUseVariable, getAutoComplete, setFieldValue, value, name, error, id, defaultValue,
  handleKeyDown, personalOptions, handleTouchOrMouseDown, fieldsConfig, resetForm,
}) => (
  <div className={`fieldByOption ${prop('className', config)}
   ${path(['input_options', 'only_variables'], config) ? 'fieldByOption--only-variables' : ''}`}
  >
    <Condition
      cond={config.input_options.cond}
      values={values[fieldGroup]}
      fieldGroup={fieldGroup}
      fieldsConfig={fieldsConfig}
      resetForm={resetForm}
    >
     <>
       {
         {
           [FIELD_TYPES_KEYS[FIELD_TYPES.TEXT]]: (
             // eslint-disable-next-line jsx-a11y/no-static-element-interactions
             <div
               className="fieldByOption__group"
               onMouseDown={handleTouchOrMouseDown}
               onTouchStart={handleTouchOrMouseDown}
             >
               <label
                 htmlFor={id}
                 className="fieldByOption__group-label"
               >{prop('label', config)}
               </label>
               <FormsCollection.TextArea
                 asInput
                 id={id}
                 name={name}
                 type="text"
                 value={value}
                 error={error}
                 onKeyDown={handleKeyDown}
                 onChange={handleChange}
                 childrenRef={setFieldRef}
               />
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.TEXTAREA]]: (
             // eslint-disable-next-line jsx-a11y/no-static-element-interactions
             <div
               className="fieldByOption__group"
               onMouseDown={handleTouchOrMouseDown}
               onTouchStart={handleTouchOrMouseDown}
             >
               <label htmlFor={id} className="fieldByOption__group-label">{prop('label', config)}</label>
               <FormsCollection.TextArea
                 name={name}
                 type="text"
                 id={id}
                 value={value}
                 error={error}
                 onChange={handleChange}
                 childrenRef={setFieldRef}
               />
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.ESTIMATE]]: (
             <div className="fieldByOption__group">
               <label htmlFor={id} className="fieldByOption__group-label">{prop('label', config)}</label>
               <FormsCollection.Input
                 name={name}
                 id="create-task__estimated-time"
                 onChange={handleChange}
                 placeholder="1w 20m 21h 30m"
                 onBlur={handleBlur}
                 value={value}
                 touched
               />
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.RADIO]]: (
             <div className="fieldByOption__group">
               <label htmlFor={id} className="fieldByOption__group-label">{prop('label', config)}</label>
               <RadioButtons
                 defaultValue={defaultValue}
                 config={config.input_options}
                 name={name}
                 values={values[fieldGroup]}
                 id={id}
                 selectedValue={value}
                 handleBlur={handleBlur}
                 handleChange={handleChange}
               />
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.AUTOCOMPLETE]]: (
             <div className={
               `fieldByOption__group
              ${personalOptions ? 'fieldByOption__group--personal-select' : ''}`
             }
             >
               <label htmlFor={prop('name', config)} className="fieldByOption__group-label">{prop('label', config)}</label>
               <div className="fieldByOption__row">
                 <AsyncAutoComplete
                   id={id}
                   defaultValue={defaultValue}
                   config={config.input_options}
                   name={name}
                   value={value}
                   loadOptions={getAutoComplete}
                   onChange={val => setFieldValue(
                     fieldGroup,
                     { ...values[fieldGroup], [prop('name', config)]: val },
                   )}
                 />
                 {
                   personalOptions && (
                     <FormsCollection.Select
                       name={`personal-${name}`}
                       value={values[fieldGroup][`personal-${name}`]}
                       className="fieldByOption__personal-select"
                       data={personalOptions}
                       onChange={handleChange}
                     />
                   )
                 }
               </div>
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.MULTI_AUTOCOMPLETE]]: (
             <div className={
               `fieldByOption__group
              ${personalOptions ? 'fieldByOption__group--personal-select' : ''}`
             }
             >
               <label htmlFor={prop('name', config)} className="fieldByOption__group-label">{prop('label', config)}</label>
               <div className="fieldByOption__row">
                 <AsyncAutoComplete
                   isMulti
                   closeMenuOnSelect={false}
                   id={id}
                   defaultValue={defaultValue}
                   config={config.input_options}
                   name={name}
                   value={value}
                   loadOptions={getAutoComplete}
                   onChange={val => setFieldValue(
                     fieldGroup,
                     { ...values[fieldGroup], [prop('name', config)]: val },
                   )}
                 />
                 {
                   personalOptions && (
                     <FormsCollection.Select
                       name={`personal-${name}`}
                       value={values[fieldGroup][`personal-${name}`]}
                       className="fieldByOption__personal-select"
                       data={personalOptions}
                       onChange={handleChange}
                     />
                   )
                 }
               </div>
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.DATE_PICKER]]: (
             <div className="fieldByOption__group">
               <label htmlFor={prop('name', config)} className="fieldByOption__group-label">{prop('label', config)}</label>
               <FormsCollection.DatePicker
                 id={id}
                 name={name}
                 value={value}
                 onChange={handleChange}
               />
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.TIME_PICKER]]: (
             <div className="fieldByOption__group">
               <label htmlFor={prop('name', config)} className="fieldByOption__group-label">{prop('label', config)}</label>
               <FormsCollection.TimePicker
                 id={id}
                 name={name}
                 value={value}
                 onChange={handleChange}
               />
             </div>
           ),
           [FIELD_TYPES_KEYS[FIELD_TYPES.SELECT]]: (
             <div className="fieldByOption__group">
               <label htmlFor={prop('name', config)} className="fieldByOption__group-label">{prop('label', config)}</label>
               <FormsCollection.Select
                 id={id}
                 name={name}
                 value={value}
                 data={path(['input_options', 'options'], config)}
                 onChange={handleChange}
               />
             </div>
           ),
         }[config.input_type]
       }
       <small className="fieldByOption__error">{error}</small>
       {
         isUseVariable && !pathOr(0, ['input_options', 'isNotAllowVariables'], config) && (
           <button
             type="button"
             onMouseDown={e => e.preventDefault()}
             onClick={onOpenModalAddVariable}
             className="fieldByOption__add-variable"
           >
             <span className="icon-add-btn" />
             Add variable
           </button>
         )
       }
       </>
    </Condition>
  </div>
);

FieldByOption.propTypes = {
  isUseVariable: PropTypes.bool,
  getAutoComplete: PropTypes.func,
  config: PropTypes.instanceOf(Object).isRequired,
  fieldsConfig: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)],
  ).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)],
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  onOpenModalAddVariable: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  setFieldRef: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  personalOptions: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  fieldGroup: PropTypes.oneOf(['fields', 'options']).isRequired,
  handleTouchOrMouseDown: PropTypes.func.isRequired,
};

FieldByOption.defaultProps = {
  personalOptions: null,
  getAutoComplete: identity,
  isUseVariable: false,
  handleBlur: identity,
};
export default FieldByOption;
