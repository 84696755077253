import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonsCollection, Layouts, DropDown, PermissionElement,
} from '../../../../ui-kit';
import {
  Header,
  ContactsContainer,
} from '../../../../containers';
import { LeftSideBar } from '../../../../newContainers';
import {
  RightSideBarContainer,
} from './components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import CRMModules from '../CRMModules/index';
import './style.sass';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import { useSubscriptionPermission } from '../../../../utils/hooks/useSubscriptionPermission';

const Contacts = ({
  t, onCreateContactModal, windowWidth, selectedContactId,
  selectedPinnedContactId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onCreateCustomFieldModal = useCallback(() => setIsOpen(true));
  useSubscriptionPermission(MODULES.CONTACTS);
  return (
    <Layouts.Main rightSideBarStatus={false}>
      <LeftSideBar>
        <CRMModules />
      </LeftSideBar>
      <div className="main-container page-contacts__main-container">
        <Header>
          <h1 className="weight--medium title--xl title-page">
            {t('Contacts')}
          </h1>
        <>
          {
          windowWidth > WINDOW_WIDTH.MEDIUM ? (
            <ButtonsCollection.ButtonBrill
              onClick={onCreateContactModal}
              className="button--sm header__control-button--project"
            >
              Create contact
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonPlus
              onClick={onCreateContactModal}
              className="header__control-button--project"
            />
          )
        }
          <PermissionElement module={MODULES.CUSTOM_FIELDS} action={ACTIONS.CREATE}>
            <DropDown
              className="project-header-control"
              name="project-header"
              label={(
                <ButtonsCollection.ButtonIcons
                  title="Control buttons for contacts"
                  className="header-col__project-burger"
                >
                  <span className="icon-burger-dots-gorizont" />
                </ButtonsCollection.ButtonIcons>
              )}
              list={
                [
                  {
                    id: 0,
                    label: (
                      <>
                        <span className="project-header-control__icon icon-category-set" />
                        <span className="project-header-control__text">Custom fields</span>
                      </>
                    ),
                    onClick: () => onCreateCustomFieldModal(),
                  },
                ]
              }
            />
          </PermissionElement>
        </>
        </Header>
        <div className="main-container__content page-contacts__content">
          <ContactsContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedContactId={selectedContactId}
            selectedPinnedContactId={selectedPinnedContactId}
          />
          <RightSideBarContainer windowWidth={windowWidth} />
        </div>
      </div>
    </Layouts.Main>
  );
};
Contacts.propTypes = {
  onCreateContactModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  selectedContactId: PropTypes.number.isRequired,
  selectedPinnedContactId: PropTypes.number.isRequired,
};
export default Contacts;
