import {
  compose, lifecycle, withProps, withStateHandlers, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { push as connectRouterPush } from 'connected-react-router';
import {
  map, objOf, prop, keys, values as RValues, reduce, find, propEq, is, equals, path, last,
} from 'ramda';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { notEqual } from 'ramda-extension';

import { memo } from 'react';
import { bpActions, bpSelectors } from '../store/bpModule';
import { RIGHT_SIDEBAR_VIEWS } from '../constants/common';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';
import { insertNodeAfterElement } from '../../../../utils/helpers/uiComponentHelpers/caretHelpers';
import { getVariable, getVariableLabel } from '../helpers/variables';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { getListOfNodesWhatUsingVariables, renameEventsFields } from '../helpers/common';
import { uiActions } from '../../../../state/ui';
import { NODES_ACTIONS_CONST, NODES_REDUX_ACTIONS } from '../constants/crud';
import { getSecondsFromTimeString, getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';

const mapStateToProps = (store, {
  processId, shouldBeTrigger,
  crudNodesConfig: { selectedNode }, config: { action },
}) => ({
  process: bpSelectors.getProcessById(store)(processId),
  actions: bpSelectors.getActionsEntities(store),
  nodes: bpSelectors.getProcessNodeEntities(store),
  variablesToEvent: bpSelectors.getOutputVariablesToEvent(store),
  node: bpSelectors.getProcessNodeById(store)(selectedNode),
  events: bpSelectors.getEventsEntities(store),
  getFieldsOfEvent: bpSelectors.getFieldsOfEvent(store),
  entitiesOfActions: shouldBeTrigger
    ? bpSelectors.getTriggersEntities(store)
    : bpSelectors.getActionsEntities(store),
  isPending: action === NODES_ACTIONS_CONST.CREATE
    ? bpSelectors.getPendingAddBusinessProcessNode(store)
    : bpSelectors.getPendingEditBusinessProcessNode(store),
  getOptionsOfEvent: bpSelectors.getOptionsOfEvent(store),
});


const mapDispatchToProps = (dispatch, { config: { action } }) => ({
  push: compose(dispatch, connectRouterPush),
  setBusinessProcessNode: compose(dispatch, bpActions.setBusinessProcessNode),
  actionRequest: compose(dispatch, NODES_REDUX_ACTIONS[action]),
  setOpenModal: compose(dispatch, uiActions.openModal),
});

const onChangeActionStateHandler = () => objOf('selectedAction');

const normalizeVariables = (text) => {
  if (!is(String, text)) return text;

  const div = document.createElement('div');
  div.innerHTML = text;
  const variablesElements = div.querySelectorAll('variable');
  variablesElements.forEach((variable) => {
    insertNodeAfterElement(variable,
      document.createTextNode(variable.getAttribute('data-value')));
    variable.remove();
  });
  return div.innerText;
};

const onSubmit = (formValues, {
  props: {
    selectedAction: { value },
    actionRequest, match: { params: { processId } },
    setCrudNodesConfig,
    crudNodesConfig,
    node, config: { action }, nodes,
  }, resetForm,
}) => {
  const { fields, options } = reduce((accum, groupName) => ({
    ...accum,
    [groupName]: reduce((acc, fieldName) => ({
      ...acc,
      [fieldName]: normalizeVariables(formValues[groupName][fieldName]),
    }),
    {}, keys(formValues[groupName])),
  }),
  {}, keys(formValues));
  const { parentId } = crudNodesConfig;

  if (fields && fields.estimated_time) {
    fields.estimated_time = getSecondsFromTimeString(fields.estimated_time);
  }

  actionRequest({
    processId,
    event_id: value,
    ...(action === NODES_ACTIONS_CONST.CREATE
      ? ({ parent_id: parentId || prop('id', last(RValues(nodes))) }) : ({})),
    ...(node ? ({ nodeId: node.id }) : ({})),
    values: JSON.stringify(fields),
    options: JSON.stringify(options),
  }, {
    callbacks: {
      success: () => {
        resetForm({ fields: {}, options: {} });
        setTimeout(() => {
          setCrudNodesConfig({ view: RIGHT_SIDEBAR_VIEWS.NOTHING });
        }, 600);
      },
    },
  });
};

const uglifyVariablesHandler = ({ nodes, events, variablesToEvent }) => (text) => {
  if (!is(String, text)) return text;
  return text.replace(/{\d*\|\d*}/g, (varData) => {
    const [nodeId, variableId] = varData.replace(/({|})/g, '').split('|');
    const node = nodes[nodeId];
    const event = events[node.event_id];
    const variable = find(propEq('id', Number(variableId)), variablesToEvent(node.id)[node.id]);

    const variableElement = getVariable({
      label: getVariableLabel(node, event, variable),
      nodeId,
      variableId,
    });

    return variableElement.outerHTML;
  });
};

const onDeleteVariableHandler = ({ values, setFieldValue }) => ({ target }) => {
  if (target.className === 'bp-variable-delete'
    || target.parentNode.className === 'bp-variable-delete') {
    const deleteButtonElement = target.className === 'bp-variable-delete'
      ? target : target.parentNode;
    const labelElement = deleteButtonElement.parentNode.parentNode.parentNode.parentNode;
    const field = labelElement.parentNode;
    requestAnimationFrame(() => {
      labelElement.remove();
      const [groupName, fieldName] = field.getAttribute('id').split(',');

      setFieldValue(groupName, {
        ...values[groupName],
        [fieldName]: field.innerHTML,
      });
    });
  }
};

const onSetActionDataHandler = ({
  crudNodesConfig, getSelectedActionLabel, onChangeAction, resetForm,
  node: { params: nodeValues = '{}' }, uglifyVariables,
  setCrudNodesConfig, node,
}) => () => {
  setCrudNodesConfig({
    crudNodesConfig,
    parentId: node.parent_id,
  });
  const parsedValues = JSON.parse(nodeValues);
  const accumValues = reduce((accum, groupName) => ({
    ...accum,
    [groupName]: reduce((acc, fieldName) => ({
      ...acc,
      [fieldName]: uglifyVariables(parsedValues[groupName][fieldName]),
    }),
    {}, keys(parsedValues[groupName])),
  }),
  {}, keys(parsedValues));

  resetForm(accumValues);
  onChangeAction(getSelectedActionLabel(crudNodesConfig.selectedNode));
};

const parserValues = ({ options, params, uglifyVariables }) => {
  const parsedValues = {
    fields: compose((nodeParams => (
      nodeParams && nodeParams.estimated_time
        ? ({ ...nodeParams, estimated_time: getTimeStringBySeconds(nodeParams.estimated_time) })
        : nodeParams
    )))(JSON.parse(params)),
    options: JSON.parse(options),
  };
  return reduce((accum, groupName) => ({
    ...accum,
    [groupName]: reduce((acc, fieldName) => ({
      ...acc,
      [fieldName]: uglifyVariables(parsedValues[groupName][fieldName]),
    }),
    {}, keys(parsedValues[groupName])),
  }),
  { fields: {}, options: {} }, keys(parsedValues));
};

const withActionCRUD = ({ action }) => compose(
  withProps(() => ({
    config: { action },
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ events, nodes }) => {
    const getEvent = node => events[path([node, 'event_id'], nodes)];
    return {
      getSelectedActionLabel: selectedNode => (getEvent(selectedNode)
        ? renameKeys({ title: 'label', id: 'value' }, getEvent(selectedNode))
        : null),
    };
  }),
  withStateHandlers(({
    getSelectedActionLabel,
    crudNodesConfig: { selectedNode },
  }) => ({ selectedAction: getSelectedActionLabel(selectedNode) }), {
    onChangeAction: onChangeActionStateHandler,
  }),
  withProps(({
    entitiesOfActions, selectedAction, getFieldsOfEvent, getOptionsOfEvent,
  }) => ({
    optionsOfActions: compose(
      map(renameEventsFields),
      RValues,
    )(entitiesOfActions),
    fields: getFieldsOfEvent(prop('value', selectedAction)),
    options: getOptionsOfEvent(prop('value', selectedAction)),
  })),
  withHandlers({
    uglifyVariables: uglifyVariablesHandler,
  }),
  withFormik({
    mapPropsToValues: ({
      node,
      uglifyVariables,
      fields, options,
    }) => {
      if (node) {
        return parserValues({ options: node.options, params: node.params, uglifyVariables });
      }
      return {
        fields: reduce(({ name, defaultValue }) => ({ [name]: defaultValue }), {}, fields),
        options: reduce(({ name, defaultValue }) => ({ [name]: defaultValue }), {}, options),
      };
    },
    validateOnChange: true,
    validateOnBlur: true,
    handleSubmit: onSubmit,
  }),
  memo,
  withHandlers({
    onDeleteVariable: onDeleteVariableHandler,
    onSetActionData: onSetActionDataHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        crudNodesConfig, getSelectedActionLabel, onChangeAction, resetForm,
        node, uglifyVariables, setCrudNodesConfig,
        config,
      } = this.props;
      document.querySelector('body').addEventListener('click',
        this.props.onDeleteVariable);
      if (propEq('action', NODES_ACTIONS_CONST.UPDATE, config)) {
        const { params, options, parent_id: parentId } = node;
        const accumValues = parserValues({ options, params, uglifyVariables });

        setCrudNodesConfig({
          ...crudNodesConfig,
          parentId,
        });
        resetForm(accumValues);
        onChangeAction(getSelectedActionLabel(crudNodesConfig.selectedNode));
      }
    },
    componentWillUnmount() {
      document.querySelector('body').removeEventListener('click',
        this.props.onDeleteVariable);
    },
    shouldComponentUpdate(prevProps) {
      const {
        selectedAction, nodes, node, events, setOpenModal, onChangeAction,
        crudNodesConfig, getSelectedActionLabel, config,
      } = this.props;
      if (propEq('action', NODES_ACTIONS_CONST.UPDATE, config)) {
        if (notEqual(prevProps.selectedAction, selectedAction)
          && equals(prevProps.crudNodesConfig.parentId, crudNodesConfig.parentId)) {
          const nodesWhatUsing = getListOfNodesWhatUsingVariables(node.id, events, nodes);

          if (nodesWhatUsing.length) {
            onChangeAction(getSelectedActionLabel(prevProps.crudNodesConfig.selectedNode));
            setOpenModal('blockedNodeModal');
            return false;
          }
        }
      }
      return true;
    },
    componentDidUpdate(prevProps) {
      const {
        crudNodesConfig, getSelectedActionLabel, onChangeAction, resetForm, node,
        uglifyVariables, setCrudNodesConfig, config,
      } = this.props;
      if (propEq('action', NODES_ACTIONS_CONST.UPDATE, config)) {
        const {
          options, params, parent_id: parentId,
        } = node;
        if (notEqual(prevProps.crudNodesConfig, crudNodesConfig)) {
          const accumValues = parserValues({ options, params, uglifyVariables });

          setCrudNodesConfig({
            ...crudNodesConfig,
            parentId,
          });
          resetForm(accumValues);
          onChangeAction(getSelectedActionLabel(crudNodesConfig.selectedNode));
        }
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    alignContainerCenter: false,
    delay: 800,
    className: 'preloaderEditBusinessProcessNode',
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default withActionCRUD;
