import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { FormsCollection } from '../../../../../../../../../ui-kit';

const RadioButtons = ({
  config, handleChange, handleBlur, name, defaultValue, selectedValue,
}) => (
  <div className="radio-buttons">
    { config.options.map(({ label, value }) => (
      <FormsCollection.Radio
        key={`${name}${value}`}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        checked={(selectedValue || defaultValue) === value}
        id={`${name}${value}`}
      />
    )) }
  </div>
);

RadioButtons.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
  selectedValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Object)],
  ).isRequired,
};

export default RadioButtons;
