import {
  arrayOf, bool, instanceOf, number, oneOf, shape, string,
} from 'prop-types';

const EntityId = number;

const EventField = shape({
  id: EntityId,
  name: string,
  defaultValue: oneOf([string, number, bool]),
  options: instanceOf(Object),
});

const EventInstance = {
  id: EntityId,
  key: string,
  title: string,
  input: arrayOf(EventField),
  output: arrayOf(EventField),
  is_input: oneOf([1, 0]),
  is_output: oneOf([1, 0]),
};

const Event = shape({
  ...EventInstance,
});

const ProcessNode = shape({
  id: EntityId,
  title: string,
  values: shape({ [string]: EventField }),
  actionId: EntityId,
});

const BusinessProcess = shape({
  id: EntityId,
  title: string,
  description: string,
  category_id: EntityId,
});

const CategoryOfBusinessProcess = shape({
  id: EntityId,
  title: string,
});

const BusinessProcessTypes = {
  CategoryOfBusinessProcess,
  BusinessProcess,
  ProcessNode,
  Event,
  EventField,
  EntityId,
};

export default BusinessProcessTypes;
