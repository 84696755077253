import React from 'react';
import PropTypes from 'prop-types';

import { DragDropContext } from 'react-beautiful-dnd';
import { Backlog, Sprint } from '..';

const DragDropArea = ({ onDragEnd, sprints }) => (
  <div className="table-tasks">
    <DragDropContext onDragEnd={onDragEnd}>
      {sprints.map(item => (<Sprint key={`sprint-key-${item}`} id={item} />))}
      <Backlog />
    </DragDropContext>
  </div>
);

DragDropArea.propTypes = {
  sprints: PropTypes.arrayOf(PropTypes.number),
  onDragEnd: PropTypes.func.isRequired,
};

DragDropArea.defaultProps = {
  sprints: [],
};


export default DragDropArea;
