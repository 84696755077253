import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { map, reduce } from 'ramda';
import storage from 'redux-persist/lib/storage';

import bootData from './user';
import users from './users';
import messenger from './messenger';
import projects from './projects';
import project from './project';
import task from './task';
import sprints from './sprints';
import tasks from './tasks';
import newTask from './newTask';
import statistics from './statistics';
import settingUsers from './settingUsers';
import permissions from './permissions';
import customFields from './customFields';
import contacts from './contacts';
import pipelines from './pipelines';
import leads from './leads';
import clients from './clients';
import invoices from './invoices';
import subscription from './subscription';

import ui from './ui';
import { state } from '../utils/middlewares/sagaRequestApi';
import { state as socketConnection } from '../utils/helpers/webSocket';
import modulesConfig from '../modules/modules-info';

const makeListOfModulesNames = (modules) => {
  const list = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(modules)) {
    if (value.redux) {
      list.push(key);
    }
  }
  return list;
};

const listOfModulesNames = makeListOfModulesNames(modulesConfig.modules);


// eslint-disable-next-line global-require,import/no-dynamic-require
const storeFromModulesGrouped = map(moduleName => require(`../modules/${moduleName}/src/store/index.js`).default || {}, listOfModulesNames);
const storeFromModulesList = reduce((accum, value) => ({ ...accum, ...value }),
  {}, storeFromModulesGrouped);


const uiPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['pagination', 'routeTypes', 'leftSidebar', 'rightSidebar', 'tabs', 'fieldValues', 'urlFilters'],
};

const messengerPersistConfig = {
  key: 'messenger',
  storage,
};

const projectsPersistConfig = {
  key: 'projects',
  storage,
  whitelist: ['viewStyle'],
};

const leadsPersistConfig = {
  key: 'leads',
  storage,
  whitelist: ['checkedPipeline', 'leadsView'],
};

const invoicesPersistConfig = {
  key: 'invoices',
  storage,
  whitelist: ['invoiceListView'],
};

const rootReducer = history => combineReducers({
  ...(storeFromModulesList || {}),
  customFields,
  settingUsers,
  users,
  newTask,
  projects: persistReducer(projectsPersistConfig, projects),
  messenger: persistReducer(messengerPersistConfig, messenger),
  task,
  project,
  tasks,
  bootData,
  statistics,
  sprints,
  permissions,
  clients,
  contacts,
  pipelines,
  subscription,
  leads: persistReducer(leadsPersistConfig, leads),
  invoices: persistReducer(invoicesPersistConfig, invoices),
  ui: persistReducer(uiPersistConfig, ui),
  router: connectRouter(history),
  ...state,
  socketConnection,
});

export default rootReducer;
