import { createActions } from 'redux-actions';
import {
  identity, pathOr, compose, mergeLeft,
} from 'ramda';
import { callNotification } from '../../utils/helpers/notifies';
import types from './types';

import dataSchema, {
  sprintTasksSchema,
  categoriesTaskSchema,
  backlogSchema,
  tasksSchema,
  agendaBoardSchema,
  sprintsListSchema,
  usersWithTasksSchema,
  tasksListSchema,
  notesListSchema, pinnedTasksListSchema, statusesListSchema,
} from './schema';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { setErrorPage } from '../ui/actions';
import { getNormalizeErrorObject, getErrorMessage } from '../../utils/helpers/requestHelpers';

const errorPageDataWhenGetProject = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);

export const {
  getProjectRequest,
  getProjectTasksRequest,
  addSprintRequest,
  deleteProjectTaskRequest,
  getCategoriesTaskRequest,
  addCategoryTaskRequest,
  deleteCategoryTaskRequest,
  updateCategoryTaskRequest,
  sortProjectTasksRequest,
  getAgendaTasksRequest,
  getAgendaTasksBySprintRequest,
  getSprintsRequest,
  getUsersWithTasksRequest,
  deleteSprintRequest,
  updateSprintRequest,
  getImportantTasksRequest,
  getNotesRequest,
  updateNoteRequest,
  deleteNoteRequest,
  addNoteRequest,
  getProjectMembersRequest,
  addProjectMembersRequest,
  deleteProjectMemberRequest,
  getPinnedTasksRequest,
  getStatusesOfTasksRequest,
  addStatusOfTasksRequest,
  deleteStatusOfTasksRequest,
  updateStatusesPriorityListRequest,
  updateStatusOfTasksRequest,
  editProject,
  setProjectTasks,
  setSprint,
  reorderSprintTasks,
  reorderBacklogTasks,
  setBacklogTask,
  removeSprintTask,
  removeBacklogTask,
  setSprintTask,
  setCategoriesTask,
  sortSprintTasks,
  sortBacklogTasks,
  setBacklogTaskToList,
  setSprintTaskToList,
  setAuthor,
  setAssignee,
  setAgendaTasks,
  onDragAgendaEnd,
  reorderAgendaTasks,
  setSprints,
  deleteProjectTask,
  clearProjectData,
  setUsersWithTasks,
  deleteSprint,
  reorderSprint,
  deleteSprintEntity,
  setCategoryTask,
  deleteCategoryTask,
  deleteCategoryTaskEntity,
  reorderCategoryTask,
  setImportantTasks,
  setNotes,
  deleteNote,
  deleteNoteEntity,
  reorderNotes,
  setNote,
  setProjectMembers,
  deleteProjectMember,
  reorderProjectMembers,
  setSummary,
  setPinnedTasks,
  setPinTask,
  resetPinTask,
  getProjectError,
  setTaskToAgenda,
  deleteTaskOfAgenda,
  setStatusesOfTasks,
  setStatusOfTasks,
  deleteStatusOfTasks,
  updateStatusesPriorityList,
  onDragTasksStatus,
  deleteProjectTaskSuccess,
} = createActions(
  {
    [types.GET_PROJECT_REQUEST]: [
      identity,
      ({ projectId }, meta) => ({
        async: true,
        route: `/projects/${projectId}`,
        selectorName: 'getProject',
        method: 'GET',
        schema: {
          rules: { project: dataSchema },
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(editProject,
            {
              pathEntities: ['data', 'entities', 'members'],
            }),
          error: compose(setErrorPage, errorPageDataWhenGetProject),
        },
        ...meta,
      }),
    ],
    [types.GET_PROJECT_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints`,
        selectorName: 'getProjectTasksRequest',
        method: 'GET',
        params: { ...params, sortBy: 'status', order: 'desc' },
        schema: {
          rules: { sprints: [sprintTasksSchema], backlog: [backlogSchema] },
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setProjectTasks,
            {
              isNotClear: true,
              multiPathEntities: [
                ['data', 'entities', 'author'],
                ['data', 'entities', 'assigneeUser'],
              ],
            }),
        },
        ...meta,
      }),
    ],
    [types.ADD_SPRINT_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints`,
        selectorName: 'addSprintRequest',
        method: 'POST',
        params,
        actions: {
          success: () => getProjectTasksRequest({
            projectId,
            withRelated: 'tasks',
            limit: 100,
            offset: 0,
          }),
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_CATEGORIES_TASK_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}/task-categories`,
        selectorName: 'getCategoriesTaskRequest',
        method: 'GET',
        schema: {
          rules: categoriesTaskSchema,
          pathData: ['data'],
        },
        params,
        actions: {
          success: setCategoriesTask,
        },
        ...meta,
      }),
    ],
    [types.ADD_CATEGORY_TASK_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}/task-categories`,
        selectorName: 'addCategoryTaskRequest',
        method: 'POST',
        params,
        actions: {
          success: () => getCategoriesTaskRequest({ id }),
          error: error => callNotification.error(getErrorMessage(error)),
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_CATEGORY_TASK_REQUEST]: [
      identity,
      ({ projectId, categoryId }, meta) => ({
        async: true,
        route: `/projects/${projectId}/task-categories/${categoryId}`,
        selectorName: 'deleteCategoryTaskRequest',
        method: 'DELETE',
        actions: {
          success: deleteCategoryTask,
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_CATEGORY_TASK_REQUEST]: [
      identity,
      ({ categoryId, projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/task-categories/${categoryId}`,
        selectorName: 'updateCategoryTaskRequest',
        method: 'PUT',
        params,
        actions: {
          success: setCategoryTask,
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.SORT_PROJECT_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks`,
        selectorName: 'sortProjectTasksRequest',
        method: 'GET',
        params,
        schema: {
          rules: [tasksSchema],
          pathData: ['data'],
        },
        actions: {
          success: meta.isSprint ? sortSprintTasks : sortBacklogTasks,
          meta: { sprintId: meta.sprintId },
        },
        ...meta,
      }),
    ],
    [types.SET_BACKLOG_TASK_TO_LIST]: [
      identity,
      (payload, meta) => meta,
    ],
    [types.GET_AGENDA_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/agenda`,
        selectorName: 'getAgendaTasksRequest',
        schema: {
          rules: { statuses: [agendaBoardSchema] },
          pathData: ['data'],
        },
        actions: {
          success: setAgendaTasks,
        },
        method: 'GET',
        params,
        ...meta,
      }),
    ],
    [types.GET_AGENDA_TASKS_BY_SPRINT_REQUEST]: [
      identity,
      ({ projectId, sprintId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/agenda/${sprintId}`,
        selectorName: 'getAgendaTasksBySprintRequest',
        schema: {
          rules: { statuses: [agendaBoardSchema] },
          pathData: ['data'],
        },
        actions: {
          success: setAgendaTasks,
        },
        method: 'GET',
        params,
        ...meta,
      }),
    ],
    [types.GET_SPRINTS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints`,
        selectorName: 'getSprintsRequest',
        method: 'GET',
        params,
        schema: {
          rules: sprintsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: action => setSprints({ ...action, meta }),
        },
        callbacks: {
          success: pathOr(() => {}, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_USERS_WITH_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks/members`,
        selectorName: 'getUsersWithTasksRequest',
        method: 'GET',
        schema: {
          rules: usersWithTasksSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setUsersWithTasks,
            {
              pathEntities: ['data', 'entities', 'usersWithTasks'],
            }),
        },
        params,
        ...meta,
      }),
    ],
    [types.DELETE_SPRINT_REQUEST]: [
      identity,
      ({ projectId, sprintId }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints/${sprintId}`,
        selectorName: 'deleteSprintRequest',
        actions: {
          success: action => deleteSprint({ ...action, meta }),
        },
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.UPDATE_SPRINT_REQUEST]: [
      identity,
      ({ projectId, sprintId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints/${sprintId}`,
        selectorName: 'updateSprintRequest',
        method: 'PUT',
        params,
        actions: {
          success: setSprint,
          meta,
        },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_IMPORTANT_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/tasks`,
        selectorName: 'getImportantTasksRequest',
        method: 'GET',
        schema: {
          rules: tasksListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setImportantTasks,
        },
        params,
        ...meta,
      }),
    ],
    [types.GET_NOTES_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/documents`,
        selectorName: 'getNotesRequest',
        method: 'GET',
        schema: {
          rules: notesListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setNotes,
        },
        params,
        ...meta,
      }),
    ],
    [types.UPDATE_NOTE_REQUEST]: [
      identity,
      ({ projectId, noteId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/documents/${noteId}`,
        selectorName: 'updateNoteRequest',
        method: 'PUT',
        actions: {
          success: setNote,
        },
        success: pathOr(() => {}, ['callbacks', 'success'], meta),
        error: () => callNotification.error('Note cannot be updated'),
        params,
        ...meta,
      }),
    ],
    [types.DELETE_NOTE_REQUEST]: [
      identity,
      ({ projectId, noteId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/documents/${noteId}`,
        selectorName: 'deleteNoteRequest',
        method: 'DELETE',
        actions: {
          success: deleteNote,
        },
        success: pathOr(() => {}, ['callbacks', 'success'], meta),
        error: () => callNotification.error('Note cannot be deleted'),
        params,
        ...meta,
      }),
    ],
    [types.ADD_NOTE_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/documents`,
        selectorName: 'addNoteRequest',
        method: 'POST',
        actions: {
          success: setNote,
        },
        success: pathOr(() => {}, ['callbacks', 'success'], meta),
        params,
        ...meta,
      }),
    ],
    [types.ADD_PROJECT_MEMBERS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/members/add`,
        selectorName: 'addProjectMembersRequest',
        method: 'PUT',
        schema: {
          rules: { project: dataSchema },
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setProjectMembers,
            {
              pathEntities: ['data', 'entities', 'members'],
            }),
        },
        params,
        ...meta,
      }),
    ],
    [types.DELETE_PROJECT_MEMBER_REQUEST]: [
      identity,
      ({ projectId, memberId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/members/${memberId}`,
        selectorName: 'deleteProjectMemberRequest',
        method: 'DELETE',
        actions: {
          success: action => deleteProjectMember({ ...action, meta }),
        },
        params,
        ...meta,
      }),
    ],
    [types.GET_PINNED_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/pinned-tasks`,
        selectorName: 'getPinnedTasks',
        method: 'GET',
        schema: {
          rules: pinnedTasksListSchema,
          pathData: [],
        },
        actions: {
          success: setPinnedTasks,
        },
        params,
        ...meta,
      }),
    ],
    [types.GET_STATUSES_OF_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/statuses`,
        selectorName: 'getStatusesOfTasksRequest',
        method: 'GET',
        schema: {
          rules: statusesListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setStatusesOfTasks,
        },
        params,
        ...meta,
      }),
    ],
    [types.ADD_STATUS_OF_TASKS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/statuses`,
        selectorName: 'getStatusesOfTasksRequest',
        method: 'POST',
        actions: {
          success: setStatusOfTasks,
        },
        params,
        ...meta,
      }),
    ],
    [types.DELETE_STATUS_OF_TASKS_REQUEST]: [
      identity,
      ({ projectId, statusId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/statuses/${statusId}`,
        selectorName: 'deleteStatusOfTasksRequest',
        method: 'DELETE',
        actions: {
          success: deleteStatusOfTasks,
        },
        params,
        ...meta,
      }),
    ],
    [types.UPDATE_STATUSES_PRIORITY_LIST_REQUEST]: [
      identity,
      ({ projectId, statusId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/update-priority-status-list`,
        selectorName: 'updateStatusesPriorityListRequest',
        method: 'PUT',
        actions: {
          success: updateStatusesPriorityList,
        },
        params,
        ...meta,
      }),
    ],
    [types.UPDATE_STATUS_OF_TASKS_REQUEST]: [
      identity,
      ({ projectId, statusId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/statuses/${statusId}`,
        selectorName: 'updateStatusOfTasksRequest',
        method: 'PUT',
        actions: {
          success: setStatusOfTasks,
        },
        params,
        ...meta,
      }),
    ],
  },
  types.EDIT_PROJECT,
  types.SET_PROJECT_TASKS,
  types.SET_SPRINT,
  types.REORDER_SPRINT_TASKS,
  types.REORDER_BACKLOG_TASKS,
  types.SET_BACKLOG_TASK,
  types.REMOVE_SPRINT_TASK,
  types.REMOVE_BACKLOG_TASK,
  types.SET_SPRINT_TASK,
  types.SET_CATEGORIES_TASK,
  types.SORT_SPRINT_TASKS,
  types.SORT_BACKLOG_TASKS,
  types.SET_SPRINT_TASK_TO_LIST,
  types.SET_AUTHOR,
  types.SET_ASSIGNEE,
  types.SET_AGENDA_TASKS,
  types.ON_DRAG_AGENDA_END,
  types.REORDER_AGENDA_TASKS,
  types.SET_SPRINTS,
  types.DELETE_PROJECT_TASK,
  types.CLEAR_PROJECT_DATA,
  types.SET_USERS_WITH_TASKS,
  types.DELETE_SPRINT,
  types.REORDER_SPRINT,
  types.DELETE_SPRINT_ENTITY,
  types.SET_CATEGORY_TASK,
  types.DELETE_CATEGORY_TASK,
  types.DELETE_CATEGORY_TASK_ENTITY,
  types.REORDER_CATEGORY_TASK,
  types.SET_IMPORTANT_TASKS,
  types.SET_NOTES,
  types.DELETE_NOTE,
  types.DELETE_NOTE_ENTITY,
  types.REORDER_NOTES,
  types.SET_NOTE,
  types.SET_PROJECT_MEMBERS,
  types.DELETE_PROJECT_MEMBER,
  types.DELETE_PROJECT_MEMBER,
  types.REORDER_PROJECT_MEMBERS,
  types.SET_SUMMARY,
  types.SET_PINNED_TASKS,
  types.SET_PIN_TASK,
  types.RESET_PIN_TASK,
  types.GET_PROJECT_ERROR,
  types.SET_TASK_TO_AGENDA,
  types.DELETE_TASK_OF_AGENDA,
  types.SET_STATUSES_OF_TASKS,
  types.SET_STATUS_OF_TASKS,
  types.DELETE_STATUS_OF_TASKS,
  types.UPDATE_STATUSES_PRIORITY_LIST,
  types.ON_DRAG_TASKS_STATUS,
  types.DELETE_PROJECT_TASK_SUCCESS,
);
