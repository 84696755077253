import React from 'react';
import {
  keys, find, propEq, is, equals, test, cond, all, compose, reduce, map, not, identity, flip,
  any, reject, propOr,
} from 'ramda';
import PropTypes from 'prop-types';

import { ModalWindow, TabWrapper, ScrollWrapper } from '../../../../../../../../ui-kit';
import './style.sass';
import BusinessProcessTypes from '../../../../../helpers/types';

const getNodeLabel = (events, nodes, nodeId) => {
  const { title } = events[nodes[nodeId].event_id];
  return <><span>[{nodes[nodeId].id}]</span>{title}</>;
};

const getIsAllowed = (node, variable) => {
  if (node) {
    const { focusNode } = window.getSelection();
    if (focusNode) {
      const field = focusNode.parentNode.hasAttribute('contenteditable') ? focusNode.parentNode : focusNode;
      // console.log(node);
      if (field && field.getAttribute && field.getAttribute('id')) {
        const [group, name] = field.getAttribute('id').split(',');
        const input = find(propEq('name', name), propOr([], group, node));
        if (input) {
          const { type: inputType } = input.input_options || {};
          const { type: variableTypeNative } = variable.input_options || {};
          const variableType = propOr(variableTypeNative, 'custom', variableTypeNative);
          if (inputType || equals(inputType, 0)) {
            const listOfCond = [
              [is(Number), equals(variableType)],
              [is(String), compose(flip(test)(variableType), inputTypeStr => new RegExp(inputTypeStr, 'g'))],
            ];
            const getLogicFunc = logicKey => (logicKey === 'not' ? not : identity);
            const getLogicCountFunc = logicKey => (logicKey === 'not' ? all(equals(true)) : any(equals(true)));

            return cond([
              ...listOfCond,
              [is(Object), compose(
                all(equals(true)),
                reduce((accum, logicKey) => [...accum,
                  compose(
                    getLogicCountFunc(logicKey),
                    map(compose(
                      getLogicFunc(logicKey),
                      cond(listOfCond),
                    )),
                  )(inputType[logicKey])], []),
                keys,
                reject(equals('custom')),
              )],
            ])(inputType);
          }
        }
      }
    }
  }
  return true;
};

const VariablesModal = ({
  isOpen, onCloseModal, variablesOfNodes, onSetVariable, getVariableLabelByText, events, nodes,
  selectedAction,
}) => (
  <ModalWindow
    title="Select variables"
    isOpen={isOpen}
    onClose={onCloseModal}
    isPreventTouch
    onCloseModal={onCloseModal}
    className="modal-window--big modal-window__bp-variables"
  >
    <div className="bpVariables__list">
      <ScrollWrapper heightIs={400} autoHide>
        {
          keys(variablesOfNodes).map(nodeId => (
            <TabWrapper key={nodeId} title={<div className="bpVariables__title-group">{getNodeLabel(events, nodes, nodeId)}</div>}>
              {
                variablesOfNodes[nodeId].map((variable) => {
                  const { name, description } = variable;
                  const isAllowed = getIsAllowed(events[selectedAction.value], variable);
                  if (!isAllowed) return (<div key={name} style={{ display: 'none' }} />);
                  return (
                    <div className="bpVariables__item" key={name}>
                      <div className="bpVariables__name">
                        <button
                          className="bpVariables__variable-button"
                          type="button"
                          onClick={() => onSetVariable({
                            nodeId,
                            name,
                            variable,
                          })}
                        >{getVariableLabelByText({ nodeId, variable })}
                        </button>
                      </div>
                      <div className="bpVariables__description">
                        {description}
                      </div>
                    </div>
                  );
                })
              }
            </TabWrapper>))
        }
      </ScrollWrapper>
    </div>
  </ModalWindow>
);

VariablesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variablesOfNodes: PropTypes.instanceOf(Object).isRequired,
  onSetVariable: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  getVariableLabelByText: PropTypes.func.isRequired,
  events: PropTypes.shape({ [PropTypes.number]: BusinessProcessTypes.Event }).isRequired,
  selectedAction: PropTypes.instanceOf(Object).isRequired,
  nodes: PropTypes.shape({ [PropTypes.number]: BusinessProcessTypes.ProcessNode }).isRequired,
};

export default VariablesModal;
