import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import DeleteProcessModal from './deleteProcessModal';
import { uiActions, uiSelectors } from '../../../../../../../state/ui';
import { bpActions, bpSelectors } from '../../../../store/bpModule';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  deleteProcess: bpActions.deleteBusinessProcessRequest,
};

const mapStateToProps = (state, { crudProcessConfig: { selectedProcess } }) => ({
  isOpen: uiSelectors.getModal(state)('deleteProcessModal'),
  process: bpSelectors.getProcessById(state)(selectedProcess),
  isPending: bpSelectors.getPendingDeleteBusinessProcess(state),
});


const onDeleteHandler = ({
  process: { id: processId },
  deleteProcess, onClose,
}) => () => deleteProcess({ processId }, { meta: { processId }, callbacks: { success: onClose } });

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('deleteProcessModal');

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);


export default enhance(DeleteProcessModal);
