import { compose } from 'recompose';

import AddAction from './editAction';

import { withActionCRUD } from '../../../../../enchancer';
import { NODES_ACTIONS_CONST } from '../../../../../constants/crud';

const enhance = compose(
  withActionCRUD({
    action: NODES_ACTIONS_CONST.UPDATE,
  }),
);

export default enhance(AddAction);
