import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import { ButtonsCollection, ToggleButton, FormsCollection } from '../../../../../../../ui-kit';
import './style.sass';

const EditProcess = ({ values, handleChange, handleSubmit }) => (
  <FormsCollection.FormWrapper className="processContainer__form-main" handleSubmit={handleSubmit}>
    <FormsCollection.Input
      placeholderJump="Process name"
      id="processName"
      name="title"
      type="text"
      onChange={handleChange}
      value={pathOr('', ['title'], values)}
    />
    <div className="processContainer__status-row">
      Business process status:
      <ToggleButton
        onChange={handleChange}
        name="status"
        status={pathOr(0, ['status'], values)}
      />
    </div>
    <ButtonsCollection.ButtonBrill
      type="submit"
      className="button--md processContainer__button-save button-brill--fill"
    >Save
    </ButtonsCollection.ButtonBrill>
  </FormsCollection.FormWrapper>
);

EditProcess.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditProcess;
