import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, ShowMore, DropDown } from '../../../../ui-kit';
import './style.sass';
import { Summary } from '..';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import MessageElements from '../../../../components/messageElements';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const TaskAbout = ({
  task, windowWidth, onEdit, onDelete, t, isTaskPin, onPin, onUnpin, P, isShowMore, setIsShowMore,
}) => (
  <div className="task-about">
    <div className="task-about__header">
      <div className="task-about__code">
        {`#${task.id}`}
      </div>
      <h2 className="task-about__title text--cut">{task.title}</h2>
      <div className="task-about__control">
        <DropDown
          className="controls-group-task"
          name="controls-group-task"
          label={(
            <ButtonsCollection.ButtonIcons
              className="project-task__controls-button-task task-about__control-button"
              title={t('Control buttons')}
            >
              <span className="project-task__control-icon icon-settings-vertical">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
            </ButtonsCollection.ButtonIcons>
          )}
          list={
            [
              {
                id: 0,
                onClick: onEdit,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pencil-2" />
                    <span className="controls-group-task__text">{t('Edit')}</span>
                  </>
                ),
              },
              {
                id: 1,
                onClick: isTaskPin ? onUnpin : onPin,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pin" />
                    <span className="controls-group-task__text">{isTaskPin ? 'Unpin' : t('Pin')}</span>
                  </>
                ),
              },

              ...P(MODULES.TASKS, ACTIONS.DELETE, [{
                id: 2,
                onClick: onDelete,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-delete-icon" />
                    <span className="controls-group-task__text">{t('Delete')}</span>
                  </>
                ),
              }]),
            ]
          }
        />
      </div>
    </div>
    <div className="task-about__body">
      {
        windowWidth < WINDOW_WIDTH.X_SMALL && (
          <div className={`task-about__show-more-button ${isShowMore ? 'active' : ''}`}>
            <ButtonsCollection.ButtonIcons
              type="button"
              title="Show more"
              onClick={() => setIsShowMore(!isShowMore)}
            >
              <span className="icon-down" />
            </ButtonsCollection.ButtonIcons>
          </div>
        )
      }
      {isShowMore && (
        <div className="task-about__show-more__summary-task-mobile">
          <Summary task={task} />
        </div>
      )}
      <div className="task-about__body-content selectable-text">
        <h3>{t('Description')}</h3>
        <MessageElements
          messageId={task.id}
        >
          {task.description}
        </MessageElements>
      </div>
      <div className="task-about__aside">
        {
          windowWidth > WINDOW_WIDTH.MEDIUM && (
            <Summary task={task} />
          )
        }
        {
          windowWidth > WINDOW_WIDTH.X_SMALL
          && windowWidth < WINDOW_WIDTH.MEDIUM
          && (
            <ShowMore className="task-about__show-more">
              <Summary task={task} />
            </ShowMore>
          )
        }
      </div>
    </div>
  </div>
);

TaskAbout.propTypes = {
  isShowMore: PropTypes.bool.isRequired,
  setIsShowMore: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  task: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isTaskPin: PropTypes.bool.isRequired,
  onPin: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  P: PropTypes.func.isRequired,
};

export default TaskAbout;
