import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { RightSideBar } from '../../../../../newContainers';
import './style.sass';
import { RIGHT_SIDEBAR_VIEWS } from '../../constants/common';
import {
  EditProcess, EditAction, AddAction, ProcessNode,
} from './components';
import DeleteNodeModal from './components/deleteNodeModal';
import { ScrollWrapper, TabWrapper } from '../../../../../ui-kit';
import InfoEventModal from './components/infoEventModal';
import BusinessProcessTypes from '../../helpers/types';

const ProcessContainer = ({
  result, hasNotNodes, crudNodesConfig, onAddAction, onEditAction, onDeleteAction,
  setCrudNodesConfig, onToggleRightSideBar, editingEvent,
}) => (
  <>
    <ScrollWrapper className="processContainer__scroll-wrapper">
      <div className="processContainer">
        <div className="processContainer__area">
          {
            result.map(processNodeId => (
              <ProcessNode
                key={processNodeId}
                processNodeId={processNodeId}
                onAddAction={onAddAction}
                onEditAction={onEditAction}
                onDeleteAction={onDeleteAction}
                crudNodesConfig={crudNodesConfig}
                setCrudNodesConfig={setCrudNodesConfig}
              />
            ))
          }
          <button
            onClick={() => onAddAction(null)}
            type="button"
            className="pieceOfProcess pieceOfProcess__add"
          >
            <span className="pieceOfProcess__title">Add new {hasNotNodes ? 'trigger' : 'action'}</span>
          </button>
        </div>
      </div>
    </ScrollWrapper>
    <RightSideBar
      onToggleMiddleware={onToggleRightSideBar}
      className="right-side-bar--bp"
      title="Process configuration"
    >
      <ScrollWrapper>
        <TabWrapper
          defaultStatusTab
          title={(
            <h2 className="title">

              Process Information
            </h2>
          )}
        >
          <EditProcess />
        </TabWrapper>
        {
          crudNodesConfig.view !== RIGHT_SIDEBAR_VIEWS.NOTHING && (
            <TabWrapper
              defaultStatusTab
              className="pieceOfProcess__actionTabWrapper"
              title={(
                <h2 className="title">
                  {
                    {
                      [RIGHT_SIDEBAR_VIEWS.EDIT_ACTION]: ('Edit action'),
                      [RIGHT_SIDEBAR_VIEWS.CREATE_ACTION]: (
                        hasNotNodes
                          ? 'Select trigger'
                          : 'Add action'),
                    }[crudNodesConfig.view]

                  }
                </h2>
              )}
            >
              {
                {
                  [RIGHT_SIDEBAR_VIEWS.EDIT_ACTION]: (<EditAction
                    crudNodesConfig={crudNodesConfig}
                    setCrudNodesConfig={setCrudNodesConfig}
                    shouldBeTrigger={!propOr(true, 'is_action', editingEvent)}
                  />),
                  [RIGHT_SIDEBAR_VIEWS.CREATE_ACTION]: (<AddAction
                    setCrudNodesConfig={setCrudNodesConfig}
                    crudNodesConfig={crudNodesConfig}
                    shouldBeTrigger={hasNotNodes}
                  />),
                }[crudNodesConfig.view]

              }
            </TabWrapper>
          )
        }
      </ScrollWrapper>
    </RightSideBar>
    <DeleteNodeModal crudNodesConfig={crudNodesConfig} />
    <InfoEventModal crudNodesConfig={crudNodesConfig} />
  </>
);

ProcessContainer.propTypes = {
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  hasNotNodes: PropTypes.bool.isRequired,
  crudNodesConfig: PropTypes.instanceOf(Object).isRequired,
  onAddAction: PropTypes.func.isRequired,
  onEditAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  setCrudNodesConfig: PropTypes.func.isRequired,
  editingNode: BusinessProcessTypes.ProcessNode,
  onToggleRightSideBar: PropTypes.func.isRequired,
};

export default ProcessContainer;
