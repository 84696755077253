import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../../../../../ui-kit';
import BusinessProcessTypes from '../../../../helpers/types';

const DeleteProcessModal = ({
  isOpen,
  isPending,
  onClose,
  process,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete process"
  >

    Are you sure you want to delete
    <b>{prop('title', process)}</b>

    process?
  </ConfirmModal>
);

DeleteProcessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  process: BusinessProcessTypes.BusinessProcess.isRequired,
};

export default DeleteProcessModal;
