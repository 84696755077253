import {
  compose, withStateHandlers, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { memo } from 'react';
import { includes, prop } from 'ramda';

import { uiActions } from '../../../../state/ui';
import { getProjectSprint } from '../../../../state/project/selectors';
import Sprint from './sprint';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import { withWindowWidth } from '../../../../utils/enchancers';
import { SPRINT_STATUS } from '../../../../constants/sprints';

const mapStateToProps = (state, props) => ({
  item: getProjectSprint(state)(props.id),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onOpenTabSprintStateHandler = ({ isOpenSprintTab }) => (val = null) => ({
  isOpenSprintTab: val !== null ? val : !isOpenSprintTab,
});

const onEditHandler = ({ id, setOpenModal, setSelectedSprintId }) => () => {
  setSelectedSprintId(id);
  setOpenModal('editSprintModal');
};

const onDeleteHandler = ({
  id, setOpenModal, setSelectedSprintId, onOpenTabSprint,
}) => () => {
  setSelectedSprintId(id);
  onOpenTabSprint(false);
  setOpenModal('deleteSprintModal');
};

const onCompleteHandler = ({
  id, setOpenModal, setSelectedSprintId, onOpenTabSprint,
}) => () => {
  setSelectedSprintId(id);
  onOpenTabSprint(false);
  setOpenModal('completeSprintModal');
};

const onStartHandler = ({
  id, setOpenModal, setSelectedSprintId, onOpenTabSprint,
}) => () => {
  setSelectedSprintId(id);
  onOpenTabSprint(true);
  setOpenModal('startSprintModal');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common', 'tasks']),
  getContext({
    setSelectedSprintId: PropTypes.func.isRequired,
  }),
  withProps(({ item: { totalEstimated, totalSpend, status } }) => ({
    totalSpent: getTimeStringBySeconds(totalSpend),
    totalEstimated: getTimeStringBySeconds(totalEstimated),
    isSprintCompleted: SPRINT_STATUS.COMPLETE === status,
  })),
  withStateHandlers(({ item }) => ({ isOpenSprintTab: includes(prop('status', item), [SPRINT_STATUS.ACTIVE, SPRINT_STATUS.NOT_ACTIVE]) }), {
    onOpenTabSprint: onOpenTabSprintStateHandler,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onComplete: onCompleteHandler,
    onStart: onStartHandler,
  }),
  memo,
);
export default enhance(Sprint);
