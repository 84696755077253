import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Layouts, NoInternetConnection, Header,
} from '../../ui-kit';
import { LeftSideBar } from '../../newContainers';
import Activity from './components';
import './style.sass';
import { PageError } from '..';
import { TYPES } from '../../constants/users';
import { userSelectors } from '../../state/user';
import { uiActions } from '../../state/ui';
import { VIEWS } from '../../constants/ui';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ActivityPage = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelectors.getUserData());
  const allowedViews = Object.keys(VIEWS);
  const view = window.location.pathname.split('/')[2];

  useEffect(() => {
    if (!view) push('/activity/me/');
    if (currentUser.type !== TYPES.ADMIN && allowedViews?.includes(view)) push('/activity/me/');
    dispatch(uiActions.setAppTitle('Activity - Avanga 2.0'));
  }, []);
  return (allowedViews?.includes(view) ? (
    <Layouts.Main rightSideBarStatus={false}>
      <LeftSideBar />
      <div className="main-container page-activity__main-container">
        <NoInternetConnection>
          <Header>
            <h1 className="weight--medium title--xl title-page">
              {t('Activity')}
            </h1>
          </Header>
          <div className="main-container__content page-activity__activity-content">
            <Activity view={view} />
          </div>
        </NoInternetConnection>
      </div>
    </Layouts.Main>
  ) : <PageError />);
};
