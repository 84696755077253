import { compose, withProps, withHandlers } from 'recompose';
import { propOr } from 'ramda';
import { connect } from 'react-redux';
import { push as connectRouterPush } from 'connected-react-router';

import ProcessNode from './processNode';
import { bpSelectors } from '../../../../store/bpModule';
import { uiActions } from '../../../../../../../state/ui';

const mapStateToProps = (store, { processNodeId }) => ({
  processActions: bpSelectors.getEventsEntities(store),
  processNode: bpSelectors.getProcessNodeById(store)(processNodeId),
  nodesResult: bpSelectors.getProcessNodeResult(store),
});

const mapDispatchToProps = ({
  openModal: uiActions.openModal,
  push: connectRouterPush,
});

const onOpenInfoHandler = ({
  openModal, setCrudNodesConfig, crudNodesConfig, processNode: { event_id },
}) => () => {
  openModal('businessProcessEventInfo');
  setCrudNodesConfig({ ...crudNodesConfig, selectedEventId: event_id });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onOpenInfo: onOpenInfoHandler,
  }),
  withProps(({ processNode, processActions, nodesResult }) => {
    const { title } = propOr({}, processNode.event_id, processActions);

    return {
      isAllowDeleting: !(processNode.parent_id === null && nodesResult.length > 1),
      processTitle: `#${processNode.id} ${title}`,
    };
  }),
);

export default enhance(ProcessNode);
