import {
  compose, lifecycle, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { push as connectRouterPush } from 'connected-react-router';

import {
  map, objOf, path, pathOr, prop, values as RValues, keys, reduce, propOr,
} from 'ramda';
import FieldByOption from './fieldByOption';
import { bpSelectors } from '../../../../store/bpModule';
import { renameKeysTitleIntoValue } from '../../../../../../../utils/helpers/uiHelpers';
import { uiActions } from '../../../../../../../state/ui';
import { withAutocomplete, withRefs } from '../../../../../../../utils/enchancers';
import { AUTOCOMPLETE_MODELS_CONFIG } from '../../../../constants/api';

const mapStateToProps = (store, { processId }) => ({
  process: bpSelectors.getProcessById(store)(processId),
  entitiesOfActions: bpSelectors.getActionsEntities(store),
});

const mapDispatchToProps = ({
  push: connectRouterPush,
  openModal: uiActions.openModal,
});

const onChangeActionHandler = () => objOf('selectedAction');

const onOpenModalAddVariableHandler = (state, { openModal, getRef }) => () => {
  const field = getRef('FIELD');
  if (field) field.focus();
  // todo set focus to end
  openModal('businessProcessVariablesModal');
};
const handleChangeHandler = ({
  setFieldValue, fieldGroup, setFieldError,
  values, config: { input_options: { validation } },
}) => ({ target: { value, name } }) => {
  setFieldValue(fieldGroup,
    { ...values[fieldGroup], [name]: value });
  if (validation) {
    const schema = reduce((accum, ruleMethod) => (accum || yup)[ruleMethod](validation[ruleMethod]),
      null, keys(validation));
    schema.validate(value).catch(({ message }) => {
      setFieldError(fieldGroup,
        { ...values[fieldGroup], [name]: propOr(message, 'message', message) });
    });
  }
};

const handleKeyDownHandler = ({
  config: {
    input_options: {
      isSimple = false,
      only_variables: onlyVariables = false,
    },
  },
}) => (e) => {
  if (onlyVariables) {
    e.preventDefault();
  }
  if (isSimple) {
    if (e.target.querySelector('variable')) {
      e.target.innerHTML = '';
    }
  }
};

const setFieldRefHandler = ({ setRef }) => e => setRef('FIELD', e);

const handleTouchOrMouseDownHandler = (
  {
    config: {
      input_options: {
        only_variables: onlyVariables = false,
      },
    },
  },
) => (e) => {
  if (onlyVariables) {
    e.preventDefault();
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withProps(({
    fieldGroup, config, values, errors,
  }) => {
    const { fields, fields: { watchersType, assigneeType } } = values;
    fields.watchersType = watchersType || pathOr('', ['default_value'], config);
    fields.assigneeType = assigneeType || pathOr('', ['default_value'], config);
    return {
      name: path(['name'], config),
      value: pathOr('', [fieldGroup, path(['name'], config)], values),
      error: pathOr('', [fieldGroup, path(['name'], config)], errors),
      id: `${fieldGroup},${prop('name', config)},${Number(pathOr(0, ['input_options', 'isSimple'], config))}`,
      defaultValue: pathOr('', ['default_value'], config),
      personalOptions: pathOr(path(['input_options', 'logicSelect'], config), ['input_options', 'personalSelect'], config),
      values: { ...values, fields },
    };
  }),
  withHandlers({
    setFieldRef: setFieldRefHandler,
    handleChange: handleChangeHandler,
    handleKeyDown: handleKeyDownHandler,
    handleTouchOrMouseDown: handleTouchOrMouseDownHandler,
  }),
  withStateHandlers(() => ({ selectedAction: null }), {
    onChangeAction: onChangeActionHandler,
    onOpenModalAddVariable: onOpenModalAddVariableHandler,
  }),
  withProps(({ entitiesOfActions, selectedAction }) => ({
    optionsOfActions: compose(
      map(renameKeysTitleIntoValue),
      RValues,
    )(entitiesOfActions),
    fields: prop('input', selectedAction),
  })),
  withAutocomplete(props => AUTOCOMPLETE_MODELS_CONFIG[path(['config', 'input_options', 'model'], props)]),
  lifecycle({
    componentDidMount() {
      const { getRef } = this.props;
      requestAnimationFrame(() => {
        const field = getRef('FIELD');
        if (field) {
          field.scrollTo(field.scrollWidth, field.scrollHeight);
        }
      });
    },
  }),
);

export default enhance(FieldByOption);
