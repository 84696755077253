import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { permissionsActions } from '../../../../state/permissions';
import { USER_STATUSES } from '../../../../constants/users';
import {
  Header,
  AsyncAutoComplete,
  ButtonsCollection,
  FormsCollection,
} from '../../../../ui-kit';
import rules from './rules';
import './style.sass';
import api from '../../../../api/settings.service';
import { APP_ROUTES } from '../../../../constants/appRoutes';
import { useSubscriptionPermission } from '../../../../utils/hooks/useSubscriptionPermission';
import { MODULES } from '../../../../constants/pemissions';

/**
 * @param getRolesAutocomplete {function}
 * @returns {JSX.Element}
 * @constructor
 */

const UserInvite = ({
  getRolesAutocomplete,
}) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);

  const {
    control, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(rules),
  });

  const getRolesRequest = useCallback((value) => {
    dispatch(permissionsActions.getRolesRequest(value));
  });

  const createUserRequest = useCallback(async (value) => {
    const data = await api.createSettingUserRequest(value);
    setIsPending(true);
    if (data?.user) {
      setIsPending(false);
      dispatch(push(APP_ROUTES.PEOPLE));
    }
  });

  const onSend = (values) => {
    createUserRequest({
      ...values,
      userId,
      role_id: values.role_id.value,
      status: USER_STATUSES[3].value,
    });
  };

  const onSubmit = useCallback(values => onSend(values), []);

  useEffect(() => {
    getRolesRequest();
  }, []);
  useSubscriptionPermission(MODULES.SETTINGS);
  return (
    <>
      <Header hideToggleRightSideBar className="user-header">
        <h1 className="weight--medium title--xl title-page">
          <div className="xs-hidden">{t('User management')} - {' '}</div>
          {t('Invite user')}
        </h1>
        <ButtonsCollection.ButtonBrill
          asLink
          href={APP_ROUTES.PREFERENCES_INVITE_USER}
          type="button"
          renderMobileButton
          className="user-header__button-invite"
        >
          {t('Create user')}
        </ButtonsCollection.ButtonBrill>
      </Header>
      <div className="main-container__content main-container__content--profile">
        <div className="userEdit">
          <FormsCollection.FormWrapper classNameForm="userFormInvite" handleSubmit={handleSubmit(onSubmit)}>
            <div className="userFormInvite__row">
              <div className="userFormInvite__col">
                <label className="userFormInvite__label" htmlFor="userEdit_email">{t('Email')}</label>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      value={value}
                      id="userEdit_email"
                      error={errors.email?.message}
                      touched={touched}
                      onBlur={onBlur}
                      name="email"
                      onChange={onChange}
                      placeholder="example@gmail.com"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="userFormInvite__col">
                <label
                  className="userFormInvite__label"
                  htmlFor="userEdit_username"
                >
                  {t('Username')}
                </label>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      value={value}
                      id="userEdit_username"
                      error={errors.username?.message}
                      touched={touched}
                      onBlur={onBlur}
                      name="username"
                      onChange={onChange}
                      placeholder={t('Username')}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="userFormInvite__row">
              <div className="userFormInvite__col">
                <label className="userFormInvite__label" htmlFor="userEdit_firstName">{t('First name')}</label>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      value={value}
                      id="userEdit_firstName"
                      error={errors.first_name?.message}
                      touched={touched}
                      onBlur={onBlur}
                      name="first_name"
                      onChange={onChange}
                      placeholder={t('First name')}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="userFormInvite__col">
                <label className="userFormInvite__label" htmlFor="userEdit_lastName">{t('Last name')}</label>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      value={value}
                      id="userEdit_lastName"
                      error={errors.last_name?.message}
                      touched={touched}
                      onBlur={onBlur}
                      name="last_name"
                      onChange={onChange}
                      placeholder={t('Last name')}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="userFormInvite__row">
              <div className="userFormInvite__col">
                <span className="userFormInvite__label">{t('Role')}</span>
                <Controller
                  name="role_id"
                  control={control}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <AsyncAutoComplete
                      value={value}
                      error={errors.role_id?.message}
                      closeMenuOnSelect
                      touched={touched}
                      onBlur={onBlur}
                      name="role_id"
                      defaultValue={value}
                      onChange={onChange}
                      loadOptions={getRolesAutocomplete}
                      placeholder={t('Role')}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="userFormInvite__col">
                <span className="userFormInvite__label">{t('Status')}</span>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={USER_STATUSES[3].value}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Select
                      disabled
                      error={errors.status?.message}
                      onBlur={onBlur}
                      touched={touched}
                      value={value}
                      placeholder={USER_STATUSES[3].title}
                      name="status"
                      onChange={onChange}
                      data={[USER_STATUSES[3]]}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="userFormInvite__row userFormInvite__row--buttons">
              <ButtonsCollection.ButtonBrill
                type="submit"
                isPending={isPending}
                className="userFormInvite__submit-button button-brill--fill"
              >
                {t('Create user')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </FormsCollection.FormWrapper>
        </div>
      </div>
    </>
  );
};

export default UserInvite;
