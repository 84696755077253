import {
  forEach, keys, uniq, values,
} from 'ramda';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';

export const renameEventsFields = renameKeys({ title: 'label', id: 'value', options: 'rules' });

export const getListOfNodesWhatUsingVariables = (nodeId, events, nodes) => {
  let accum = [];
  forEach((node) => {
    const params = JSON.parse(node.params);
    forEach((key) => {
      if (new RegExp(`{${nodeId}\\|\\d*}`,
        'g').test(params[key])) accum = uniq([...accum, `#${nodeId} ${events[node.event_id].title}`]);
    }, keys(params));
  }, values(nodes));

  return accum;
};
