import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';

import ClientInfo from '../clientInfo';
import ContactsInfo from '../contactsInfo';
import ClientNotes from '../notesInfo';
import History from '../historyInfo';
import Products from '../products';

const ClientTab = ({ client }) => (
  <Tabs selectedTabClassName="client-navigation__link--active">
    <div className="client-navigation">
      <TabList className="client-navigation__list">
        <Tab className="client-navigation__link">Client Info</Tab>
        <Tab className="client-navigation__link">Contacts</Tab>
        <Tab className="client-navigation__link">Products</Tab>
        <Tab className="client-navigation__link">Notes</Tab>
        <Tab className="client-navigation__link">History</Tab>
      </TabList>
    </div>
    <TabPanel>
      <ClientInfo selectedClientId={client.id} />
    </TabPanel>
    <TabPanel>
      <ContactsInfo client={client} />
    </TabPanel>
    <TabPanel>
      <Products clientId={client.id} />
    </TabPanel>
    <TabPanel>
      <ClientNotes clientId={client.id} />
    </TabPanel>
    <TabPanel>
      <History clientId={client.id} />
    </TabPanel>
  </Tabs>
);

ClientTab.propTypes = {
  client: PropTypes.shape({}).isRequired,
};

export default ClientTab;
