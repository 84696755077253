import { handleActions } from 'redux-actions';

import { pathOr } from 'ramda';
import types from './types';
import {
  mergeByProp,
} from '../../../../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_BP_ROLES]: mergeByProp('roles', pathOr([], ['payload', 'data'])),
  [types.SET_BP_PROJECTS]: mergeByProp('projects', pathOr([], ['payload', 'data'])),
  [types.SET_BP_CHANNELS]: mergeByProp('channels', pathOr([], ['payload', 'data'])),
  [types.SET_BP_USERS]: mergeByProp('channels', pathOr([], ['payload', 'data'])),
  [types.SET_BP_CLIENTS]: mergeByProp('clients', pathOr([], ['payload', 'data'])),
}, {
});

export default reducer;
