import { createSelector } from 'reselect';
import { pathOr, identity } from 'ramda';

const getCustomer = createSelector(pathOr({}, ['subscription', 'customer']), identity);
const getSubscription = createSelector(pathOr(null, ['subscription', 'subscription']), identity);

export {
  getCustomer,
  getSubscription,
};
