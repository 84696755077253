export const API_ROUTES = {
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/forgot-password/change-password',
  CONTINUE_SIGN_UP: '/auth/continue-sign-up',
  FAVORITE_PROJECTS: '/favorite-projects/',
  PROJECTS_SUMMARY: '/projects/summary',
  MY_TASKS_SUMMARY: '/tasks',
  IMPORTANT_TASKS: '/tasks/important',
  PROJECT_COWORKERS: '/projects/coworkers',
  UPDATE_USER_PROFILE: '/users/profile',
  UPDATE_PROFILE_SETTINGS: '/profile-settings',
  GOOGLE_LOGIN: '/authenticated/google',
  CHECK_CODE_REQUEST: '/auth/check-activation-code',
  FORGOT_PASSWORD_CODE: '/forgot-password/check-code',
  SUBSCRIBER_INFO: '/subscriber-info',
  ACTIVITY: {
    ME: '/stats/me',
    USERS: '/stats/users',
    USER_TOTAL_TIME: '/stats/me',
    USERS_TOTAL_TIME: '/stats/users',
    PROJECTS_TOTAL_TIME: '/stats/projects',
    USERS_LIST_AUTOCOMPLETE: '/users/autocomplete',
    PROJECTS_TASK_CRUD: '/projects',
    USER_AUTOCOMPLETE: '/users/autocomplete',
    USER_REPORT: '/stats/report',
  },
};
