import {
  branch,
  compose, renderNothing, withHandlers, withProps,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import InfoEventModal from './infoEventModal';
import { uiActions, uiSelectors } from '../../../../../../../state/ui';
import { bpActions, bpSelectors } from '../../../../store/bpModule';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  deleteProcessNodeRequest: bpActions.deleteBusinessProcessNodeRequest,
};

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('businessProcessEventInfo'),
  events: bpSelectors.getEventsEntities(state),
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('businessProcessEventInfo');

const enhance = compose(
  branch(({ crudNodesConfig: { selectedEventId } }) => !selectedEventId, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ events, crudNodesConfig: { selectedEventId } }) => ({
    event: events[selectedEventId],
  })),
  withHandlers({
    onClose: onCloseHandler,
  }),
);


export default enhance(InfoEventModal);
