import React from 'react';
import PropTypes from 'prop-types';

import { MY_PROJECTS_FILTER, STYLE_VIEW } from '../../../../constants/projects';
import {
  ButtonsCollection, MobileFiltersContainer, DropDown, FormsCollection,
} from '../../../../ui-kit';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';

const FiltersRow = ({
  handlerStatusVisible, viewStyle, onChangeSorting, sortingByDate,
  searchName, setSearchProjectsName, onSearch, t,
  projectsFilter, onSetProjectsFilter, areMobileFiltersOpen, toggleMobileFiltersHandler,
}) => (
  <div className="projects-filter">
    <div className="projects-filter__tab-visible">
      <ButtonsCollection.ButtonBrill
        className={`button--fill-grey projects-filter__btn-tab${viewStyle === STYLE_VIEW.CELL ? ' active' : ''}`}
        onClick={() => handlerStatusVisible(STYLE_VIEW.CELL)}
      >
        <span className="projects-filter__icon-cells" />
        {t('Cells view')}
      </ButtonsCollection.ButtonBrill>
      <ButtonsCollection.ButtonBrill
        className={`projects-filter__btn-tab${viewStyle === STYLE_VIEW.LIST ? ' active' : ''}`}
        onClick={() => handlerStatusVisible(STYLE_VIEW.LIST)}
      >
        <span className="projects-filter__icon-list" />
        {t('List view')}
      </ButtonsCollection.ButtonBrill>
    </div>
    <div className="projects-filter__sort-group">
      <FormsCollection.Search
        className="search-field--circle"
        placeholder={t('Search by project name')}
        name="search-project"
        value={searchName}
        onChange={setSearchProjectsName}
        onSearch={onSearch}
        withoutControl
      />
      <MobileFiltersContainer
        title="Filter"
        onWidth={WINDOW_WIDTH.SMALL}
        isOpen={areMobileFiltersOpen}
        onToggle={toggleMobileFiltersHandler}
      >
        <div className="projects-filter__sort-group">
          <DropDown
            label={`${t('Sort')} : `}
            placeholder="Custom"
            onChange={onChangeSorting}
            currentItem={sortingByDate}
            list={
            [
              {
                id: 0,
                order: 'desc',
                sortBy: 'created_at',
                label: t('Recent'),
              },
              {
                id: 1,
                order: 'asc',
                sortBy: 'created_at',
                label: t('Old'),
              },
              {
                id: 2,
                order: 'asc',
                sortBy: 'title',
                label: t('A-Z'),
              },
              {
                id: 3,
                order: 'desc',
                sortBy: 'title',
                label: t('Z-A'),
              },
            ]
          }
          />
          <DropDown
            label={`${t('Filter')} : `}
            onChange={onSetProjectsFilter}
            currentItem={projectsFilter}
            list={
            [
              {
                id: MY_PROJECTS_FILTER.ALL,
                label: 'All',
              },
              {
                id: MY_PROJECTS_FILTER.CREATED_BY_ME,
                label: 'Created by me',
              },
              {
                id: MY_PROJECTS_FILTER.FAVORITES,
                label: 'Favorites',
              },
              {
                id: MY_PROJECTS_FILTER.ACTIVE,
                label: 'Active',
              },
              {
                id: MY_PROJECTS_FILTER.INACTIVE,
                label: 'Inactive',
              },
            ]
          }
          />
        </div>
      </MobileFiltersContainer>
    </div>
  </div>
);

FiltersRow.propTypes = {
  handlerStatusVisible: PropTypes.func.isRequired,
  viewStyle: PropTypes.string.isRequired,
  searchName: PropTypes.string.isRequired,
  setSearchProjectsName: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortingByDate: PropTypes.number,
  onSetProjectsFilter: PropTypes.func.isRequired,
  projectsFilter: PropTypes.oneOfType([PropTypes.oneOf([null]),
    PropTypes.string]).isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

FiltersRow.defaultProps = {
  sortingByDate: null,
};

export default FiltersRow;
