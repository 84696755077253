import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MyTasksList from './myTasksList';
import { tasksActions, tasksSelectors } from '../../../../state/tasks';

const mapStateToProps = state => ({
  summary: tasksSelectors.getMyTasksSummary(state),
  isLoading: tasksSelectors.getMyTaskPendingRequest(state),
});
const mapDispatchToProps = {
  getMyTasksSummaryRequest: tasksActions.getMyTasksSummaryRequest,
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { data, getMyTasksSummaryRequest } = this.props;
      if (!data) getMyTasksSummaryRequest();
    },
  }),
  withTranslation(['common']),
);
export default enhance(MyTasksList);
