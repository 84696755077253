import {
  compose,
} from 'recompose';

import AddAction from './addAction';
import { withActionCRUD } from '../../../../../enchancer';
import { NODES_ACTIONS_CONST } from '../../../../../constants/crud';

const enhance = compose(
  withActionCRUD({
    action: NODES_ACTIONS_CONST.CREATE,
  }),
);

export default enhance(AddAction);
