import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push as connectRouterPush } from 'connected-react-router';

import ProcessCard from './processCard';
import { bpSelectors } from '../../../../store/bpModule';

const mapStateToProps = (store, { processId }) => ({
  process: bpSelectors.getProcessById(store)(processId),
});

const mapDispatchToProps = ({
  push: connectRouterPush,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ProcessCard);
