/* eslint-disable import/prefer-default-export */
import { compose } from 'recompose';
import {
  filter, isEmpty, propOr, values, length, propEq,
} from 'ramda';
import { push } from 'connected-react-router';

import projectTypes from '../../../state/project/types';

import {
  deleteCategoryTask, deleteNote, deleteSprint, deleteProjectMember,
} from '../../helpers/projectHelpers/crudHelper';
import { uiActions } from '../../../state/ui';
import { projectActions, projectSelectors } from '../../../state/project';
import { SPRINT_STATUS } from '../../../constants/sprints';


const deleteProjectMemberMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_PROJECT_MEMBER) {
    const { payload: { meta: { memberId } } } = action;
    deleteProjectMember(getState(), memberId, next);
  } else {
    next(action);
  }
};

const deleteCategoryMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_CATEGORY_TASK) {
    const { payload: { category } } = action;
    deleteCategoryTask(getState(), category.id, next);
  } else {
    next(action);
  }
};

const deleteNoteMiddleware = ({ getState }) => next => (action) => {
  if (action.type === projectTypes.DELETE_NOTE) {
    const { payload: { document } } = action;
    deleteNote(getState(), document.id, next);
  } else {
    next(action);
  }
};

const hasActiveSprint = compose(isEmpty, filter(propEq('status', SPRINT_STATUS.ACTIVE)), values);

const setSprintsMiddleware = () => next => (action) => {
  if (action.type === projectTypes.SET_SPRINTS) {
    const { payload: { meta = {}, data: { entities } } } = action;
    if (meta.isAgendaBoard && hasActiveSprint(propOr([], 'sprints', entities))) next(uiActions.setPageNotFound(true));
    next(projectActions.setSprints(action.payload));
  } else {
    next(action);
  }
};

const setSprintMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === projectTypes.SET_SPRINT) {
    const { payload: { meta = {}, sprint: { status, project_id } } } = action;
    const sprints = projectSelectors.getProjectSprintsTasks(getState());
    const countActiveSprints = compose(length, filter(propEq('status', SPRINT_STATUS.ACTIVE)), values)(sprints);
    if (meta.isAgendaBoard
      && status !== 0
      // eslint-disable-next-line camelcase
      && countActiveSprints === 1) dispatch(push(`/projects/${project_id}/`));
    next(action);
  } else {
    next(action);
  }
};

const deleteSprintMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === projectTypes.DELETE_SPRINT) {
    const { payload: { meta, sprint: { id, status, project_id } } } = action;
    const sprints = projectSelectors.getProjectSprintsTasks(getState());
    const countActiveSprints = compose(length, filter(propEq('status', SPRINT_STATUS.ACTIVE)), values)(sprints);
    if (meta.isAgendaBoard
      && status !== 0
      // eslint-disable-next-line camelcase
      && countActiveSprints === 1) dispatch(push(`/projects/${project_id}/`));
    deleteSprint(id, getState(), next);
  } else {
    next(action);
  }
};

export {
  deleteCategoryMiddleware,
  deleteNoteMiddleware,
  deleteProjectMemberMiddleware,
  deleteSprintMiddleware,
  setSprintsMiddleware,
  setSprintMiddleware,
};
