import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Route, Switch } from 'react-router';

import { LeftSideBar } from '../../../newContainers';
import { Layouts, NoInternetConnection, ModuleWrapper } from '../../../ui-kit';
import { BusinessProcess, BusinessProcesses } from './pages';

import { setAppTitle } from '../../../state/ui/actions';
import config from '../config';

const BPModule = () => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container">
      <ModuleWrapper title="Business Processes" config={config}>
        <NoInternetConnection>
          <Switch>
            <Route exact path="/business-processes/:processId/" component={BusinessProcess} />
            <Route exact component={BusinessProcesses} />
          </Switch>
        </NoInternetConnection>
      </ModuleWrapper>
    </div>
  </Layouts.Main>
);

const mapDispatchToProps = {
  setAppTitle,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('Business Processes - Avanga 2.0');
    },
  }),
);

export default enhance(BPModule);
