import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import { ButtonsCollection } from '../../../../../../../ui-kit';
import './style.sass';

/**
 *
 * @param planData {object}
 * @param subscription {object}
 * @param onSelectPlan {function}
 * @param onCancelPlan {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const CardItem = ({
  planData,
  subscription,
  onSelectPlan,
  onCancelPlan,
}) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const { metadata, unit_amount: planPrice } = planData;
  const { plan: { id: subscriptionId, amount }, cancel_at: cancelAt } = subscription;
  const metadataArray = Object.entries(metadata);

  const subscriptionEnd = () => {
    const currentDate = moment();
    const endDate = moment.unix(cancelAt);
    return endDate.diff(currentDate, 'days');
  };

  const onSelect = () => onSelectPlan(planData.id);
  const onCancel = () => onCancelPlan(subscription.id);
  const normalizePrice = value => (Number(value) / 100).toFixed(2);

  useEffect(() => {
    setIsDisabled(false);
    if (amount > planPrice && planPrice === 0) {
      setIsDisabled(prevState => !prevState);
    }
  }, [amount, planPrice]);
  return (
    <div className={classNames('card__subscription__wrapper', subscriptionId === planData?.id && '__selected')}>
      {subscriptionId === planData?.id && (<div className="card__subscription__selected">{t('Selected')}</div>)}
      <h4 className="card__subscription__title">
        {planData.product.name}
      </h4>
      <div className="card__subscription__price">
        <h5>
          {`$${normalizePrice(planPrice)}`}
        </h5>
        <span>
          per unit
        </span>
      </div>
      <div className="card__subscription__line" />
      <div className="card__subscription__services">
        <ul className="card__subscription__services__list">
          {metadataArray.map(item => (
            <li
              className={classNames('card__subscription__services__item', !!Number(item[1]) && 'available')}
            >
              {item[0]}
            </li>
          ))}
        </ul>
      </div>
      <div>
        {
          subscriptionId === planData?.id ? (
            <div className="card__subscription__cancel">
              {cancelAt && (
              <span className="card__subscription__cancel__text">
                {`Will cancel in ${subscriptionEnd()} days`}
              </span>
              )}
              <ButtonsCollection.ButtonBrill
                className="button-brill--fill card__subscription__select-button button--md"
                type="submit"
                onClick={onCancel}
              >
                {cancelAt ? t('Don`t cancel') : t('Cancel')}
              </ButtonsCollection.ButtonBrill>
            </div>
          )
            : (
              <ButtonsCollection.ButtonBrill
                className={classNames(isDisabled && 'button-brill--grey__disabled', !isDisabled && 'button-brill--fill', '__select-button button--md')}
                type="submit"
                onClick={onSelect}
                disabled={isDisabled}
                title={isDisabled && t('You cannot change to a cheaper subscription while your current plan is valid')}
              >
                {t('Select')}
              </ButtonsCollection.ButtonBrill>
            )
        }
      </div>
    </div>
  );
};
