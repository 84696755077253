import { createSelector } from 'reselect';
import {
  identity, pathOr, prop, propOr, reduce, values, compose, filter, propEq, sort, keys,
} from 'ramda';
import { memoizeWithIdentity, propNotEq } from 'ramda-extension';

const rootSelector = createSelector(
  propOr({}, 'businessProcess'),
  identity,
);

export const getBusinessProcessEntities = createSelector(
  rootSelector,
  pathOr({}, ['businessProcess', 'entities']),
);

export const getProcessById = createSelector(
  getBusinessProcessEntities,
  processesList => memoizeWithIdentity(processId => propOr({}, processId, processesList)),
);

export const getBusinessProcessResult = createSelector(
  rootSelector,
  pathOr([], ['businessProcess', 'result']),
);

export const getEventsEntities = createSelector(
  rootSelector,
  pathOr({}, ['events', 'entities']),
);

export const getEventsResult = createSelector(
  rootSelector,
  pathOr([], ['events', 'result']),
);

export const getTriggersResult = createSelector(
  getEventsResult,
  compose(reduce((accum, action) => (!action.is_action
    ? [...accum, action.id]
    : accum), []), values),
);

export const getTriggersEntities = createSelector(
  getEventsEntities,
  compose(
    reduce((accum, action) => (!action.is_action
      ? { ...accum, [action.id]: action }
      : accum), {}), values,
  ),
);

export const getActionsResult = createSelector(
  getEventsResult,
  compose(reduce((accum, action) => (action.is_action
    ? [...accum, action.id]
    : accum), []), values),
);

export const getActionsEntities = createSelector(
  getEventsEntities,
  compose(
    reduce((accum, action) => (action.is_action
      ? { ...accum, [action.id]: action }
      : accum), {}), values,
  ),
);

export const getCategoriesEntities = createSelector(
  rootSelector,
  pathOr({}, ['categories', 'entities']),
);

export const getCategoriesResult = createSelector(
  rootSelector,
  pathOr([], ['categories', 'result']),
);

export const getProcessNodeEntities = createSelector(
  rootSelector,
  pathOr({}, ['nodes', 'entities']),
);

export const getProcessNodeResult = createSelector(
  rootSelector,
  pathOr([], ['nodes', 'result']),
);

export const getProcessNodeById = createSelector(
  getProcessNodeEntities,
  processNodes => memoizeWithIdentity(processId => prop(processId, processNodes)),
);
export const getEventByNodeId = createSelector(
  getProcessNodeEntities,
  getEventsEntities,
  (nodes, events) => memoizeWithIdentity(nodeId => events[prop('event_id', prop(nodeId, nodes))]),
);

export const getFieldsOfEvent = createSelector(
  getActionsEntities,
  events => memoizeWithIdentity(
    eventId => compose(
      filter(propEq('is_input', 1)),
      propOr([], 'fields'),
      prop(eventId),
    )(events),
  ),
);

export const getOptionsOfEvent = createSelector(
  getEventsEntities,
  events => memoizeWithIdentity(
    eventId => compose(
      propOr([], 'options'),
      prop(eventId),
    )(events),
  ),
);

export const getOutputVariablesToEvent = createSelector(
  getEventsEntities,
  getProcessNodeEntities,
  (events, nodes) => memoizeWithIdentity(
    nodeId => compose(
      // filter(fields => propNotEq('is_output', 1)(fields)),
      (obj) => { // TODO rewrite in fp style
        const nodeIds = keys(obj);
        const res = {};

        nodeIds.forEach((id) => {
          res[id] = obj[id].filter(field => field.is_output === 1);
        });

        return res;
      },
      reduce((accum, item) => ({
        ...accum,
        [item.id]: reduce((nodesAccum, nodeItem) => ([...nodesAccum,
          { ...nodeItem, nodeId: item.id }]),
        [], events[item.event_id].fields),
      }), []),
      filter(node => (nodeId ? node.id <= nodes[nodeId].id : true)),
      sort((a, b) => a.parent_id - b.parent_id),
      values,
    )(nodes),
  ),
);

export const getBusinessProcessesPending = createSelector(
  pathOr(false, ['pending', 'getBusinessProcessesRequest']),
  identity,
);

export const getBusinessProcessPending = createSelector(
  pathOr(false, ['pending', 'getBusinessProcessRequest']),
  identity,
);

export const getPendingUpdateBusinessProcess = createSelector(
  pathOr(false, ['pending', 'editBusinessProcessRequest']),
  identity,
);

export const getPendingDeleteBusinessProcess = createSelector(
  pathOr(false, ['pending', 'deleteBusinessProcessRequest']),
  identity,
);

export const getPendingAddBusinessProcessNode = createSelector(
  pathOr(false, ['pending', 'addBusinessProcessNodeRequest']),
  identity,
);

export const getPendingEditBusinessProcessNode = createSelector(
  pathOr(false, ['pending', 'editBusinessProcessNodeRequest']),
  identity,
);

export const getPendingDeleteBusinessProcessNode = createSelector(
  pathOr(false, ['pending', 'deleteBusinessProcessNodeRequest']),
  identity,
);
