import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUserData } from '../../state/user/selectors';
import { TYPES } from '../../constants/users';
import { callNotification } from '../helpers/notifies';
import { getSubscription } from '../../state/subscription/selectors';
import subscriptionApi from '../../api/subscription.service';
import { setCustomer } from '../../state/subscription/actions';


/**
 *
 * @param module {string}
 */

const CANCELED = 'canceled';

export const useSubscriptionPermission = (module) => {
  const dispatch = useDispatch();
  const subscription = useSelector(getSubscription);
  const user = useSelector(getUserData);
  const { t } = useTranslation();

  const checkSubscriptionPermission = useCallback(() => {
    const { plan: { metadata } } = subscription;
    if (subscription.status === CANCELED) {
      callNotification.error(t('You subscription is canceled'));
      return;
    }
    if (Number(metadata[module])) {
      return;
    }
    if (user.type === TYPES.ADMIN) {
      callNotification.error(`${module} is not allowed, please update your subscription plan`);
      return;
    }
    callNotification.error(`${module} is not allowed in current subscription plan`);
  }, [user, subscription]);

  const getCustomerData = async () => {
    const customerData = await subscriptionApi.getCustomer();
    dispatch(setCustomer(customerData));
  };

  useEffect(() => {
    if (!subscription.plan) {
      getCustomerData();
    } else checkSubscriptionPermission();
  }, [subscription]);
};
