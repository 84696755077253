import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Condition = ({ children, isShown }) => (
  <React.Fragment>
    {
      isShown ? (children) : (<></>)
    }
  </React.Fragment>
);

Condition.propTypes = {
  children: PropTypes.element.isRequired,
  isShown: PropTypes.bool.isRequired,
};

export default Condition;
