import {
  compose,
} from 'recompose';
import { connect } from 'react-redux';

import BusinessProcess from './businessProccess';
import { bpSelectors } from '../../store/bpModule';
import { withWindowWidth } from '../../../../../utils/enchancers';

const mapStateToProps = (store, { match: { params: { processId } } }) => ({
  businessProcess: bpSelectors.getProcessById(store)(processId),
});

const enhancer = compose(
  connect(mapStateToProps),
  withWindowWidth(),
);

export default enhancer(BusinessProcess);
