import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { has } from 'ramda';

import { memo } from 'react';
import RadioButtons from './radioButtons';


const condChecker = ({ cond, values, setIsShown }) => () => {
  if (cond) {
    const { field, value: { equal, notEqual } } = cond;
    if (has('equal', cond.value)) {
      if (values[field] === equal) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    }
    if (has('notEqual', cond)) {
      if (values[field] !== notEqual) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    }
  }
};

const enhance = compose(
  withState('isShown', 'setIsShown', ({ cond }) => !cond),
  memo,
  withHandlers({
    condChecker,
  }),
  lifecycle({
    componentDidMount() {
      const { defaultValue, name, handleChange } = this.props;
      if (defaultValue) {
        handleChange({ target: { name, value: defaultValue } });
      }
      this.props.condChecker();
    },
    componentDidUpdate() {
      this.props.condChecker();
    },
  }),
);

export default enhance(RadioButtons);
