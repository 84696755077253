import {
  compose, pure, withHandlers, withState,
} from 'recompose';
import { withTranslation } from 'react-i18next';

import FiltersRow from './filtersRow';
import { debounceFunc } from '../../../../../../../utils/helpers/commonHelpers';

const onChangeSortingHandler = ({ setProcessesFilter }) => ({ val: { order, sortBy, id } }) => {
  setProcessesFilter({ order, sort: sortBy, sortId: id });
};

const onChangeSearchHandler = ({
  setSearchValue, setProcessesFilter,
  processFilter,
}) => ({ target: { value } }) => {
  setSearchValue(value);
  debounceFunc(() => setProcessesFilter({ ...processFilter, title: value }),
    800,
    false,
    'searchBusinessProcess');
};

const enhance = compose(
  withTranslation(['common']),
  withState('searchValue', 'setSearchValue', ''),
  pure,
  withHandlers({
    onChangeSearch: onChangeSearchHandler,
    onChangeSorting: onChangeSortingHandler,
  }),
);
export default enhance(FiltersRow);
