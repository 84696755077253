import React from 'react';
import PropTypes from 'prop-types';
import {
  ConfirmModal,
} from '../../../../../../../ui-kit';

const UpdateSubscriptionModal = ({
  isOpen,
  onClose,
  onConfirm,
  modalText,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle="Update"
    onAction={onConfirm}
    onClose={onClose}
    title="Subscription update"
  >
    {modalText}
  </ConfirmModal>
);

UpdateSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modalText: PropTypes.string.isRequired,
};

export default UpdateSubscriptionModal;
