import { branch, compose, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { push as connectRouterPush } from 'connected-react-router';

import { withFormik } from 'formik';
import { prop } from 'ramda';
import { withRouter } from 'react-router';
import EditProcess from './editProcess';
import { bpActions, bpSelectors } from '../../../../store/bpModule';
import preloaderWhileLoading from '../../../../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../../../../constants/ui';

const mapStateToProps = (store, { match: { params: { processId } } }) => ({
  businessProcess: bpSelectors.getProcessById(store)(processId),
  isPending: bpSelectors.getPendingUpdateBusinessProcess(store),
});

const mapDispatchToProps = ({
  push: connectRouterPush,
  editBusinessProcess: bpActions.editBusinessProcessRequest,
});

const onSubmit = (formValues, {
  props: {
    match: { params: { processId } },
    editBusinessProcess,
  },
}) => {
  editBusinessProcess({ ...formValues, status: Number(formValues.status), processId });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ businessProcess: { id } }) => !id, renderNothing),
  withFormik({
    mapPropsToValues: prop('businessProcess'),
    validateOnChange: true,
    validateOnBlur: true,
    handleSubmit: onSubmit,
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    alignContainerCenter: false,
    delay: 800,
    className: 'preloaderEditBusinessProcess',
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);

export default enhance(EditProcess);
