import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ButtonsCollection } from '../../..';
import { debounceFunc } from '../../../../utils/helpers/commonHelpers';

import './style.sass';

const DEBOUNCE_TIMEOUT = 800;
const ON_SEARCH_CALLBACK = 'onSearchCallBack';
const ENTER = 'Enter';

/**
 *
 * @param name {string}
 * @param onChange {function}
 * @param inFocus {boolean}
 * @param value {string}
 * @param placeholder {string}
 * @param className {string}
 * @param withoutControl {boolean}
 * @param children {JSX.Element}
 * @param onKeyPress {function}
 * @param onClick {function}
 * @param onSearchByEnter {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Search = ({
  name,
  onChange = () => {},
  value = '',
  placeholder = 'Search',
  className = '',
  withoutControl = false,
  children,
  onKeyPress = () => {},
  onClick = () => {},
  onSearchByEnter = () => {},
  onSearch = () => {},
}) => {
  const [inFocus, setInFocus] = useState(false);
  const inputRef = useRef(null);
  const handlerInFocus = () => {
    if (inFocus) {
      return;
    } setInFocus(prevValue => !prevValue);
  };
  const onClickOutsideHandler = (e) => {
    if (!e.path.includes(inputRef.current)) {
      setInFocus(false);
    }
  };

  const onSearchDebounced = element => debounceFunc(() => {
    onSearch(element);
  }, DEBOUNCE_TIMEOUT, false, ON_SEARCH_CALLBACK);

  const handleSearch = (key, e) => key === ENTER && onSearchByEnter(e);

  useEffect(() => {
    onSearchDebounced();
    document.body.addEventListener('click', onClickOutsideHandler);
    return (
      document.body.removeEventListener('click', onClickOutsideHandler)
    );
  }, [value]);

  return (
    <div className={classNames('search-field', className, inFocus && 'search-field--focus')}>
      <input
        onClick={handlerInFocus}
        type="text"
        name={name}
        onChange={onChange}
        value={value}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        onKeyDown={({
          key,
          ...e
        }) => handleSearch(key, e)}
        autoComplete="off"
        className="search-field__place-write"
        ref={inputRef}
      />
      {!withoutControl
      && (
      <ButtonsCollection.ButtonIcons
        title="Submit search"
        onClick={onClick}
        type="button"
        className="search-field__button"
      >
        <span className="icon-search-icon2" />
      </ButtonsCollection.ButtonIcons>
      )
      }
      {children}
    </div>
  );
};
