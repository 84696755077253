import axios from 'axios';
import { getRootApiUrl } from '../utils/helpers/requestHelpers';

const url = getRootApiUrl();

const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearToken = () => {
  axios.defaults.headers.common.Authorization = '';
};

const instance = axios.create({
  baseURL: url,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

const get = (methodUrl, params) => instance.get(url + methodUrl, { params });
const post = (methodUrl, params) => instance.post(url + methodUrl, params);
const put = (methodUrl, params) => instance.put(url + methodUrl, params);
const destroy = (methodUrl, params) => instance.delete(url + methodUrl, { params });

export default {
  setToken,
  clearToken,
  get,
  post,
  put,
  destroy,
};
