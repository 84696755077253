import {
  compose, withHandlers, withProps,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BlockedNodeModal from './blockedNodeModal';
import { uiActions, uiSelectors } from '../../../../../../../state/ui';
import { bpActions, bpSelectors } from '../../../../store/bpModule';
import { getListOfNodesWhatUsingVariables } from '../../../../helpers/common';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  deleteProcessNodeRequest: bpActions.deleteBusinessProcessNodeRequest,
};

const mapStateToProps = (state, { crudNodesConfig: { selectedNode } }) => ({
  isOpen: uiSelectors.getModal(state)('blockedNodeModal'),
  node: bpSelectors.getProcessNodeById(state)(selectedNode),
  events: bpSelectors.getEventsEntities(state),
  nodes: bpSelectors.getProcessNodeEntities(state),
});


const onDeleteHandler = ({
  deleteProcessNodeRequest, onClose, node, match: { params: { processId } },
}) => () => deleteProcessNodeRequest({
  processId,
  nodeId: node.id,
}, {
  callbacks: {
    success: onClose,
  },
});

const onCloseHandler = ({ onCloseModal, modalName }) => () => {
  onCloseModal(modalName);
  onCloseModal('deleteNodeModal');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ events, node = {}, nodes }) => ({
    event: events[node.event_id],
    listOfUse: getListOfNodesWhatUsingVariables(node.id, events, nodes),
  })),
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);


export default enhance(BlockedNodeModal);
