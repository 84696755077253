import {
  compose, withStateHandlers, lifecycle, getContext, withHandlers, withProps, withContext,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import PROJECT_TABS from '../../constants/tabNavigation';
import { taskActions, taskSelectors } from '../../state/task';
import { projectActions } from '../../state/project';
import Task from './task';
import { withUrlParams } from '../../utils/enchancers';
import withRefs from '../../utils/enchancers/withRefs';

const mapDispatchToProps = {
  getTask: taskActions.getActiveTaskRequest,
  clearDataTask: taskActions.clearDataTask,
  getSprints: projectActions.getSprintsRequest,
};

const mapStateToProps = state => ({
  task: taskSelectors.getActiveTask(state),
  isLoading: taskSelectors.getActiveTaskPending(state),
});

const withActiveTask = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const {
        getTask, getSprints, getUrlParam, match: { params: { id, taskId } },
      } = this.props;
      getTask({ projectId: id, clientId: getUrlParam(['client_id']), taskId });
      getSprints({ projectId: id });
    },
  }),
);


const onScrollSetRefHandler = ({ setRef }) => e => setRef('commentsScroll', e);

const enhance = compose(
  withRefs(),
  withTranslation(['tasks']),
  withUrlParams({}),
  withContext({
    clientId: PropTypes.string,
  }, ({ getUrlParam }) => ({
    clientId: getUrlParam(['client_id']),
  })),
  withActiveTask,
  withStateHandlers(
    () => ({ statusVisible: 'brill' }), {
      handlerStatusVisible: () => val => ({
        statusVisible: val,
      }),
    },
  ),
  withHandlers({
    onScrollSetRef: onScrollSetRefHandler,
  }),
  withProps(({ getRef }) => ({
    scrollElement: getRef('commentsScroll'),
  })),
  getContext({
    setActiveTab: PropTypes.func,
    setIsTaskOpen: PropTypes.func,
  }),
  lifecycle({
    componentDidMount() {
      const { setActiveTab, setIsTaskOpen } = this.props;
      if (setActiveTab) {
        setActiveTab(PROJECT_TABS.TASK);
      }
      if (setIsTaskOpen) {
        setIsTaskOpen(true);
      }
    },
    componentWillUnmount() {
      const { setActiveTab, clearDataTask, setIsTaskOpen } = this.props;
      if (setActiveTab) {
        setActiveTab(PROJECT_TABS.TASKS);
      }
      if (setIsTaskOpen) {
        setIsTaskOpen(false);
      }
      clearDataTask();
    },
  }),
);
export default enhance(Task);
