import { withProps } from 'recompose';
import { mergeLeft, prop } from 'ramda';

import { getFullName } from '../helpers/userHelpers';
import { getImageUrl } from '../helpers/requestHelpers';
import { IMAGES_SIZE } from '../../constants/ui';

const withUserData = user => withProps((props) => {
  const data = user(props);
  return ({
    user: mergeLeft(data, {
      fullName: getFullName(data),
      userAvatar: getImageUrl(prop('avatar', data), IMAGES_SIZE.sm),
    }),
  });
});

export default withUserData;
