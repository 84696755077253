import React from 'react';
import PropTypes from 'prop-types';
import ProcessCard from './components/proccessCard';
import FiltersRow from './components/filtersRow';
import CreateProcessModal from './components/createProcessModal';
import DeleteProcessModal from './components/deleteProcessModal';

import './style.sass';

const ProcessesListContainer = ({
  result, openModal, refToBrillBuilder, processesFilter, onDeleteProcess,
  setProcessesFilter, crudProcessConfig, setCrudProcessConfig,
}) => (
  <div className="processesListContainer">
    <FiltersRow
      setProcessesFilter={setProcessesFilter}
      processesFilter={processesFilter}
    />
    <div
      className="processesListContainer__wrapper"
      ref={refToBrillBuilder}
    >
      <button
        onClick={() => openModal('createProcessModal')}
        type="button"
        className="processCard processCard__add"
      >
        <span className="processCard__title">Add new process</span>
      </button>
      {
        result.map(processId => (
          <ProcessCard
            key={processId}
            onDeleteProcess={onDeleteProcess}
            processId={processId}
          />
        ))
      }
    </div>
    <DeleteProcessModal
      crudProcessConfig={crudProcessConfig}
      setCrudProcessConfig={setCrudProcessConfig}
    />
    <CreateProcessModal />
  </div>
);

ProcessesListContainer.propTypes = {
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
  crudProcessConfig: PropTypes.instanceOf(Object).isRequired,
  setCrudProcessConfig: PropTypes.func.isRequired,
  refToBrillBuilder: PropTypes.func.isRequired,
  setProcessesFilter: PropTypes.func.isRequired,
  processesFilter: PropTypes.instanceOf(Object).isRequired,
  onDeleteProcess: PropTypes.func.isRequired,
};

export default ProcessesListContainer;
