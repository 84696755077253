import React from 'react';

import { NavLink } from 'react-router-dom';
import { ButtonsCollection, Header } from '../../../../../ui-kit';
import { ProcessContainer } from '../../containers';
import { WINDOW_WIDTH } from '../../../../../constants/ui';

import './style.sass';

const BusinessProcess = ({ businessProcess, windowWidth }) => (
  <>
    <Header className="role-header">
      <>
        <NavLink className="business-proccess__go-back" to="/business-processes/">
          <span className="icon-expand-arrow-left" title="Back to business processes" />
        </NavLink>
        <h1 className="weight--medium title--l title-page">
          Business Processes - {businessProcess.title}
        </h1>
      </>
      {
        windowWidth > WINDOW_WIDTH.MEDIUM ? (
          <ButtonsCollection.ButtonBrill
            asLink
            renderMobileButton
            href="/business-processes"
            className="button--sm header__control-button--project"
          >
            New Process
          </ButtonsCollection.ButtonBrill>
        ) : (
          <ButtonsCollection.ButtonPlus
            asLink
            renderMobileButton
            href="/business-processes"
            className="header__control-button--project"
          />
        )
      }
    </Header>
    <ProcessContainer />
  </>
);

export default BusinessProcess;
