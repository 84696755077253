import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      field: 'title',
      message: 'Title is required',
    }),
});

export default rules;
