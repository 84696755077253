import { createActions } from 'redux-actions';
import {
  compose, identity, mergeLeft, pathOr,
} from 'ramda';
import types from './types';
import { businessProcessesEventsSchema, businessProcessesSchema } from './schema';
import { uiActions } from '../../../../../state/ui';
import { setErrorPage } from '../../../../../state/ui/actions';
import { getNormalizeErrorObject } from '../../../../../utils/helpers/requestHelpers';

const errorPageDataWhenGetBps = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);

export const {
  getBusinessProcessesRequest,
  getBusinessProcessRequest,
  addBusinessProcessRequest,
  deleteBusinessProcessRequest,
  editBusinessProcessRequest,
  addBusinessProcessNodeRequest,
  editBusinessProcessNodeRequest,
  deleteBusinessProcessNodeRequest,
  getBusinessProcessEventsRequest,
  setBusinessProcesses,
  setBusinessProcess,
  deleteBusinessProcess,
  setBusinessProcessNode,
  deleteBusinessProcessNode,
  setBusinessProcessNodes,
  setBusinessProcessEvents,
} = createActions({
  [types.GET_BUSINESS_PROCESSES_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/bp',
      selectorName: 'getBusinessProcessesRequest',
      method: 'GET',
      params,
      schema: {
        rules: businessProcessesSchema,
        pathData: ['data'],
      },
      actions: {
        success: setBusinessProcesses,
        error: compose(setErrorPage, errorPageDataWhenGetBps),
      },
      ...meta,
    }),
  ],
  [types.GET_BUSINESS_PROCESS_REQUEST]: [
    identity,
    ({ processId, ...params }, meta) => ({
      async: true,
      route: `/bp/${processId}`,
      selectorName: 'getBusinessProcessRequest',
      method: 'GET',
      params,
      actions: {
        success: setBusinessProcess,
        error: uiActions.setErrorPage,
      },
      ...meta,
    }),
  ],
  [types.ADD_BUSINESS_PROCESS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/bp',
      selectorName: 'addBusinessProcessRequest',
      method: 'POST',
      params,
      actions: {
        success: setBusinessProcess,
      },
      ...meta,
      callbacks: {
        success: pathOr(identity, ['callbacks', 'success'], meta),
      },
    }),
  ],
  [types.DELETE_BUSINESS_PROCESS_REQUEST]: [
    identity,
    ({ processId, ...params }, meta) => ({
      async: true,
      route: `/bp/${processId}`,
      selectorName: 'deleteBusinessProcessRequest',
      method: 'DELETE',
      params,
      actions: {
        meta,
        success: () => deleteBusinessProcess(processId),
      },
      ...meta,
    }),
  ],
  [types.EDIT_BUSINESS_PROCESS_REQUEST]: [
    identity,
    ({ processId, ...params }, meta) => ({
      async: true,
      route: `/bp/${processId}`,
      selectorName: 'editBusinessProcessRequest',
      method: 'PUT',
      params,
      actions: {
        success: setBusinessProcess,
      },
      ...meta,
    }),
  ],
  [types.ADD_BUSINESS_PROCESS_NODE_REQUEST]: [
    identity,
    ({ processId, ...params }, meta) => ({
      async: true,
      route: `/bp/${processId}/nodes`,
      selectorName: 'addBusinessProcessNodeRequest',
      method: 'POST',
      params,
      actions: {
        success: setBusinessProcessNode,
      },
      ...meta,
    }),
  ],
  [types.EDIT_BUSINESS_PROCESS_NODE_REQUEST]: [
    identity,
    ({ nodeId, processId, ...params }, meta) => ({
      async: true,
      route: `/bp/${processId}/nodes/${nodeId}/`,
      selectorName: 'editBusinessProcessNodeRequest',
      method: 'PUT',
      params,
      actions: {
        success: setBusinessProcessNode,
      },
      ...meta,
    }),
  ],
  [types.DELETE_BUSINESS_PROCESS_NODE_REQUEST]: [
    identity,
    ({ nodeId, processId, ...params }, meta) => ({
      async: true,
      route: `/bp/${processId}/nodes/${nodeId}/`,
      selectorName: 'deleteBusinessProcessNodeRequest',
      method: 'DELETE',
      params,
      actions: {
        success: () => deleteBusinessProcessNode(nodeId),
      },
      ...meta,
    }),
  ],
  [types.GET_BUSINESS_PROCESS_EVENTS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/bp/events/',
      selectorName: 'getBusinessProcessEventsRequest',
      method: 'GET',
      params,
      schema: {
        rules: businessProcessesEventsSchema,
        pathData: [],
      },
      actions: {
        success: setBusinessProcessEvents,
      },
      ...meta,
    }),
  ],
},
types.SET_BUSINESS_PROCESSES,
types.SET_BUSINESS_PROCESS,
types.DELETE_BUSINESS_PROCESS,
types.SET_BUSINESS_PROCESS_NODE,
types.DELETE_BUSINESS_PROCESS_NODE,
types.SET_BUSINESS_PROCESS_NODES,
types.SET_BUSINESS_PROCESS_EVENTS);
