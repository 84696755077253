import { compose } from 'recompose';
import React, { useEffect, useRef, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import { Portal } from 'react-portal';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CreateProjectModal } from '../../../containers/allProjects/components/modals';
import { Banner } from '../..';
import { debounceFunc } from '../../../utils/helpers/commonHelpers';
import { getTotalUnreadCountRequest, setActiveChannel } from '../../../state/messenger/actions';
import { resetModals, setUrlBeforeChannels, setAppUnreadMessages } from '../../../state/ui/actions';
import withNotification from '../../../utils/enchancers/withNotification';
import { CHANNELS_ROUTES } from '../../../constants/ui';
import withSideBarSwipe from '../../../utils/enchancers/withSideBarSwipe';
import { uiSelectors } from '../../../state/ui';
import { messengerSelectors } from '../../../state/messenger';


import 'react-toastify/dist/ReactToastify.css';
import './style.sass';

const DEFAULT_DEBOUNCE_TIME = 60;
const DEFAULT_RESIZE_TIME = 1000;
const DEFAULT_DEBOUNCE_NAME = 'fixHeightOnMobileDevicesFunc';

/**
 *
 * @param children {string | number | array | element}
 * @param leftSideBarStatus {string | number | array | element}
 * @param rightSideBarStatus {string | number | array | element}
 * @param className {string}
 * @param onEnableNotification {string}
 * @param isNotificationPermissionDefault {string}
 * @param noRightSidebar {string}
 * @returns {JSX.Element}
 *
 */

const Main = ({
  children = '', leftSideBarStatus = false, rightSideBarStatus = false,
  className = '', onEnableNotification = () => { }, isNotificationPermissionDefault = true,
  noRightSidebar = false,
}) => {
  const mainContainer = useRef(null);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const totalUnreadCount = useSelector(messengerSelectors.getTotalUnreadCount);
  const directChannels = useSelector(messengerSelectors.getDirectChannels);
  const groupChannels = useSelector(messengerSelectors.getGroupChannels);
  const isSocketError = useSelector(uiSelectors.getIsWsError);

  const onSetUrlBeforeChannel = useCallback((() => {
    if (match.url !== CHANNELS_ROUTES.CHANNELS && match.url !== CHANNELS_ROUTES.DIRECTS) {
      dispatch(setUrlBeforeChannels(match.url));
    }
  }), [match.url, setUrlBeforeChannels]); // eslint-disable-line

  const onSetCorrectHeightPageOnMobileDevices = useCallback(() => {
    const fixHeightOnMobileDevicesFunc = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    debounceFunc(fixHeightOnMobileDevicesFunc, DEFAULT_DEBOUNCE_TIME, false, DEFAULT_DEBOUNCE_NAME);
  }, []);

  useEffect(() => {
    const onAnimation = ({ propertyName }) => {
      if (propertyName === 'padding-left'
        || propertyName === 'padding-right'
        || propertyName === 'transform') {
        /* TODO:
        * It breaks drag'n'drop.
        * Might break some animations! Be careful!
        * */
        // window.dispatchEvent(new Event('resize'));
      }
      return propertyName;
    };
    let mainContainerRefHolder = null;
    window.addEventListener('resize', onSetCorrectHeightPageOnMobileDevices);
    if (mainContainer.current) {
      mainContainer.current.addEventListener('transitionend', onAnimation);
      mainContainerRefHolder = mainContainer.current;
    }
    return () => {
      window.removeEventListener('resize', onSetCorrectHeightPageOnMobileDevices);
      mainContainerRefHolder.removeEventListener('transitionend', onAnimation);
    };
  }, [onSetCorrectHeightPageOnMobileDevices]);

  useEffect(() => {
    dispatch(setActiveChannel(null));
    onSetCorrectHeightPageOnMobileDevices();
    onSetUrlBeforeChannel();
    return () => {
      dispatch(setActiveChannel(null));
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(resetModals());
    onSetUrlBeforeChannel();
  }, [match.url]); // eslint-disable-line

  useEffect(() => {
    const timerId = setTimeout(() => window.dispatchEvent(new Event('resize')), DEFAULT_RESIZE_TIME);
    return () => clearTimeout(timerId);
  }, [isSocketError]);

  useEffect(() => {
    dispatch(getTotalUnreadCountRequest());
  }, [directChannels, groupChannels]); // eslint-disable-line

  useEffect(() => {
    dispatch(setAppUnreadMessages(totalUnreadCount));
  }, [totalUnreadCount]); // eslint-disable-line

  return (
    <div
      className={classNames(
        `page-wrapper ${className}`,
        { 'js-left-side-bar--open': leftSideBarStatus },
        { 'js-right-side-bar--open': rightSideBarStatus && !noRightSidebar },
        { 'is-notification-banner': isNotificationPermissionDefault },
      )}
      ref={mainContainer}
    >
      <Banner
        isVisible={isNotificationPermissionDefault}
        onButtonClick={onEnableNotification}
        actionTitle="enable notifications"
        title={t('Avanga needs your permission to')}
      />
      <main className={classNames('active', { 'is-notification-banner': isNotificationPermissionDefault })}>
        {children}
      </main>
      <CreateProjectModal />
      <Portal>
        <ToastContainer />
      </Portal>
    </div>
  );
};

const enhancer = compose(
  withSideBarSwipe({ range: 100 }),
  withNotification,
);

export default enhancer(Main);
