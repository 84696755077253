import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardItem } from './components/cardItem';
import {
  ButtonsCollection,
  PreLoaderWhileLoading,
  ScrollWrapper,
  ToggleButton,
} from '../../../../../ui-kit';
import api from '../../../../../api/subscription.service';
import { subscriptionSelectors } from '../../../../../state/subscription';
import './style.sass';
import { PRELOADER_DIMENSION } from '../../../../../constants/ui';
import { getUserData } from '../../../../../state/user/selectors';
import { setSubscription } from '../../../../../state/subscription/actions';
import UpdateSubscriptionModal from './components/confirmModal/confirmUpdateModal';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { SUBSCRIPTION_STATUS } from '../../../../../constants/billings';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const YEAR = 'year';
const MONTH = 'month';

const inactiveStatuses = [SUBSCRIPTION_STATUS.CANCELED, SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED];

export const SubscriptionContainer = () => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(false);
  const [billingPlans, setBillingPlans] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  const { email } = useSelector(getUserData);
  const subscription = useSelector(subscriptionSelectors.getSubscription);
  const customer = useSelector(subscriptionSelectors.getCustomer);

  const { cancel_at_period_end: cancelAtPeriodEnd } = subscription;
  const closeModal = () => setModalData(null);

  const getPlans = async (params) => {
    const data = await api.getPlansRequest(params);
    setBillingPlans(data);
  };

  const getCardData = async () => {
    const { invoice_settings: { default_payment_method: defaultPaymentMethod } } = customer;
    if (defaultPaymentMethod) {
      const { paymentMethod } = await api.getCard(defaultPaymentMethod);
      setCardData(paymentMethod);
    }
  };

  const onCancelPlan = async (id) => {
    const response = await api.cancelSubscription({
      subscriptionId: id,
      cancelAtPeriodEnd: !cancelAtPeriodEnd,
    });
    dispatch(setSubscription(response));
  };

  const onChangeToggle = async ({ target: { checked } }) => {
    setIsChecked(checked);
    await getPlans(checked ? YEAR : MONTH);
  };

  const updatePlan = async () => {
    const subscriptionId = subscription.id;
    const response = await api.updateSubscription({
      priceId: modalData.priceId,
      customerId: customer.id,
    }, subscriptionId);
    if (response.subscription.pending_update) callNotification.error(t('Subscription was not updated, please add other card'));
    else dispatch(setSubscription(response));
    closeModal();
  };

  const createNewSubscription = async (priceId) => {
    const { url } = await api.createSubscriptionCheckout({ priceId, email });
    if (url) {
      window.open(url);
    }
  };

  const onSelectPlan = (priceId) => {
    const { invoice_settings: { default_payment_method: defaultPaymentMethod } } = customer;
    const { status } = subscription;
    const isInactiveSubscription = inactiveStatuses.includes(status);
    if (defaultPaymentMethod && !isInactiveSubscription) setModalData({ isOpen: true, priceId });
    else createNewSubscription(priceId);
  };

  const onChangePaymentCard = async () => {
    const { url } = await api.setCard({ email });
    window.location.replace(url);
  };

  useEffect(() => {
    getPlans(MONTH);
  }, []);

  useEffect(() => {
    if (customer.id) getCardData();
  }, [customer]);


  return (
    <PreLoaderWhileLoading
      dimension={PRELOADER_DIMENSION.DEFAULT}
      alignContainerCenter
      isLoading={!!billingPlans}
    >
      <ScrollWrapper>
        <div className="subscription-container">
          <div className="subscription-container__toggle-button">
            <span>{t('Bill monthly')}</span>
            <ToggleButton status={isChecked} onChange={onChangeToggle} />
            <span>{t('Bill yearly')}</span>
          </div>
          <div>
            <div className="subscription-container__cards-list">
              {
              billingPlans?.map(item => (
                <CardItem
                  planData={item}
                  subscription={subscription}
                  onSelectPlan={onSelectPlan}
                  onCancelPlan={onCancelPlan}
                />
              ))
            }
            </div>
          </div>
          {cardData && (
          <div className="subscription-container__change-payment">
            <div className="subscription-container__card-info">{t('Card info')}:</div>
            <div className="subscription-container__card-info-block">
              <div className="subscription-container__card-number">{`XXXX-XXXX-XXXX-${cardData.card.last4}`}</div>
              <ButtonsCollection.ButtonBrill
                type="submit"
                className="button-brill--fill subscription-container__change-payment__button "
                onClick={onChangePaymentCard}
              >
                {t('Change card')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </div>
          )
          }
        </div>
        <UpdateSubscriptionModal
          isOpen={modalData?.isOpen}
          onClose={closeModal}
          onConfirm={updatePlan}
          modalText={t('Are you sure you want to update your plan?')}
        />
      </ScrollWrapper>
    </PreLoaderWhileLoading>
  );
};
